import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { merge } from 'rxjs';

import { CodeValueGroups, DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ITabCoreDetails, IWorkOrder, IWorkOrderVersion } from '../../models';
import { CodeValueService } from '../../../common';
import { WorkorderService } from '../workorder.service';
import { CoreCommissionFormService } from './core-commission-form.service';
import { FooterFormService } from './footer-form.service';
import { Validators } from '@angular/forms';


@Injectable()
export class CoreDetailFormService implements IFormService {
  formGroup: FormGroup<ITabCoreDetails>;

  private isRootComponentDestroyed$: Subject<boolean>;
  private workOrder: IWorkOrder;

  constructor(
    private fb: FormBuilder,
    private codeValueService: CodeValueService,
    private workorderService: WorkorderService,
    private footerFormService: FooterFormService,
    private coreCommissionFormService: CoreCommissionFormService
  ) {
  }

  get organizationIdInternalFormControl(): FormControl<number> {
    return this.formGroup.get('OrganizationIdInternal') as FormControl<number>;
  }

  get holidayScheduleNameIdFormControl(): FormControl<number> {
    return this.formGroup.get('HolidayScheduleNameId') as FormControl<number>;
  }

  get lineOfBusinessIdFormControl(): FormControl<number> {
    return this.formGroup.get('LineOfBusinessId') as FormControl<number>;
  }

  get startDateFormControl(): FormControl<string> {
    return this.formGroup.get('StartDate') as FormControl<string>;
  }

  get workOrderStartDateStateFormControl(): FormControl<string> {
    return this.formGroup.get('WorkOrderStartDateState') as FormControl<string>;
  }

  get endDateFormControl(): FormControl<string> {
    return this.formGroup.get('EndDate') as FormControl<string>;
  }

  get workOrderEndDateStateFormControl(): FormControl<string> {
    return this.formGroup.get('WorkOrderEndDateState') as FormControl<string>;
  }

  get terminationDateFormControl(): FormControl<string> {
    return this.formGroup.get('TerminationDate') as FormControl<string>;
  }

  get workSiteIdFormControl(): FormControl<number> {
    return this.formGroup.get('WorksiteId') as FormControl<number>;
  }

  get workerLocationIdFormControl(): FormControl<number> {
    return this.formGroup.get('WorkerLocationId') as FormControl<number>;
  }

  get internalOrganizationDefinition1IdFormControl(): FormControl<number> {
    return this.formGroup.get('InternalOrganizationDefinition1Id') as FormControl<number>;
  }

  get organizationIdInternalChange$() {
    return this.organizationIdInternalFormControl.valueChanges;
  }

  get lineOfBusinessIdChange$() {
    return this.lineOfBusinessIdFormControl.valueChanges;
  }

  get workSiteIdChange$() {
    return this.workSiteIdFormControl.valueChanges;
  }

  get workerLocationIdChange$() {
    return this.workerLocationIdFormControl.valueChanges;
  }

  get internalOrganizationDefinition1IdChange$() {
    return this.internalOrganizationDefinition1IdFormControl.valueChanges;
  }

  get startDateChange$() {
    return merge(
      this.startDateFormControl.valueChanges,
      this.workOrderStartDateStateFormControl.valueChanges
    );
  }

  get endDateChange$() {
    return merge(
      this.endDateFormControl.valueChanges,
      this.workOrderEndDateStateFormControl.valueChanges
    );
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const workorderDetails = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabCoreDetails>(workorderDetails);

    this.updateValidatorsAndDisabled();

    this.workOrder = workorder;

    return this.formGroup;
  }

  private updateValidatorsAndDisabled() {
    FormServiceHelper.setValidators(this.formGroup, this.onGetValidators(this.formGroup));
    FormServiceHelper.setDisabled(this.formGroup, this.onGetDisabled(this.formGroup));
  };

  private onGetValidators(formGroup: FormGroup<ITabCoreDetails>): ValidatorsConfig<ITabCoreDetails> {
    // TODO: StartDateState/EndDateState
    return {
      StartDate: [Validators.required],
      EndDate: [Validators.required],
      LineOfBusinessId: [Validators.required],
      InternalOrganizationDefinition1Id: [Validators.required],
      WorksiteId: [Validators.required],
      HolidayScheduleNameId: [Validators.required], // TODO: Is supposed to be handled by the disabled condition that hides it?
      OrganizationIdInternal: [Validators.required],
      WorkerLocationId: [Validators.required]
    };
  }

  private onGetDisabled(formGroup: FormGroup<ITabCoreDetails>): DisabledConfig<ITabCoreDetails> {
    // TODO: StartDateState/EndDateState
    const worksiteId = this.formGroup.get('WorksiteId').value;
    const workerLocationId = this.formGroup.get('WorkerLocationId').value;

    const showHolidayScheduleNameId = true; // TODO
    return {
      HolidayScheduleNameId: !showHolidayScheduleNameId // TODO: check this. UI shows if worksite or worker location is Canada. Validator is only if worker location is Candada?
    };
  }

  destroyForm() {
    this.formGroup = null;
    this.workOrder = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.observeLineOfBusinessId();
    this.observeDate();
  }

  updateForm(workorder: IWorkOrder) {
    this.workOrder = workorder;
    const workorderDetails = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(workorderDetails, { emitEvent: false });

    this.updateValidatorsAndDisabled();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const coreDetails: ITabCoreDetails = this.formGroup.value;

    workOrder.AtsPlacementId = coreDetails.AtsPlacementId;
    workOrder.StartDate = coreDetails.StartDate;
    workOrder.EndDate = coreDetails.EndDate;
    workOrder.WorkOrderVersion.LineOfBusinessId = coreDetails.LineOfBusinessId;
    workOrder.WorkOrderVersion.InternalOrganizationDefinition1Id = coreDetails.InternalOrganizationDefinition1Id;
    workOrder.WorkOrderVersion.InternalOrganizationDefinition2Id = coreDetails.InternalOrganizationDefinition2Id;
    workOrder.WorkOrderVersion.InternalOrganizationDefinition3Id = coreDetails.InternalOrganizationDefinition3Id;
    workOrder.WorkOrderVersion.InternalOrganizationDefinition4Id = coreDetails.InternalOrganizationDefinition4Id;
    workOrder.WorkOrderVersion.InternalOrganizationDefinition5Id = coreDetails.InternalOrganizationDefinition5Id;
    workOrder.WorkOrderVersion.WorksiteId = coreDetails.WorksiteId;
    workOrder.WorkOrderVersion.WorkerLocationId = coreDetails.WorkerLocationId;
    workOrder.WorkOrderVersion.HolidayScheduleNameId = coreDetails.HolidayScheduleNameId;
    workOrder.OrganizationIdInternal = coreDetails.OrganizationIdInternal;
    workOrder.TerminationDate = coreDetails.TerminationDate;
    workOrder.WorkOrderVersion.WorkOrderStartDateState = coreDetails.WorkOrderStartDateState;
    workOrder.WorkOrderVersion.WorkOrderCreationReasonId = coreDetails.WorkOrderCreationReasonId;
    workOrder.WorkOrderVersion.WorkOrderEndDateState = coreDetails.WorkOrderEndDateState;

    return workOrder;
  }

  displayWorkOrderStartEndDateState(workOrder: IWorkOrder) {
    return !(workOrder.StatusId === PhxConstants.WorkOrderStatus.Processing
      || workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Approved
      || workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Replaced
      || workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.PendingUnterminate
      || workOrder.WorkOrderVersion.StatusId === PhxConstants.WorkOrderVersionStatus.Deleted);
  }

  updateHolidayScheduleNameId(value: number, emitEvent = false) {
    this.holidayScheduleNameIdFormControl.patchValue(value, { emitEvent });
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabCoreDetails {
    return {
      AtsPlacementId: workorder.AtsPlacementId,
      StartDate: workorder.StartDate,
      EndDate: workorder.EndDate,
      LineOfBusinessId: workorder.WorkOrderVersion.LineOfBusinessId,
      InternalOrganizationDefinition1Id: workorder.WorkOrderVersion.InternalOrganizationDefinition1Id,
      InternalOrganizationDefinition2Id: workorder.WorkOrderVersion.InternalOrganizationDefinition2Id,
      InternalOrganizationDefinition3Id: workorder.WorkOrderVersion.InternalOrganizationDefinition3Id,
      InternalOrganizationDefinition4Id: workorder.WorkOrderVersion.InternalOrganizationDefinition4Id,
      InternalOrganizationDefinition5Id: workorder.WorkOrderVersion.InternalOrganizationDefinition5Id,
      WorksiteId: workorder.WorkOrderVersion.WorksiteId,
      WorkerLocationId: workorder.WorkOrderVersion.WorkerLocationId,
      HolidayScheduleNameId: workorder.WorkOrderVersion.HolidayScheduleNameId,
      OrganizationIdInternal: workorder.OrganizationIdInternal,
      TerminationDate: workorder.TerminationDate,
      WorkOrderStartDateState: workorder.WorkOrderVersion.WorkOrderStartDateState,
      WorkOrderCreationReasonId: workorder.WorkOrderVersion.WorkOrderCreationReasonId,
      WorkOrderEndDateState: workorder.WorkOrderVersion.WorkOrderEndDateState,
      OriginalStartDate: workorder.AssignmentStartDate
    };
  }

  private observeLineOfBusinessId() {
    this.lineOfBusinessIdChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      if (!this.workorderService.getIsRecruitersAllowed(value)) {
        this.coreCommissionFormService.resetRecruiterFormArray();
      }
    });
  }

  private observeDate() {
    this.startDateChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(() => {
      const workOrder = this.workOrder;
      if (workOrder.StatusId === PhxConstants.WorkOrderStatus.Processing
        || workOrder.StatusId === PhxConstants.WorkOrderStatus.ChangeInProgress) {
        if (this.displayWorkOrderStartEndDateState(workOrder)) {
          this.footerFormService.updateEffectiveDate(this.workOrderStartDateStateFormControl.value || new Date().toDateString());
        } else {
          this.footerFormService.updateEffectiveDate(this.startDateFormControl.value || new Date().toDateString());
        }
      }
    });

  }

  private isHolidayScheduleNameRequired(subdivisionId: number, workorderVersion: IWorkOrderVersion): boolean {
    if (subdivisionId) {
      const country = this.codeValueService.getCodeValue(subdivisionId, 'geo.CodeSubdivision').parentId;
      return country === PhxConstants.CountryCanada;
    }
    return false;
  }

  private worksiteIsCanadian(worksiteId: number): boolean {
    if (worksiteId) {
      const country = this.getWorksiteCountryId(worksiteId);
      return country === PhxConstants.CountryCanada;
    }
    return false;
  }
  
  private workerLocationIsCanadian(workerLocationId: number): boolean {
    if (workerLocationId) {
      const countryId = this.codeValueService.getCodeValue(workerLocationId, 'geo.CodeSubdivision').parentId;  
      return countryId === PhxConstants.CountryCanada;
    }
    return false;
  }

  private getWorksiteCountryId(worksiteId: number): number {
    const subDivisionId = this.codeValueService.getParentId(CodeValueGroups.Worksite, worksiteId);
    const countryId = this.codeValueService.getCodeValue(subDivisionId, 'geo.CodeSubdivision').parentId;
    return countryId;
  }

}
