import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IFooter, IWorkOrder } from '../../models';

@Injectable()
export class FooterFormService implements IFormService {
  formGroup: FormGroup<IFooter>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
  ) { }

  get effectiveDateFormControl(): FormControl<string> {
    return this.formGroup.get('EffectiveDate') as FormControl<string>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<IFooter>({
      EffectiveDate: [workorder.WorkOrderVersion.EffectiveDate]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder.WorkOrderVersion.EffectiveDate = this.formGroup.value.EffectiveDate;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    this.formGroup.patchValue({ EffectiveDate: workorder.WorkOrderVersion.EffectiveDate }, { emitEvent: false });
  }

  updateEffectiveDate(value: string) {
    this.effectiveDateFormControl.patchValue(value, { emitEvent: false });
  }
}
