import { Inject, Injectable } from '@angular/core';
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

import { locale as dxLocale } from 'devextreme/localization';
import * as moment from 'moment';

import { ApiService } from './api.service';
import { PhxConstants } from '../model';
import { WindowRefService } from './WindowRef.service';

@Injectable({
  providedIn: 'root'
})
export class PhxLocalizationService {
  public static get locale() {
    return PhxLocalizationService.currentLocale;
  }

  private static currentLocale = 'en-CA';

  public onLangChanged: BehaviorSubject<string> = new BehaviorSubject<string>(PhxLocalizationService.currentLocale);

  constructor(private apiService: ApiService, private currencyPipe: CurrencyPipe, private winRef: WindowRefService, @Inject(DOCUMENT) private document: Document) { }

  public getLangFromCultureId(cultureId: number): string {
    let lang = 'en-CA';
    if (cultureId === PhxConstants.CultureType.FrCA) {
      lang = 'fr-CA';
    }
    return lang;
  }

  public getLangFromBrowser(): string {
    return (this.winRef.nativeWindow.navigator.languages ? navigator.languages[0] : this.winRef.nativeWindow.navigator.language || this.winRef.nativeWindow.navigator.userLanguage) || 'en-CA';
  }

  public get currentLang() {
    return PhxLocalizationService.currentLocale;
  }

  public get defaultLang() {
    return 'en-CA';
  }

  public setLocale(lang: string): void {
    if (lang !== this.currentLang) {
      PhxLocalizationService.currentLocale = lang;
      this.onLangChanged.next(lang);
      this.document.documentElement.setAttribute('lang', lang);
      this.localizeThirdPartyModules(lang);
    }
  }

  public localizeThirdPartyModules(lang: string) {
    dxLocale(lang); // devextreme
    moment.locale(lang); // moment
  }

  public loadAnonymousTranslations() {
    return this.apiService.queryWithPromise(`localization/${this.getLangFromBrowser()}`).then(response => {
      this.winRef.nativeWindow.PhxTranslations = response;
    });
  }

  private getTranslationForNodeList(translationKeyList: Array<string>) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    let localTranslationsObj = window['PhxTranslations'] || this.getLocaleDefaultTranslateObj() || this.getDefaultTranslateObj();
    let result = null;

    for (const node of translationKeyList) {
      if (localTranslationsObj.hasOwnProperty(node)) {
        localTranslationsObj = localTranslationsObj[node];
        if (node === translationKeyList[translationKeyList.length - 1]) {
          result = localTranslationsObj;
        }
      } else {
        break;
      }
    }

    return result;
  }

  private getTranslation(key: string): string {
    const translationKeyList = key.split('.');

    const translation = this.getTranslationForNodeList(translationKeyList);

    if (translation === null) {
      return key;
    } else {
      return translation;
    }
  }

  public translate(key: string, ...substitutes: any[]) {
    if (!key) {
      return '';
    }

    let translated = this.getTranslation(key);

    if (substitutes) {
      for (let i = 0; i < substitutes.length; i++) {
        translated = translated.replace(/{(\d+):prependHyphen}/g, (match, index) => {
            const value = substitutes[index];
            return value ? ` - ${value}` : '';
        });

        const tokenMarker: string = '\\{' + i.toString() + '\\}';
        translated = translated.replace(new RegExp(tokenMarker, 'g'), substitutes[i]);
      }
    }

    return translated;
  }

  // Format money by current locale, eg. currentLocale='fr-CA', $54000 will be 54 000,00$
  // currencyCode --- eg. input 'CAD' will display 54 000,00$CAD
  public formatMoney(val: number, currencyCode?: string) {
    return this.currencyPipe.transform(val, currencyCode, 'symbol', '1.2-2', PhxLocalizationService.currentLocale);
  }

  private getLocaleDefaultTranslateObj() {
    const map = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'fr-CA': {
        common: {
          generic: {
            loadingText: 'Veuillez patienter ...'
          }
        }
      }
    };

    return map[PhxLocalizationService.currentLocale];
  }

  private getDefaultTranslateObj() {
    return {
      common: {
        generic: {
          loadingText: 'Please wait ...' // Veuillez patienter ...
        }
      }
    };
  }
}
