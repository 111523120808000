import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { FormServiceHelper, IFormService, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkOrder } from '../../models';
import { ITabCoreCollaborators } from '../../models/work-order-form.interface';
import { PtFieldViewCustomValidator } from '../../ptFieldCustomValidator';

@Injectable()
export class CoreCollaboratorFormService implements IFormService {
  formGroup: FormGroup<ITabCoreCollaborators>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder
  ) {
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const collaboratorDetails: ITabCoreCollaborators = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabCoreCollaborators>(collaboratorDetails);

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  private updateValidatorsAndDisabled() {
    FormServiceHelper.setValidators(this.formGroup, this.onGetValidators(this.formGroup));
  }

  private onGetValidators(formGroup: FormGroup<ITabCoreCollaborators>): ValidatorsConfig<ITabCoreCollaborators> {
    return {
      AssignedToUserProfileId: [Validators.required]
    };
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const collaboratorDetail: ITabCoreCollaborators = this.formGroup.value;

    workOrder.WorkOrderVersion.AssignedToUserProfileId = collaboratorDetail.AssignedToUserProfileId;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    const collaboratorDetails: ITabCoreCollaborators = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(collaboratorDetails, { emitEvent: false });

    this.updateValidatorsAndDisabled();
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabCoreCollaborators {
    return {
      AssignedToUserProfileId: (workorder.WorkOrderVersion.AssignedToUserProfileId && workorder.WorkOrderVersion.AssignedToUserProfileId !== 1)
        ? workorder.WorkOrderVersion.AssignedToUserProfileId : null
    };
  }

}
