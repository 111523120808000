import { CoreDetailFormService } from './core-detail-form.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IPaymentParty, IPaymentRate, IPaymentContact, IPaymentInvoice } from '../../models/work-order-form.interface';
import { TimeMaterialInvoiceTabFormService } from './time-material-invoice-tab-form.service';
import { ExpenseInvoiceTabFormService } from './expense-invoice-tab-form.service';

@Injectable()
export class PartyPaymentInfoFormService implements IFormService {

  formGroup: FormArray<IPaymentParty>;
  private isRootComponentDestroyed$: Subject<boolean>;

  private organizationIdSupplierSubscription$: Subscription;

  private organizationIdSupplierChange = new Subject<number>();

  constructor(
    private fb: FormBuilder,
    private coreDetailFormService: CoreDetailFormService,
    private timeMaterialInvoiceTabFormService: TimeMaterialInvoiceTabFormService,
    private expenseInvoiceTabFormService: ExpenseInvoiceTabFormService
  ) {
  }

  get paymentPartiesRateDetailFormArray(): FormArray<IPaymentParty> { // TODO: remove? we already have formGroup
    return this.formGroup;
  }

  get organizationIdSupplierChange$() {
    return this.organizationIdSupplierChange.asObservable();
  }

  get allSupplierOrganizationIdsChange$() {
    return this.formGroup.valueChanges
      .pipe(
        map(paymentParties => paymentParties.map(paymentParty => paymentParty.OrganizationIdSupplier)),
        distinctUntilChanged((x, y) => x.sort().join(',') == y.sort().join(','))
      );
  }

  get allSupplierOrganizationIdsValue() {
    return this.formGroup.value
      .map(paymentParty => paymentParty.OrganizationIdSupplier);
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    const paymentParties = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.array<IPaymentParty>(paymentParties
      .map(paymentParty => this.createPaymentPartyFormGroup(paymentParty))
    );

    this.formGroup.controls.forEach((paymentPartyFormGroup, index) => {
      // Updates all validators/disabled down the tree
      this.updatePaymentPartyValidatorsAndDisabled(paymentPartyFormGroup as FormGroup<IPaymentParty>, index);
    })

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.setupOrganizationIdSupplierListeners();

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {

    const paymentParties = this.formGroup;
    
    const workOrderVersion = workOrder.WorkOrderVersion;

    workOrderVersion.PaymentInfoes = paymentParties.value.map((paymentParty, index) => {

      const paymentInfoDto = workOrderVersion.PaymentInfoes[index];

      // Patch the form changes to the PaymentInfoDto
      return {
        ...paymentInfoDto,
        ...{
          Hours: paymentParty.Hours,
          CurrencyId: paymentParty.CurrencyId,
          OrganizationIdSupplier: paymentParty.OrganizationIdSupplier,
          UserProfileIdSupplier: paymentParty.UserProfileIdSupplier,
          IsCommissionVacation: null, // TODO
          
          PaymentRates: paymentParty.PaymentRates.map((paymentRate, index) => {
            const paymentRateDto = paymentInfoDto.PaymentRates[index]; // TODO: match by RateTypeId?

            return {
              ...paymentRateDto,
              ...{
                RateTypeId: paymentRate.RateTypeId,
                Rate: paymentRate.Rate,
                RateUnitId: paymentRate.RateUnitId,
                Description: paymentRate.Description,
                IsApplyDeductions: paymentRate.IsApplyDeductions,
                IsApplyVacation: paymentRate.IsApplyVacation,
                IsApplyStatHoliday: paymentRate.IsApplyStatHoliday
              }
            };
          }),

          PaymentContacts: (paymentParty.PaymentContacts ?? []).map((paymentContact, index) => {
            const paymentContactDto = paymentInfoDto.PaymentContacts[index];

            return {
              ...paymentContactDto,
              ...{
                UserProfileId: paymentContact.UserProfileId
              }
            };
          })
        }
      };
    });

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const paymentParties = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(paymentParties, { emitEvent: false });
    
    FormServiceHelper.addRemoveFormArrayControls(this.formGroup, this.fb);

    this.formGroup.controls.forEach((control, index) => {
      const paymentPartyFormGroup = control as FormGroup<IPaymentParty>;
      
      const paymentRatesFormArray = paymentPartyFormGroup.get("PaymentRates") as FormArray<IPaymentRate>;
      FormServiceHelper.addRemoveFormArrayControls(paymentRatesFormArray, this.fb);

      const paymentContactsFormArray = paymentPartyFormGroup.get("PaymentContacts") as FormArray<IPaymentContact>;
      FormServiceHelper.addRemoveFormArrayControls(paymentContactsFormArray, this.fb);

      // Updates all validators/disabled down the tree
      this.updatePaymentPartyValidatorsAndDisabled(paymentPartyFormGroup, index);
    });

    this.setupOrganizationIdSupplierListeners();

  }

  addPaymentPartyFormGroup() {
    const formGroup = this.createBlankPaymentPartyFormGroup();
    this.paymentPartiesRateDetailFormArray.push(formGroup);
    if (!this.organizationIdSupplierSubscription$) {
      this.organizationIdSupplierSubscription$ = new Subscription();
    }
    this.organizationIdSupplierSubscription$.add(this.getOrganizationIdSupplierListener(formGroup));

    this.timeMaterialInvoiceTabFormService.addPaymentInfo();
    // TODO: Call Expense tab form service addPaymentInfo();
    // TODO: Call Incentive Compensation form service addPaymenInfo();
  }

  deletePaymentPartiesRateDetailFormGroup(index: number) {
    this.paymentPartiesRateDetailFormArray.removeAt(index);
    this.setupOrganizationIdSupplierListeners();
    this.organizationIdSupplierChange.next(null);
  }

  addPaymentPartyRate(rateTypeId: number) {

    const paymentRatesFormArrays = this.getAllPaymentRateFormArrays();

    paymentRatesFormArrays.forEach((paymentRates) => {
      paymentRates.push(this.createBlankPaymentRateFormGroup({
        RateTypeId: rateTypeId,
        Rate: null,
        RateUnitId: null,
        Description: null,
        IsApplyDeductions: null,
        IsApplyVacation: null,
        IsApplyStatHoliday: null,
      }));
    });
  }

  deletePaymentPartyRate(rateTypeId: number) {
    const paymentRatesFormArrays = this.getAllPaymentRateFormArrays();

    paymentRatesFormArrays.forEach((paymentRates) => {
      const formGroup = paymentRates.controls.find(x => x.value.RateTypeId == rateTypeId);

      if (formGroup) {
        const index = paymentRates.controls.indexOf(formGroup);
        paymentRates.removeAt(index);
      }
    });
  }

  updateCurrenyIdFormControl(index: number, value: number, emitEvent = false) {
    const formGroup = this.formGroup.at(index) as FormGroup<IPaymentParty>;
    const formControl = formGroup.get('CurrencyId');
    formControl.patchValue(value, { emitEvent });
  }

  updateHoursFormControl(index: number, value, emitEvent = false) {
    const formGroup = this.formGroup.at(index) as FormGroup<IPaymentParty>;
    const formControl = formGroup.get('Hours');
    formControl.patchValue(value, { emitEvent });
  }

  updateAllRateUnitIdFormControls(value: number, rateTypeId: number, emitEvent = false) {
    const formGroups = this.getAllRateFormGroupsByType(rateTypeId)

    formGroups.forEach(rateFormGroup => {
      rateFormGroup.get("RateUnitId").patchValue(value, { emitEvent });;
    })
  }

  getAllRateFormGroupsByType(rateTypeId: number) {
    const allRateFormGroups = this.getAllPaymentRateFormArrays()
      .flatMap(x => x.controls) as FormGroup<IPaymentRate>[];

    return allRateFormGroups
      .filter(formGroup => formGroup.value.RateTypeId = rateTypeId);
  }

  getAllPaymentRateFormArrays() {
    const ratesFormArrays: FormArray<IPaymentRate>[] = [];

    this.formGroup.controls.forEach(formGroup => {
      const ratesFormArray = formGroup.get("PaymentRates") as FormArray<IPaymentRate>;

      if (ratesFormArray) {
        ratesFormArrays.push(ratesFormArray);
      }
    });

    return ratesFormArrays;
  }

  addPaymentContact(index: number) {
    const formGroup = this.formGroup.at(index) as FormGroup<IPaymentParty>;
    const contactsGroup = formGroup.get('PaymentContacts') as FormArray<IPaymentContact>;
    contactsGroup.push(this.createPaymentContactFormGroup({
      UserProfileId: null
    }));

    // TODO: Update validators
  }

  updatePaymentRateDescription(paymentPartyIndex: number, rateTypeId: number, value: string, emitEvent = false) {
    const formGroup = this.formGroup.at(paymentPartyIndex) as FormGroup<IPaymentParty>;

    const ratesFormArray = formGroup.get("PaymentRates") as FormArray<IPaymentRate>;
    const rateFormGroup = ratesFormArray.controls.find(rate => rate.value.RateTypeId == rateTypeId);

    if (rateFormGroup) {
      rateFormGroup.get('Description').patchValue(value, { emitEvent });
    }
  }

  private setupOrganizationIdSupplierListeners() {
    if (this.organizationIdSupplierSubscription$ && !this.organizationIdSupplierSubscription$.closed) {
      this.organizationIdSupplierSubscription$.unsubscribe();
      this.organizationIdSupplierSubscription$ = null;
    }

    this.organizationIdSupplierSubscription$ = new Subscription();

    this.paymentPartiesRateDetailFormArray.controls.forEach(group => {
      this.organizationIdSupplierSubscription$.add(this.getOrganizationIdSupplierListener(group as FormGroup<IPaymentParty>));
    });
  }

  private getOrganizationIdSupplierListener(formGroup: FormGroup<IPaymentParty>) {
    return formGroup.get('OrganizationIdSupplier').valueChanges
      .pipe(distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(value => {
        this.organizationIdSupplierChange.next(value);
      });
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): Array<IPaymentParty> {
    const billingHour = workorder.WorkOrderVersion.BillingInfoes[0].Hours;
    const paymentParties: IPaymentParty[] = workorder.WorkOrderVersion.PaymentInfoes
    .map(paymentInfo => {
      return {
        Id: paymentInfo.Id,
        Hours: billingHour, // I guess as a precaution?
        CurrencyId: paymentInfo.CurrencyId,
        OrganizationIdSupplier: paymentInfo.OrganizationIdSupplier,
        OrganizationRoleTypeId: paymentInfo.OrganizationRoleTypeId,
        UserProfileIdSupplier: paymentInfo.UserProfileIdSupplier,
        IsCommissionVacation: paymentInfo.IsCommissionVacation,
        PaymentContacts: paymentInfo.PaymentContacts.map(contact => {
          const paymentContact: IPaymentContact = {
            UserProfileId: contact.UserProfileId
          };
          return paymentContact;
        }),
        PaymentRates: paymentInfo.PaymentRates.map(rate => {
          const paymentRate: IPaymentRate = {
            RateTypeId: rate.RateTypeId,
            Rate: rate.Rate,
            RateUnitId: rate.RateUnitId,
            Description: rate.Description,
            IsApplyDeductions: rate.IsApplyDeductions,
            IsApplyVacation: rate.IsApplyVacation,
            IsApplyStatHoliday: rate.IsApplyStatHoliday
          }
          return paymentRate;
        })
      };
    });

    return paymentParties;
  }

  private createPaymentPartyFormGroup(paymentParty: IPaymentParty): FormGroup<IPaymentParty> {
    return this.fb.group<IPaymentParty>({
      ...paymentParty,
      PaymentRates: this.createPaymentRateFormArray(paymentParty.PaymentRates),
      PaymentContacts: this.createPaymentContactFormArray(paymentParty.PaymentContacts),
    });
  }

  updatePaymentPartyValidatorsAndDisabled(formGroup: FormGroup<IPaymentParty>, index: number) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentPartyValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetPaymentPartyDisabled(formGroup, index));

    const paymentRates = formGroup.get("PaymentRates") as FormArray<IPaymentRate>;
    paymentRates.controls.forEach(rate => {
      this.updatePaymentRateValidatorsAndDisabled(rate as FormGroup<IPaymentRate>);
    })

    const paymentContacts = formGroup.get("PaymentContacts") as FormArray<IPaymentContact>;
    paymentContacts.controls.forEach(contact => {
      this.updatePaymentContactValidatorsAndDisabled(contact as FormGroup<IPaymentContact>);
    });
  }

  private onGetPaymentPartyValidators(formGroup: FormGroup<IPaymentParty>): ValidatorsConfig<IPaymentParty> {
    return {
      Hours: [Validators.required], // TODO: Previously not required when payment party index > 1?? (readonly)
      CurrencyId: [Validators.required],
      OrganizationIdSupplier: [Validators.required],
      OrganizationRoleTypeId: [Validators.required],
      UserProfileIdSupplier: [Validators.required],
    };
  }

  private onGetPaymentPartyDisabled(formGroup: FormGroup<IPaymentParty>, index: number): DisabledConfig<IPaymentParty> {
    const organizationIdSupplier = formGroup.get("OrganizationIdSupplier").value;
    return {
      OrganizationRoleTypeId: index == 0,
      PaymentContacts: organizationIdSupplier == null
    };
  }

  private createBlankPaymentPartyFormGroup(): FormGroup<IPaymentParty> {
    const firstPaymentParty = this.formGroup.at(0).value;

    return this.fb.group<IPaymentParty>({
      CurrencyId: null,
      Hours: firstPaymentParty.Hours,
      OrganizationIdSupplier: null,
      OrganizationRoleTypeId: null,
      UserProfileIdSupplier: null,
      PaymentRates: this.createBlankPaymentRateFormArray(firstPaymentParty.PaymentRates),
      PaymentContacts: this.fb.array([]),
    });
  }

  private createBlankPaymentRateFormArray(paymentRates: IPaymentRate[]): FormArray<IPaymentRate> {
    return this.fb.array<IPaymentRate>(
      paymentRates.map(paymentRate => this.createBlankPaymentRateFormGroup(paymentRate))
    );
  }

  private updatePaymentRateValidatorsAndDisabled(formGroup: FormGroup<IPaymentRate>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentRateValidators(formGroup));
  }

  private onGetPaymentRateValidators(formGroup: FormGroup<IPaymentRate>): ValidatorsConfig<IPaymentRate> {
    return {
      Rate: [Validators.required],
      RateTypeId: [Validators.required],
      RateUnitId: [Validators.required] // TODO: Previously not required when payment party index > 1???
    };
  }

  private createBlankPaymentRateFormGroup(paymentRate: IPaymentRate): FormGroup<IPaymentRate> {
    const workerLocationId = this.coreDetailFormService.workerLocationIdFormControl.value;
    const defaultDeductionConfig = PhxConstants.DefaultPaymentRateDeductions.find(r => r.RateTypeId === paymentRate.RateTypeId);
    const defaultConfig = defaultDeductionConfig ? defaultDeductionConfig.defaults.find(config => config.SubdivisionId === workerLocationId) : null;
    let isApplyVacation = true;
    let isApplyDeductions = true;

    if (defaultConfig) {
      isApplyVacation = defaultConfig.IsApplyVacation;
      isApplyDeductions = defaultConfig.IsApplyDeductions;
    }

    return this.fb.group<IPaymentRate>({
      Rate: null,
      RateTypeId: paymentRate.RateTypeId,
      RateUnitId: paymentRate.RateUnitId,
      IsApplyDeductions: isApplyDeductions,
      IsApplyVacation: isApplyVacation,
      IsApplyStatHoliday: null,
      Description: null
    });
  }

  // TODO: Move to a payment-invoice-form.servce.ts?

  // TODO: Call TabTimeMaterialInvoice, TabExpenseInvoice, and Incentive comp. to create these

  private createDefaultExpensePaymentInvoice(): IPaymentInvoice {
    return {
      InvoiceTypeId: PhxConstants.InvoiceType.Expense,
      PaymentInvoiceTemplateId: null,
      PaymentMethodId: null,
      PaymentReleaseScheduleId: null,
      PaymentFrequency: null,
      PaymentInvoiceTermsId: null,
      IsSalesTaxAppliedOnVmsImport: null
    };
  }

  private createDefaultIncentiveCompensationPaymentInvoice(): IPaymentInvoice {
    return {
      InvoiceTypeId: PhxConstants.InvoiceType.IncentiveCompensation,
      PaymentInvoiceTemplateId: PhxConstants.PaymentInvoiceTemplate.PCGLStandardPaymentVoucher,
      PaymentMethodId: PhxConstants.PaymentMethodType.FromPayeeProfile,
      PaymentReleaseScheduleId: null,
      PaymentFrequency: null,
      PaymentInvoiceTermsId: null,
      IsSalesTaxAppliedOnVmsImport: null
    };
  }

  private createPaymentRateFormArray(paymentRates: Array<IPaymentRate>): FormArray<IPaymentRate> {
    return this.fb.array<IPaymentRate>(
      paymentRates.map((paymentRate: IPaymentRate) => this.createPaymentRateFormGroup(paymentRate))
    );
  }

  private createPaymentRateFormGroup(paymentRate: IPaymentRate): FormGroup<IPaymentRate> {
    return this.fb.group<IPaymentRate>(paymentRate);
  }

  private createPaymentContactFormArray(paymentContacts: Array<IPaymentContact>): FormArray<IPaymentContact> {
    return this.fb.array<IPaymentContact>(
      paymentContacts.map((paymentContact: IPaymentContact) => this.createPaymentContactFormGroup(paymentContact))
    );
  }

  private updatePaymentContactValidatorsAndDisabled(formGroup: FormGroup<IPaymentContact>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentContactValidators(formGroup));
  }

  private onGetPaymentContactValidators(formGroup: FormGroup<IPaymentContact>): ValidatorsConfig<IPaymentContact> {
    return {
      UserProfileId: [Validators.required]
    };
  }

  private createPaymentContactFormGroup(paymentContact: IPaymentContact): FormGroup<IPaymentContact> {
    return this.fb.group<IPaymentContact>(paymentContact);
  }

}
