import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ComplianceRuleOverride } from './models';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ComplianceDataService {
  private readonly apiEndpoint = environment.complianceServiceApiEndpoint;

  constructor(private http: HttpClient) {}

  public createComplianceDataRuleOverride(
    complianceDataId: string,
    ruleId: string,
    entityType: string,
    entityId: string,
    body: {
        isValid: boolean,
        reasonCode: string | null,
        reasonText: string | null
    }
  ) {
    const path = `compliance/compliance-data/${complianceDataId}/rule-results/${ruleId}/overrides`;
    const queryString = `entityType=${entityType}&entityId=${entityId}`;
    
    return this.http.post<void>(`${this.apiEndpoint}/${path}?${queryString}`, body);
  }

  public getComplianceRuleOverrides(entityType: string, entityId: string | string[], filePublicId?: string) {
    const path = 'compliance-rule-override';
    const params: { [key: string]: string | string[] } = {
      entityType,
      entityId
    };

    // HttpParams will send the string "undefined" for undefined values, so need to conditionally add optional parameters
    if (filePublicId) {
      params.filePublicId = filePublicId;
    }


    const httpParams = new HttpParams({ 
      fromObject: params
    });

    return this.http.get<ComplianceRuleOverride[]>(
      `${this.apiEndpoint}/${path}`,
      { params: httpParams }
    );
  }

  public deleteComplianceRuleOverride(id: string, entityType: string, entityId: string) {
    const path = `compliance-rule-override/${id}`;
    const queryString = `entityType=${entityType}&entityId=${entityId}`;

    return this.http.delete<void>(`${this.apiEndpoint}/${path}?${queryString}`);
  }
}
