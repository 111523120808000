import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { DisabledConfig, FormServiceHelper, IFormService, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkOrder } from '../../models';
import { ITabTaxes, ITabTaxesBillingParty, IBillingSalesTax, ITabTaxesPaymentParty, IPaymentSalesTax } from '../../models/work-order-form.interface';
import { WorkorderService } from '../workorder.service';

@Injectable()
export class TaxesTabFormService implements IFormService {
  formGroup: FormGroup<ITabTaxes>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private workOrder: IWorkOrder;
  private subscription$ = new Subscription();

  billingSalesTaxJurisdictions: Array<any>;
  paymentSalesTaxJurisdictions: Array<any>;

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkorderService
  ) {
  }

  get billingInfoesFormArray(): FormArray<ITabTaxesBillingParty> {
    return this.formGroup.get('BillingInfoes') as FormArray<ITabTaxesBillingParty>;
  }

  // Only 1
  get billingInfoFormGroup(): FormGroup<ITabTaxesBillingParty> {
    return this.billingInfoesFormArray.at(0) as FormGroup<ITabTaxesBillingParty>;
  }

  get paymentInfoesFormArray(): FormArray<ITabTaxesPaymentParty> {
    return this.formGroup.get('PaymentInfoes') as FormArray<ITabTaxesPaymentParty>;
  }

  get billingSalesTaxesFormArray(): FormArray<IBillingSalesTax> {
    const formGroup = this.billingInfoesFormArray.at(0);
    return formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
  }

  mapWorkOrderToFormData(workorder: IWorkOrder): ITabTaxes {
    const workOrderVersion = workorder.WorkOrderVersion;
    return {
      BillingInfoes: workOrderVersion.BillingInfoes.map(billingInfoDto => {
        const billingInfo: ITabTaxesBillingParty = {
          SubdivisionIdSalesTax: billingInfoDto.SubdivisionIdSalesTax,
          JurisdictionId: billingInfoDto.JurisdictionId,
          BillingSalesTaxes: billingInfoDto.BillingSalesTaxes.map(salesTaxDto => {
            const salesTax: IBillingSalesTax = {
              SalesTaxId: salesTaxDto.SalesTaxId,
              IsApplied: salesTaxDto.IsApplied
            };
            return salesTax;
          })
        };

        return billingInfo;
      }),
      PaymentInfoes: workOrderVersion.PaymentInfoes.map(paymentInfoDto => {
        const paymentInfo: ITabTaxesPaymentParty = {
          SubdivisionIdSalesTax: paymentInfoDto.SubdivisionIdSalesTax,
          JurisdictionId: paymentInfoDto.JurisdictionId,
          ApplySalesTax: paymentInfoDto.ApplySalesTax,
          PaymentSalesTaxes: paymentInfoDto.PaymentSalesTaxes.map(salesTaxDto => {
            const salesTax: IPaymentSalesTax = {
              SalesTaxId: salesTaxDto.SalesTaxId,
              IsApplied: salesTaxDto.IsApplied
            };
            return salesTax;
          })
        };
        return paymentInfo;
      })
    };
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTaxes> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabTaxes>({
      ...formValue,
      BillingInfoes: this.createBillingInfoFormArray(formValue.BillingInfoes),
      PaymentInfoes: this.createPaymentInfoFormArray(formValue.PaymentInfoes),
    });

    this.workOrder = workorder;

    this.calculateSalesTaxFormArray(); // TODO: When should we do this? Shouldn't do this if WO is read-only?

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
    this.workOrder = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
      if (this.subscription$ && !this.subscription$.closed) {
        this.subscription$.unsubscribe();
        this.subscription$ = null;
      }
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const billingInfoes = this.billingInfoesFormArray.value;
    const paymentInfoes = this.paymentInfoesFormArray.value;

    billingInfoes.forEach((i, index) => {
      workOrder.WorkOrderVersion.BillingInfoes.forEach(() => {
        this.billingInfoFormGroupToPartial(workOrder, i, index);
      });
    });

    if (paymentInfoes) {
      paymentInfoes.forEach((i, index) => {
          workOrder.WorkOrderVersion.PaymentInfoes.forEach(() => {
            this.paymentInfoFormGroupToPartial(workOrder, i, index);
          });
      });
    }
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
      this.subscription$ = new Subscription();
    }

    const formValue = this.mapWorkOrderToFormData(workorder);

    const paymentInfoFormArray = this.formGroup.get('PaymentInfoes') as FormArray<ITabTaxesPaymentParty>;
    this.updatePaymentInfoFormArray(paymentInfoFormArray, formValue.PaymentInfoes);

    this.updateBillingInfoForm(formValue.BillingInfoes);
    this.workOrder = workorder;

    this.calculateSalesTaxFormArray(); // TODO: When should we do this? Shouldn't when WO is read only?

    this.updateValidatorsAndDisabled();
  }

  updateBillingInfoForm(billingInfos: ITabTaxesBillingParty[]) {
    const billingInfoFormArray = this.formGroup.get('BillingInfoes') as FormArray<ITabTaxesBillingParty>;
    this.updateBillingInfoFormArray(billingInfoFormArray, billingInfos);
    this.updateBillingSalesTaxesFormArray(this.billingSalesTaxesFormArray, billingInfos[0].BillingSalesTaxes);
  }

  billingInfoFormGroupToPartial(workOrder: IWorkOrder, billingInfo: ITabTaxesBillingParty, index: number) {
    //const index = workOrder.WorkOrderVersion.BillingInfoes[index].findIndex(x => x.Id === billingInfo.Id);
    const billingInfoDto = workOrder.WorkOrderVersion.BillingInfoes[index];

    workOrder.WorkOrderVersion.BillingInfoes[index] = {
      ...billingInfoDto,
      SubdivisionIdSalesTax: billingInfo.SubdivisionIdSalesTax,
      JurisdictionId: billingInfo.JurisdictionId,
      BillingSalesTaxes: billingInfo.BillingSalesTaxes.map(salesTax => {
        const salesTaxDto = billingInfoDto.BillingSalesTaxes[0]; // TODO: by sales tax type id?
        return {
          ...salesTaxDto,
          ...{
            SalesTaxId: salesTax.SalesTaxId,
            IsApplied: salesTax.IsApplied
          }
        };
      })
    };
    return workOrder;
  }

  paymentInfoFormGroupToPartial(workOrder: IWorkOrder, paymentInfo: ITabTaxesPaymentParty, index: number) {
    //const index = workOrder.WorkOrderVersion.PaymentInfoes.findIndex(x => x.Id === paymentInfo.Id);
    const paymentInfoDto = workOrder.WorkOrderVersion.PaymentInfoes[index];
    workOrder.WorkOrderVersion.PaymentInfoes[index] = {
      ...paymentInfoDto,
      SubdivisionIdSalesTax: paymentInfo.SubdivisionIdSalesTax,
      JurisdictionId: paymentInfo.JurisdictionId,
      ApplySalesTax: paymentInfo.ApplySalesTax,
      PaymentSalesTaxes: paymentInfo.PaymentSalesTaxes.map(salesTax => {
        const salesTaxDto = paymentInfoDto.PaymentSalesTaxes[0]; // TODO: by sales tax type id?
        return {
          ...salesTaxDto,
          ...{
            SalesTaxId: salesTax.SalesTaxId,
            IsApplied: salesTax.IsApplied
          }
        };
      })
    };
    return workOrder;
  }

  updatePaymentInfoSalesTax(paymentSalesTaxes: Array<IPaymentSalesTax>, index: number) {
    if (index > -1) {
      const formGroup = this.paymentInfoesFormArray.at(index);
      const paymentSalesTaxesFormArray = formGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
      this.updatePaymentSalesTaxFormArray(paymentSalesTaxesFormArray, paymentSalesTaxes);
    }
  }

  private createBillingInfoFormArray(billingInfos: ITabTaxesBillingParty[]): FormArray<ITabTaxesBillingParty> {
    return this.fb.array<ITabTaxesBillingParty>(
      billingInfos.map(billingInfo => this.createBillingInfoFormGroup(billingInfo))
    );
  }

  private updateValidatorsAndDisabled() {
    const billingInfos = this.formGroup.get('BillingInfoes') as FormArray<ITabTaxesBillingParty>;
    billingInfos.controls.forEach(billingInfo => {
      this.updateBillingInfoValidatorsAndDisabled(billingInfo as FormGroup<ITabTaxesBillingParty>);
    });

    const paymentInfos = this.formGroup.get('PaymentInfoes') as FormArray<ITabTaxesPaymentParty>;
    paymentInfos.controls.forEach(paymentInfo => {
      this.updatePaymentInfoValidatorsAndDisabled(paymentInfo as FormGroup<ITabTaxesPaymentParty>);
    });
  };

  private updateBillingInfoValidatorsAndDisabled(formGroup: FormGroup<ITabTaxesBillingParty>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingInfoValidators());

    const billingSalesTaxes = formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
    billingSalesTaxes.controls.forEach(salesTax => {
      this.updateBillingSalesTaxValiatorsAndDisabled(salesTax as FormGroup<IBillingSalesTax>);
    });
  }

  private onGetBillingInfoValidators(): ValidatorsConfig<ITabTaxesBillingParty> {
    return {
      SubdivisionIdSalesTax: [Validators.required]
    };
  }

  private createBillingInfoFormGroup(billingInfo: ITabTaxesBillingParty): FormGroup<ITabTaxesBillingParty> {
    return this.fb.group<ITabTaxesBillingParty>({
      ...billingInfo,
      BillingSalesTaxes: this.createBillingSalesTaxFormArray(billingInfo.BillingSalesTaxes)
    });
  }

  private createBillingSalesTaxFormArray(taxes: IBillingSalesTax[]): FormArray<IBillingSalesTax> {
    return this.fb.array<IBillingSalesTax>(
      taxes.map((billingTax: IBillingSalesTax) => this.createBillingSalesTaxFormGroup(billingTax))
    );
  }

  private updateBillingSalesTaxValiatorsAndDisabled(formGroup: FormGroup<IBillingSalesTax>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingSalesTaxValidators());
  }

  private onGetBillingSalesTaxValidators(): ValidatorsConfig<IBillingSalesTax> {
    return {
      IsApplied: [Validators.required]
    };
  }

  private createBillingSalesTaxFormGroup(billingSalesTax: IBillingSalesTax): FormGroup<IBillingSalesTax> {
    return this.fb.group<IBillingSalesTax>(billingSalesTax);
  }

  private createPaymentInfoFormArray(paymentInfoes: ITabTaxesPaymentParty[]): FormArray<ITabTaxesPaymentParty> {
    return this.fb.array<ITabTaxesPaymentParty>(
      paymentInfoes.map(paymentInfo => this.createPaymentInfoFormGroup(paymentInfo))
    );
  }

  private updatePaymentInfoValidatorsAndDisabled(formGroup: FormGroup<ITabTaxesPaymentParty>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentInfoValidators());
    FormServiceHelper.setDisabled(formGroup, this.onGetPaymentInfoDisabled(formGroup));

    const paymentSalesTaxes = formGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
    paymentSalesTaxes.controls.forEach(salesTax => {
      this.updatePaymentSalesTaxValiatorsAndDisabled(salesTax as FormGroup<IPaymentSalesTax>);
    });
  }

  private onGetPaymentInfoValidators(): ValidatorsConfig<ITabTaxesPaymentParty> {
    return {
      SubdivisionIdSalesTax: [Validators.required]
    };
  }

  private onGetPaymentInfoDisabled(formGroup: FormGroup<ITabTaxesPaymentParty>): DisabledConfig<ITabTaxesPaymentParty> {
    const applySalesTax = formGroup.get('ApplySalesTax').value;
    
    return {
      SubdivisionIdSalesTax: !applySalesTax, // TODO: !(PaymentInfo.ApplySalesTax && Has supplier org && worker SP or LLC)?
      JurisdictionId: !applySalesTax
    };
  }

  private createPaymentInfoFormGroup(paymentInfo: ITabTaxesPaymentParty): FormGroup<ITabTaxesPaymentParty> {
    return this.fb.group<ITabTaxesPaymentParty>({
      ...paymentInfo,
      PaymentSalesTaxes: this.createPaymentSalesTaxFormArray(paymentInfo.PaymentSalesTaxes)
    });
  }

  private createPaymentSalesTaxFormArray(taxes: IPaymentSalesTax[]): FormArray<IPaymentSalesTax> {
    return this.fb.array<IPaymentSalesTax>(
      taxes.map((paymentTax: IPaymentSalesTax) => this.createPaymentSalesTaxFormGroup(paymentTax))
    );
  }

  private updatePaymentSalesTaxValiatorsAndDisabled(formGroup: FormGroup<IPaymentSalesTax>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentSalesTaxValidators());
  }


  private onGetPaymentSalesTaxValidators(): ValidatorsConfig<IPaymentSalesTax> {
    return {
      IsApplied: [Validators.required]
    };
  }

  private createPaymentSalesTaxFormGroup(paymentSalesTax: IPaymentSalesTax): FormGroup<IPaymentSalesTax> {
    return this.fb.group<IPaymentSalesTax>(paymentSalesTax);
  }

  private updateBillingInfoFormArray(
    formArray: FormArray<ITabTaxesBillingParty>,
    billingInfos: ITabTaxesBillingParty[]
  ) {

    if (formArray.length && billingInfos.length) {
      billingInfos.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<ITabTaxesBillingParty>;
        if (formGroup) {
          this.updateBillingInfoFormGroup(formGroup, item);
        } else {
          formArray.push(this.createBillingInfoFormGroup(item));
        }
      });
      if (formArray.length > billingInfos.length) {
        this.clearArray(formArray, billingInfos.length);
      }
    } else if (billingInfos.length) {
      const array = this.createBillingInfoFormArray(billingInfos);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingInfoFormGroup(
    formGroup: FormGroup<ITabTaxesBillingParty>,
    billingInfo: ITabTaxesBillingParty
  ) {
    formGroup.patchValue({
      SubdivisionIdSalesTax: billingInfo.SubdivisionIdSalesTax,
      JurisdictionId: billingInfo.JurisdictionId,
    }, { emitEvent: false });

    const billingSalesTaxesFormArray = formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
    this.updateBillingSalesTaxesFormArray(billingSalesTaxesFormArray, billingInfo.BillingSalesTaxes);
  }

  private updateBillingSalesTaxes(billingInfo: ITabTaxesBillingParty, orgInternalId: number) {

    // TODOL: any
    this.workOrderService.getBillingSalesTaxes(billingInfo as any, orgInternalId).then(saleTaxes => {
      this.billingSalesTaxJurisdictions = this.workOrderService.billingSalesTaxJurisdictions;
      this.updateBillingSalesTaxesFormArray(this.billingSalesTaxesFormArray, saleTaxes);
    });
  }

  private async calculateSalesTaxFormArray() {
    const internalId = null; // TODO this.internalIdFormControl.value;
    const promiseArray = this.billingInfoesFormArray.controls
      .map((group: FormGroup<ITabTaxesBillingParty>) => this.calculateSalesTaxFormGroup(group, internalId));
    await Promise.all(promiseArray);

    const billingInfoFormGroup = this.billingInfoesFormArray.at(0);
    const billingSubdivisionIdSalesTaxControl = billingInfoFormGroup ? billingInfoFormGroup.get('SubdivisionIdSalesTax') : null;
    const billingJurisdictionIdControl = billingInfoFormGroup ? billingInfoFormGroup.get('JurisdictionId') : null;

    if (!this.subscription$ || this.subscription$.closed) {
      this.subscription$ = new Subscription();
    }
    if (billingSubdivisionIdSalesTaxControl && this.workOrder) {
      this.subscription$.add(billingSubdivisionIdSalesTaxControl.valueChanges.pipe(
        startWith(billingSubdivisionIdSalesTaxControl.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(([prev, next]) => {
        if (next !== prev) {
          billingJurisdictionIdControl.setValue(null);
          
          this.billingInfoFormGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });
          
          this.updateBillingSalesTaxes(
            this.billingInfoFormGroup.value,
            null// TODO: this.internalIdFormControl.value
          );
        }
      }));
    }
    if (billingJurisdictionIdControl && this.workOrder) {
      this.subscription$.add(billingJurisdictionIdControl.valueChanges.pipe(
        startWith(billingJurisdictionIdControl.value),
        distinctUntilChanged(),
        pairwise(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(([prev, next]) => {
        if (next !== prev) {
          this.billingInfoFormGroup.updateValueAndValidity({ onlySelf: true, emitEvent: false });

          this.updateBillingSalesTaxes(
            this.billingInfoFormGroup.value,
            null //this.internalIdFormControl.value // TODO
          );
        }
      }));
    }

    const paymentInfoFormArray = this.formGroup.get('PaymentInfoes') as FormArray<ITabTaxesPaymentParty>;
    paymentInfoFormArray.controls.forEach((group, index) => {
      this.subscription$.add(this.getSubdivisionSalesTaxListener(group as FormGroup<ITabTaxesPaymentParty>, index));
      this.subscription$.add(this.getJurisdictionIdListener(group as FormGroup<ITabTaxesPaymentParty>, index));
    });
  }

  private getSubdivisionSalesTaxListener(formGroup: FormGroup<ITabTaxesPaymentParty>, index: number) {
    const initialValue = formGroup ? formGroup.get('SubdivisionIdSalesTax').value : null;
    return formGroup.get('SubdivisionIdSalesTax').valueChanges
      .pipe(
        startWith(initialValue),
        distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(async (subsdivisionIdSalesTax) => {
        const paymentInfo = formGroup.value;
        paymentInfo.SubdivisionIdSalesTax = subsdivisionIdSalesTax;
        if (initialValue !== subsdivisionIdSalesTax) {
          paymentInfo.JurisdictionId = null;
          formGroup.get('JurisdictionId').setValue(null);
        }
        const paymentSalesTaxes = await this.workOrderService.getPaymentSalesTaxes(
          paymentInfo as any, // TODO
          index,
          this.workOrder.UserProfileIdWorker,
          this.workOrder.workerProfileTypeId
        );
        this.paymentSalesTaxJurisdictions = this.workOrderService.paymentSalesTaxJurisdictions;

        this.updatePaymentInfoSalesTax(
          paymentSalesTaxes,
          index
        );
      });
  }

  private getJurisdictionIdListener(formGroup: FormGroup<ITabTaxesPaymentParty>, index: number) {
    const paymentJurisdictionIdControl = formGroup ? formGroup.get('JurisdictionId') : null;
    return paymentJurisdictionIdControl.valueChanges.pipe(
        startWith(paymentJurisdictionIdControl.value),
        distinctUntilChanged(),
        takeUntil(this.isRootComponentDestroyed$)
      ).subscribe(async (jurisdictionId) => {
        const paymentInfo = formGroup.value;
        paymentInfo.JurisdictionId = jurisdictionId;
        const paymentSalesTaxes = await this.workOrderService.getPaymentSalesTaxes(
          paymentInfo as any, // TODO
          index,
          this.workOrder.UserProfileIdWorker,
          this.workOrder.workerProfileTypeId
        );
        this.paymentSalesTaxJurisdictions = this.workOrderService.paymentSalesTaxJurisdictions;

        this.updatePaymentInfoSalesTax(
          paymentSalesTaxes,
          index
        );
      });
  }

  private async calculateSalesTaxFormGroup(
    formGroup: FormGroup<ITabTaxesBillingParty>,
    orgInternalId: number
  ): Promise<void> {
    const subdivisionIdSalesTax = formGroup.get('SubdivisionIdSalesTax').value;
    const billingSalesTaxFormArray = formGroup.get('BillingSalesTaxes') as FormArray<IBillingSalesTax>;
    const billingSalesTaxesFormValue = billingSalesTaxFormArray?.value || [];
    const needPercentage = Boolean (billingSalesTaxFormArray?.value
    && billingSalesTaxFormArray.value.length) /* TODO billingSalesTaxFormArray.value.some(x => !x.ratePercentage && x.ratePercentage !== 0)*/;

    // If any sales taxes don't have the percentage (won't be in the form though) and SubdivisionIdSalesTax and InternalOrg Id is set
    if (needPercentage && subdivisionIdSalesTax && orgInternalId) {
      // Get applicable taxes
      const response: Array<IBillingSalesTax> = await this.workOrderService.getBillingSalesTaxes(
        formGroup.value as any, // TODO
        orgInternalId
      );

      this.billingSalesTaxJurisdictions = this.workOrderService.billingSalesTaxJurisdictions;


      const billingSalesTaxes: Array<IBillingSalesTax> = [];
      response.forEach(salesTax => {
        const existingSalesTax = billingSalesTaxesFormValue.find(x => x.SalesTaxId === salesTax.SalesTaxId);
        billingSalesTaxes.push({
          SalesTaxId: salesTax.SalesTaxId,
          IsApplied: existingSalesTax?.IsApplied !== undefined && existingSalesTax.IsApplied !== null ? existingSalesTax.IsApplied : salesTax.IsApplied
        });
      });

      this.updateBillingSalesTaxesFormArray(billingSalesTaxFormArray, billingSalesTaxes);
    }
  }

  private updateBillingSalesTaxesFormArray(
    formArray: FormArray<IBillingSalesTax>,
    taxes: IBillingSalesTax[]
  ) {
    if (formArray.length && taxes.length) {
      taxes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IBillingSalesTax>;
        if (formGroup) {
          this.updateBillingSalesTaxFormGroup(formGroup, item);
        } else {
          formArray.push(this.createBillingSalesTaxFormGroup(item));
        }
      });
      if (formArray.length > taxes.length) {
        this.clearArray(formArray, taxes.length);
      }
    } else if (taxes.length) {
      const array = this.createBillingSalesTaxFormArray(taxes);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updateBillingSalesTaxFormGroup(
    formGroup: FormGroup<IBillingSalesTax>,
    billingSalesTax: IBillingSalesTax
  ) {
    formGroup.patchValue(billingSalesTax, { emitEvent: false });
  }

  private updatePaymentInfoFormArray(
    formArray: FormArray<ITabTaxesPaymentParty>,
    paymentInfoes: ITabTaxesPaymentParty[]
  ) {
    if (formArray.length && paymentInfoes.length) {
      paymentInfoes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<ITabTaxesPaymentParty>;
        if (formGroup) {
          this.updatePaymentInfoFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentInfoFormGroup(item));
        }
      });
      if (formArray.length > paymentInfoes.length) {
        this.clearArray(formArray, paymentInfoes.length);
      }
    } else if (paymentInfoes.length) {
      const array = this.createPaymentInfoFormArray(paymentInfoes);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentInfoFormGroup(
    formGroup: FormGroup<ITabTaxesPaymentParty>,
    paymentInfo: ITabTaxesPaymentParty
  ) {
    formGroup.patchValue(paymentInfo, { emitEvent: false });

    const paymentSalesTaxesFormArray = formGroup.get('PaymentSalesTaxes') as FormArray<IPaymentSalesTax>;
    this.updatePaymentSalesTaxFormArray(paymentSalesTaxesFormArray, paymentInfo.PaymentSalesTaxes);
  }

  private updatePaymentSalesTaxFormArray(
    formArray: FormArray<IPaymentSalesTax>,
    taxes: IPaymentSalesTax[]
  ) {
    if (formArray.length && taxes.length) {
      taxes.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentSalesTax>;
        if (formGroup) {
          this.updatePaymentSalesTaxFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentSalesTaxFormGroup(item));
        }
      });
      if (formArray.length > taxes.length) {
        this.clearArray(formArray, taxes.length);
      }
    } else if (taxes.length) {
      const array = this.createPaymentSalesTaxFormArray(taxes);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentSalesTaxFormGroup(
    formGroup: FormGroup<IPaymentSalesTax>,
    paymentSalesTax: IPaymentSalesTax
  ) {
    formGroup.patchValue(paymentSalesTax, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<any>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }
}
