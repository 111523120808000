<div class="alert alert-danger alert-dismissible error-box" *ngIf="validationErrorResponse">
    <p *ngIf="validationErrorResponse.detail">{{ validationErrorResponse.detail }}</p>
    <ng-container *ngTemplateOutlet="listTpl; context:{ $implicit: validationErrorResponse.validationFailures }"></ng-container>
</div>


<ng-template #listTpl let-validationFailures>
    <ul>
        <ng-container *ngFor="let validationFailure of validationFailures">
            <ng-container *ngTemplateOutlet="detailTpl; context:{ $implicit: validationFailure }"></ng-container>
        </ng-container>
    </ul>
</ng-template>

<ng-template #detailTpl let-validationFailure>    
    <ng-container [ngTemplateOutlet]="validationFailure.ruleText ? ruleTextTemplate : validationFailure.reasons?.length > 0 ? reasonsTemplate : ruleNameTemplate">
        <ng-template #ruleNameTemplate>
            <li>{{validationFailure.ruleName}}</li>
        </ng-template>
        <ng-template #ruleTextTemplate>
            <li>{{validationFailure.ruleText}}</li>
        </ng-template>
        <ng-template #reasonsTemplate>
            <li *ngFor="let reason of validationFailure.reasons">{{reason}}</li>
        </ng-template>
        </ng-container>
</ng-template>