import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../common/services/auth.service';
import { PhxConstants } from '../../common';
import { BaseComponentOnDestroy } from '../../common/epics/base-component-on-destroy';
import { AppInitService } from 'src/app/app-init.service';
import HTTPResponseStatus = PhxConstants.HTTPResponseStatus;
import { MODULE_ROUTES } from '../../common/constants/module-routes.const';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent extends BaseComponentOnDestroy implements OnInit, AfterViewInit {
  form: UntypedFormGroup;
  isLoginFailed: boolean;
  userIpRestrictionError: string = null;
  askPassword = false;
  readonly loginErrorMessage = 'Incorrect password. Please try again.';
  readonly accountErrorMessage = 'Account does not exist. Please check your email address and try again.';
  errorMessage: string;
  @ViewChild('emailInputField') emailInputField: ElementRef<HTMLInputElement>;
  @ViewChild('passwordInputField') passwordInputField: ElementRef<HTMLInputElement>;

  allowMigratedUser = false;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private appInitService: AppInitService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.email
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(6)
      ]]
    });

    this.route.queryParams.subscribe(params => {
      this.allowMigratedUser = params.allowMigratedUser;
    });
  }

  ngAfterViewInit(): void {
    if (!this.askPassword) {
      this.focusOnEmailField();
    }
  }

  checkUserEmail() {
    const email: string = (this.form.get('email').value as string).trim();
    if (email) {
      this.authService
        .validateUserEmail(email, this.allowMigratedUser)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: () => {
            this.askPassword = true;
            this.isLoginFailed = false;
            this.userIpRestrictionError = null;
            this.form.get('email').disable();
            this.cdr.detectChanges();
            this.focusOnPasswordField();
          },
          error: err => {
            this.handleLoginError(err, false);
          }
        });
    }
  }

  login() {
    const email = (this.form.get('email').value as string).trim();
    const password = (this.form.get('password').value as string).trim();
    if (email && password) {
      this.authService.clearCookiesAndCachedItems();
      this.isLoginFailed = false;
      this.userIpRestrictionError = null;

      this.authService.login(email, password)
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: () => {
            this.appInitService.initApp().then(() => {
              this.authService.redirectAfterLogin();
            }, err => {
              this.userIpRestrictionError = err?.status === HTTPResponseStatus.Forbidden && err.error?.errorMessage || null;
              throw err;
            });
          },
          error: (err) => {
            this.handleLoginError(err);
          }
        });
    }
  }

  forgotAccount() {
    const email = (this.form.get('email').value as string).trim();
    this.router.navigate(['accountforgot', { email }]);
  }

  backToEmailField() {
    this.askPassword = false;
    this.isLoginFailed = false;
    this.userIpRestrictionError = null;
    this.form.get('email').enable();
    this.form.get('password').reset('');
    this.cdr.detectChanges();
    this.focusOnEmailField();
  }

  private handleLoginError(err, loginError = true) {
    const httpError: HttpErrorResponse = err && err instanceof HttpErrorResponse ? err : null;
    const error = httpError?.error ? httpError.error.error : null;

    if (error === 'internal_user_login_not_allowed') {
      const email: string = (this.form.get('email').value as string).trim();
      this.router.navigate([MODULE_ROUTES.SSO, { email }]);
    } else if (error === 'portal_user_login_not_allowed') {
      const redirectURL = err.error.redirectURL || '';
      this.router.navigate([redirectURL]);
    } else {
      this.errorMessage = loginError ? this.loginErrorMessage : this.accountErrorMessage;
      this.isLoginFailed = true;
      this.userIpRestrictionError = null;
      this.cdr.detectChanges();
    }
  }

  private focusOnEmailField() {
    this.focusOnInputField();
  }

  private focusOnPasswordField() {
    this.focusOnInputField(false);
  }

  private focusOnInputField(emailField = true) {
    const field = emailField ? this.emailInputField : this.passwordInputField;
    const inputElement = field?.nativeElement as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
    }
  }
}
