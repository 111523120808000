import { map, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { CodeValueGroups, PhxDataTableConfiguration, RowHighlightingConfig, PhxDataTableStateSavingMode, PhxDataTableColumn, PhxDataTableSelectionMode } from '../../model';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { CommonService, WindowRefService, PhxLocalizationService, PhxConstants } from '../../index';
import { CodeValueService } from '../../services/code-value.service';
import { OrganizationApiService } from '../../../organization/services/organization.api.service';
import { Router } from '@angular/router';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { 
  IRecordOfEmploymentGroupValidationResult, 
  IRecordOfEmploymentIndividualValidationResult, 
  IRoeFeatureConfiguration, 
  RecordOfEmploymentValidationErrorMessages 
} from '../../../contact/models/roe-validation-result.interface';
import { PayrollService } from 'src/app/payroll/payroll.service';
import { IRoeRequest } from 'src/app/payroll/model/roe-request.interface';
import { PhxDataTableComponent } from 'src/app/common/components/phx-data-table/phx-data-table.component';
import { DialogService } from '../../services/dialog.service';
import { DialogRef } from '@angular/cdk/dialog';
import { IRecordOfEmploymentReport, IRecordOfEmploymentTransactionInfo, IRecordOfEmploymentField, InsurableEarningPeriod } from '../../../contact/models/roe-report.interface';
import { ConfigurationService } from '../../../configuration/service/configuration.service';
import { formatDate } from '@angular/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { PeopleApiService } from 'src/app/contact/services/people.api.service';
import { WorkOrderDataService } from '../../../workorder-2/services';
import { IRoeValidationFailure } from '../../model/roe-validation-failure.interface';

declare let oreq: any;

@Component({
  selector: 'app-associated-workorders',
  templateUrl: './associated-workorders.component.html',
  styleUrls: ['./associated-workorders.component.less'],
  standalone: false
})

export class AssociatedWorkordersComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {
  @Input() contactId: number;
  @Input() contactName: string;
  @Input() organizationId: number;
  @Output() errors = new EventEmitter<IRoeValidationFailure[]>();
  dataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    rowHighlightingConfig: new RowHighlightingConfig(),
  });
  dataSourceUrl: string;
  dataGridComponentName = 'workorderSearch';
  internalOrganizations: any[] = [];
  oDataParams: any;
  columns: Array<PhxDataTableColumn>;
  roeAllowedUserProfileTypes = [PhxConstants.UserProfileType.WorkerTemp, PhxConstants.UserProfileType.WorkerCanadianSp];
  public anyWorkOrdersSelected = false;
  @ViewChild('workOrdersGrid') workOrdersGrid: PhxDataTableComponent;
  @ViewChild('roeReportTemplate') roeReportTemplate: TemplateRef<any>;

  modalTitle: string = 'Record of Employment Report';
  roeFields: IRecordOfEmploymentField[] = [];
  detailedRows: IRecordOfEmploymentTransactionInfo[] = [];
  softValidations: IRoeValidationFailure[] = [];

  public dialogRef: DialogRef<void>;
  showRoeModal = false;
  isRoeGenerationEnabled = false;
  private issuerFullName: string = '';
  private issuerPhoneNumber: string = '';
  private contactFirstName: string = '';
  private contactLastName: string = '';
  private contactPhoneNumber: string = '';

  constructor(
    public commonService: CommonService,
    private codeValueService: CodeValueService,
    private winRef: WindowRefService,
    private localizationService: PhxLocalizationService,
    private orgService: OrganizationApiService,
    private payrollService: PayrollService,
    private router: Router,
    private dialogService: DialogService,
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private peopleApiService: PeopleApiService,
    private workOrderDataService: WorkOrderDataService
  ) {
    super();
    this.dataTableConfiguration.stateSavingMode = PhxDataTableStateSavingMode.None;
  }

  ngOnInit() {
    this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.RoeGeneration])
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(featureFlagState => {
        this.isRoeGenerationEnabled = featureFlagState[PhxConstants.FeatureFlags.RoeGeneration];
        this.dataTableConfiguration.selectionMode = this.isRoeGenerationEnabled ? PhxDataTableSelectionMode.Multiple : PhxDataTableSelectionMode.None;
      });

    if (this.organizationId) {
      this.dataSourceUrl = 'assignment/assignmentsByOrganizationId/' + this.organizationId;
    } else {
      this.dataSourceUrl = 'assignment/assignmentsByContactId/' + this.contactId;
      if (this.isRoeGenerationEnabled && this.contactName) {
        this.modalTitle = `Record of Employment Report - ${this.contactName}`;
      }
    }

    this.loadDataTable();
    if (this.isRoeGenerationEnabled) {
      this.authService.getCurrentProfile()
        .pipe(
          takeUntil(this.isDestroyed$),
          switchMap(profile => {
            return this.peopleApiService.getPeopleObject(profile.Id);
          })
        )
        .subscribe({
          next: (peopleObject) => {
            this.issuerFullName = `${peopleObject.Contact.LastName}, ${peopleObject.Contact.FirstName}`;
            const userPhone = peopleObject.UserProfile.UserProfilePhones?.find(p => p.Phone);
            if (userPhone) {
              this.issuerPhoneNumber = this.formatPhoneNumber(userPhone.PhoneCode, userPhone.Phone, userPhone.Extension);
            }
          }
        });
    }
  }

  loadDataTable() {
    this.oDataParams = oreq
      .request()
      .withSelect([
        'WorkOrderFullNumber',
        'AssignmentId',
        'WorkOrderId',
        'WorkOrderNumber',
        'StartDate',
        'EndDate',
        'WorkOrderStatus',
        'WorkOrderVersionId',
        'PaymentPrimaryRateSumPerRateUnit',
        'BillingPrimaryRateSumPerRateUnit',
        'WorkerName',
        'ClientName',
        'OrganizationIdInternal',
        'InternalCompanyDisplayName',
        'UserProfileIdWorker',
        'WorkerProfileType',
        'ManagerName',
        'TimeSheetApprover',
        'BranchId',
        'IsChangeInProgress',
        'WorkOrderLineOfBusiness',
        'JobOwnerName',
        'FirstRecruiterName',
        'ClientOrganizationId',
        'ContactId',
        'UserProfileTypeId',
        'IsTest'
      ])
      .url();
    this.orgService.getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole()
      .pipe(
        takeUntil(this.isDestroyed$),
        map((data) => {
          return data.Items.map((item) => {
            return {
              text: item.DisplayName,
              value: item.Id
            };
          });
        }))
      .subscribe(res => {
        this.internalOrganizations = res;
        this.columns = this.buildColumns();
      });
  }

  buildColumns(): Array<PhxDataTableColumn> {
    return [
      new PhxDataTableColumn({
        dataField: 'WorkOrderFullNumber',
        caption: $localize`:@@common.number:Number`,
        calculateSortValue: 'AssignmentId'
      }),
      new PhxDataTableColumn({
        dataField: 'OrganizationIdInternal',
        caption: $localize`:@@common.internalCompany:Internal Company`,
        lookup: {
          dataSource: this.getInternalOrgLookup(),
          valueExpr: 'value',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'WorkerProfileType',
        caption: $localize`:@@common.profileType:Profile Type`,
        lookup: {
          dataSource: this.getUserProfileTypeLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'ClientName',
        caption: $localize`:@@common.clientName:Client Name`
      }),
      new PhxDataTableColumn({
        dataField: 'StartDate',
        caption: $localize`:@@common.startDate:Start Date`,
        dataType: 'date'
      }),
      new PhxDataTableColumn({
        dataField: 'EndDate',
        caption: $localize`:@@common.endDate:End Date`,
        dataType: 'date'
      }),
      new PhxDataTableColumn({
        dataField: 'BillingPrimaryRateSumPerRateUnit',
        caption: $localize`:@@common.billRate:Bill Rate`
      }),
      new PhxDataTableColumn({
        dataField: 'PaymentPrimaryRateSumPerRateUnit',
        caption: $localize`:@@common.payRate:Pay Rate`
      }),
      new PhxDataTableColumn({
        dataField: 'WorkOrderStatus',
        caption: $localize`:@@common.status:Status`,
        lookup: {
          dataSource: this.getWorkorderStatusLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'BranchId',
        caption: $localize`:@@common.branch:Branch`,
        lookup: {
          dataSource: this.getBranchLookup(),
          valueExpr: 'id',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'IsChangeInProgress',
        caption: $localize`:@@common.changeInProgress:Change In Progress`,
        dataType: 'boolean',
        lookup: {
          dataSource: this.getChangeInProgressLookup(),
          valueExpr: 'id',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'TimeSheetApprover',
        caption: $localize`:@@common.timesheetApprover:Timesheet Approver`
      }),
      new PhxDataTableColumn({
        dataField: 'ManagerName',
        caption: $localize`:@@common.clientManager:Client Manager`
      }),
      new PhxDataTableColumn({
        dataField: 'WorkOrderLineOfBusiness',
        caption: $localize`:@@common.lineOfBusiness:Line of Business`,
        lookup: {
          dataSource: this.getWorkOrderLineOfBusinessLookup(),
          valueExpr: 'text',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'JobOwnerName',
        caption: $localize`:@@common.jobOwner:Job Owner`
      }),
      new PhxDataTableColumn({
        dataField: 'FirstRecruiterName',
        caption: $localize`:@@common.recruiter:Recruiter`
      }),
      new PhxDataTableColumn({
        dataField: 'IsTest',
        caption: this.localizationService.translate('common.phxDataTable.implementationHeader'),
        dataType: 'boolean',
        lookup: {
          dataSource: PhxDataTableColumn.isTest.lookupDataSource(this.localizationService),
          valueExpr: 'value',
          displayExpr: 'text'
        }
      })
    ];
  }

  onRowClick(event: any) {
    if (event?.data) {
      this.viewWorkOrderDetails(event.data.AssignmentId, event.data.WorkOrderId, event.data.WorkOrderVersionId);
    }
  }

  viewWorkOrderDetails(assignmentId: number, workOrderId: number, workOrderVersionId: number) {
    this.router.navigate(['next', 'workorder', assignmentId, workOrderId, workOrderVersionId, 'core']);
  }

  getWorkorderStatusLookup() {
    return this.codeValueService.getCodeValues(CodeValueGroups.WorkOrderStatus, true);
  }

  getWorkOrderLineOfBusinessLookup() {
    return this.codeValueService.getCodeValues(CodeValueGroups.LineOfBusiness, true).filter(item => item.id !== PhxConstants.LineOfBusiness.Expense);
  }

  getChangeInProgressLookup() {
    return [{ id: false, text: $localize`:@@common.message.no:No` }, { id: true, text: $localize`:@@common.message.yes:Yes` }];
  }

  getUserProfileTypeLookup() {
    return this.codeValueService.getCodeValues('usr.CodeProfileType', true);
  }

  getBranchLookup() {
    return this.codeValueService.getCodeValuesSortByCode('workorder.CodeInternalOrganizationDefinition1', true);
  }

  getInternalOrgLookup() {
    return this.internalOrganizations.sort(this.commonService.compareFnToSortObjects('text'));
  }

  onContextMenuPreparing(event: any) {
    if (event?.row?.rowType === 'data' && event.row.data.AssignmentId && event.row.data.WorkOrderId && event.row.data.WorkOrderVersionId) {
      event.items = [{
        text: $localize`:@@phx.associatedWorkorders.openWoInNewTab:Open work order in new tab`,
        onItemClick: () => {
          this.winRef.openUrl(`/next/workorder/${event.row.data.AssignmentId}/${event.row.data.WorkOrderId}/${event.row.data.WorkOrderVersionId}/core`);
        }
      }];
    }
  }

  onCellPrepared(event: any) {
    if (event.rowType === 'data' && event.column.command === 'select') {
      const userProfileTypeId = event.data.UserProfileTypeId;

      if (!this.roeAllowedUserProfileTypes.includes(userProfileTypeId)) {
        event.cellElement.innerHTML = '';
      }
    }
  }

  onSelectionChanged(event: any) {
    const selectedRows = event.selectedRowsData;
    const validSelections = selectedRows.filter((row: any) =>
      this.roeAllowedUserProfileTypes.includes(row.UserProfileTypeId)
      && (row.WorkOrderStatus === 'Complete' || row.WorkOrderStatus === 'Terminated')
    );

    if (validSelections.length !== selectedRows.length) {
      event.component.deselectAll();
      event.component.selectRows(validSelections);
    }

    this.anyWorkOrdersSelected = validSelections.length > 0;
  }

  generateRoeReport(): void {
    const selectedRows = this.workOrdersGrid.getSelectedRowsData();

    const workOrders: IRoeRequest[] = selectedRows.map(row => ({
      workOrderId: row.WorkOrderId,
      assignmentId: row.AssignmentId,
      userProfileIdWorker: row.UserProfileIdWorker,
      workOrderFullNumber: row.WorkOrderFullNumber
    }));

    this.workOrderDataService
      .getFeatureConfiguration<IRoeFeatureConfiguration>('Configuration', 'RecordOfEmploymentConfiguration')
      .pipe(
        takeUntil(this.isDestroyed$),
        switchMap(featureConfig => forkJoin({
          featureConfiguration: of(featureConfig),
          roeValidation: this.payrollService.validateRoeGeneration(workOrders)
        }))
      )
      .subscribe({
        next: ({ featureConfiguration, roeValidation }) => {
          const errorResponses: IRoeValidationFailure[] = [];

          if (featureConfiguration) {
            if (featureConfiguration?.skipRoeChecklistValidation) {
              roeValidation.individualValidationResults.forEach(individualResult => {
                individualResult.hasRequestRoeInOffboardingChecklist = true;
              });
            }
          }

          roeValidation.individualValidationResults
            .filter(val => !val.isSuccess)
            .forEach(val => {
              const [errors, warnings] = this.processIndividualValidations(val);
              if (errors) {
                errorResponses.push(errors);
              }
              if (warnings) {
                this.softValidations.push(warnings);
              }
            });

          if (!Object.values(roeValidation.groupValidationResults).every(Boolean)) {
            errorResponses.push(this.processGroupValidations(roeValidation.groupValidationResults));
          }

          if (errorResponses.length === 0) {
            this.payrollService.getRoeReport(workOrders.map(wo => wo.assignmentId)).pipe(
              switchMap((report: IRecordOfEmploymentReport) => {
                return forkJoin({
                  reportData: of(report),
                  contactData: this.loadContactInformation(report.assignedToContactId),
                  issuerInfo: this.loadRoeIssuerInformation()
                });
              }),
              catchError(error => {
                this.commonService.logError('Error generating ROE report');
                return throwError(() => error);
              })
            ).subscribe(({ reportData }) => {
              this.displayRoeReport(reportData);
            });
          }
          else {
            this.softValidations = [];
          }

          this.errors.emit(errorResponses.length > 0 ? errorResponses : null);
        },
        error: () => {
          this.commonService.logError('Error generating ROE report');
        }
      })
  }

  private displayRoeReport(report: IRecordOfEmploymentReport): void {
    this.roeFields = this.mapRoeReportToFields(report as IRecordOfEmploymentReport);

    this.detailedRows = report.roeDetails
      .filter(row => row.workOrderFullNumber !== null || row.isMissingPeriod)
      .map(row => ({
        ...row,
        transactionType: this.formatTransactionType(row.transactionType),
        transactionFromDate: row.transactionFromDate ? formatDate(row.transactionFromDate, 'MMM d, y', 'en-US') : '',
        transactionToDate: row.transactionToDate ? formatDate(row.transactionToDate, 'MMM d, y', 'en-US') : ''
      }));

    this.dialogRef = this.dialogService.showInPopup(this.roeReportTemplate, {
      data: {
        modalTitle: this.modalTitle,
        roeFields: this.roeFields,
        detailedRows: this.detailedRows,
        softValidations: this.softValidations
      },
      hasBackdrop: true,
      panelClass: ['modal-xl'],
      disableClose: false
    });

    this.dialogRef.closed.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => {
      this.softValidations = [];
      this.workOrdersGrid.clearSelection();
    });
  }

  private processIndividualValidations(
    validationResult: IRecordOfEmploymentIndividualValidationResult
  ): [IRoeValidationFailure, IRoeValidationFailure] {
    const hardChecks = Object.entries(validationResult)
      .filter(([key]) => 
        key !== 'isSuccess' &&
        key !== 'hasRequestRoeInOffboardingChecklist' &&
        key !== 'hasTimesheets' &&
        key !== 'areAllTransactionsInPaidOrSuppressedStatus' &&
        key !== 'areAllVacationPaymentsComplete'
      )
      .filter(([, value]) => !value);

    const softChecks = Object.entries(validationResult)
      .filter(([key]) => 
        key !== 'isSuccess' && (
          key === 'hasRequestRoeInOffboardingChecklist' ||
          key === 'hasTimesheets' ||
          key === 'areAllTransactionsInPaidOrSuppressedStatus' ||
          key === 'areAllVacationPaymentsComplete'
        )
      )
      .filter(([, value]) => !value);

    const errors = hardChecks.length > 0 ? this.createErrorResponseFromValidation(
        hardChecks,
        `One or more validations failed for work order ${validationResult.workOrderFullNumber}.`,
        validationResult.workOrderFullNumber.split('.')[0]
    ) : null;

    const warnings = softChecks.length > 0 ? this.createErrorResponseFromValidation(
        softChecks,
        `Warning: Work order ${validationResult.workOrderFullNumber} has the following issues.`,
        validationResult.workOrderFullNumber.split('.')[0]
    ) : null;

    return [errors, warnings];
  }

  private processGroupValidations(
    groupValidations: IRecordOfEmploymentGroupValidationResult
  ): IRoeValidationFailure {
    const validationFailures = [];

    if (!groupValidations.areAllWorkOrdersSequential) {
      validationFailures.push({
        ruleText: RecordOfEmploymentValidationErrorMessages.areAllWorkOrdersSequential
      });
    }

    if (!groupValidations.areAllTimesheetCyclesConsistent) {
      validationFailures.push({
        ruleText: RecordOfEmploymentValidationErrorMessages.areAllTimesheetCyclesConsistent.replace(
          '${timesheetCycleAssignmentIds}',
          this.formatAssignmentList(groupValidations.misalignedTimesheetCycleAssignments || [])
        )
      });
    }

    if (!groupValidations.areAllPayCyclesConsistent) {
      validationFailures.push({
        ruleText: RecordOfEmploymentValidationErrorMessages.areAllPayCyclesConsistent.replace(
          '${payCycleAssignmentIds}',
          this.formatAssignmentList(groupValidations.misalignedPayCycleAssignments || [])
        )
      });
    }

    return {
      detail: 'One or more validations failed for the selected work orders.',
      validationFailures
    };
  }

  private createErrorResponseFromValidation(
    validationChecks: [string, boolean][],
    detail: string,
    assignmentString: string,
  ): IRoeValidationFailure {
    return {
      detail,
      validationFailures: validationChecks
        .filter(([, value]) => !value)
        .map(([key]) => ({
          ruleText: RecordOfEmploymentValidationErrorMessages[key].replace('${assignment}', assignmentString)
        }))
    };
  }

  onModalClose(): void {
    this.showRoeModal = false;
  }

  private mapInsurableEarningPeriod(rawPeriod: any): InsurableEarningPeriod {
    return {
      periodNumber: rawPeriod.item1,
      value: rawPeriod.item2
    };
  }

  private mapRoeReportToFields(report: IRecordOfEmploymentReport): IRecordOfEmploymentField[] {
    const formatValue = (value: any): string =>
      value === null || value === undefined || value === '0' ? '' : value;

    const formatCurrency = (amount: number | null): string => {
      if (amount === null || amount === undefined) {
        return '$0.00';
      }
      return `$${amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    const formatDateWithMinCheck = (date: Date | null): string => {
      if (!date) {
        return 'Unknown';
      }
      const formattedDate = formatDate(date, 'MMM d, y', 'en-US');
      return formattedDate === 'Jan 1, 1' ? 'Unknown' : formattedDate;
    };

    const formatCountry = (countryId: number): string => {
      if (!countryId) {
        return '';
      }
      return this.codeValueService.getCodeValue(countryId, 'geo.CodeCountry').text;
    };

    const formatProvince = (subdivisionId: number): string => {
      if (!subdivisionId) {
        return '';
      }

      return this.codeValueService.getCodeValue(subdivisionId, 'geo.CodeSubdivision').text;
    };

    return [
      {
        label: 'Block 1 - Issuer Information',
        subFields: [
          { label: 'Name of Issuer', value: this.issuerFullName },
          { label: 'Issuer\'s Telephone Number', value: formatValue(this.issuerPhoneNumber) }
        ]
      },
      { label: 'Block 3 - Employer\'s Payroll Reference Number', value: formatValue(report.employerPayrollReferenceNumber) },
      { label: 'Block 4 - Employer\'s Address', value: formatValue(report.employerAddress) },
      {
        label: 'Block 9 - Employee\'s Name and Address',
        subFields: [
          { label: 'First Name', value: formatValue(report.employeeFirstName) },
          { label: 'Last Name', value: formatValue(report.employeeLastName) },
          { label: 'Address Line 1 - Number and Street Name', value: formatValue(report.employeeAddressLine1) },
          { label: 'Address Line 2 - Place, City, or Municipality', value: formatValue(report.employeeAddressLine2PlaceCityOrMunicipality) },
          { label: 'Address Line 3 - Province, Country', value: [formatProvince(report.employeeAddressLine3Province), formatCountry(report.employeeAddressLine3Country)].filter(Boolean).join(', ') },
          { label: 'Postal Code', value: formatValue(report.employeePostalCode) }
        ]
      },
      { label: 'Block 8 - Social Insurance Number', value: formatValue(report.socialInsuranceNumber) },
      { label: 'Block 13 - Employee\'s Occupation', value: this.formatOccupation(report.employeeOccupation) },
      { label: 'Block 6 - Pay Period Type', value: formatValue(report.payPeriodType) },
      { label: 'Block 10 - First Day Worked', value: formatDateWithMinCheck(report.firstDayWorked) },
      { label: 'Block 11 - Last Day For Which Paid', value: formatDateWithMinCheck(report.lastDayForWhichPaid) },
      { label: 'Block 12 - Final Pay Period Ending Date', value: formatDateWithMinCheck(report.finalPayPeriodEndingDate) },
      { label: 'Block 14 - Expected Date of Recall', value: formatDateWithMinCheck(report.expectedDateOfRecall) },
      { label: 'Block 15A - Total Insurable Hours', value: formatValue(report.totalInsurableHours?.toString()) },
      {
        label: 'Block 15C - Insurable Earnings by Pay Period',
        subFields: (report?.insurableEarningsByPayPeriod || [])
          .map(this.mapInsurableEarningPeriod)
          .map(period => ({
            label: `Period ${period.periodNumber}`,
            value: formatCurrency(period.value)
          }))
      },
      { label: 'Block 15B - Total Insurable Earnings', value: formatCurrency(report.totalInsurableEarnings) },
      { label: 'Block 16 - Reason for Issuing this ROE', value: formatValue(report.reasonForIssuingRoe) },
      {
        label: 'Block 16 - Contact Name and Phone Number',
        subFields: [
          { label: 'First Name', value: formatValue(this.contactFirstName) },
          { label: 'Last Name', value: formatValue(this.contactLastName) },
          { label: 'Phone Number', value: formatValue(this.contactPhoneNumber) }
        ]
      },
      { label: 'Block 17A - Vacation Pay', value: report.vacationPayAmount !== null ? formatCurrency(report.vacationPayAmount) : '' },
      { label: 'Block 20 - Issuer\'s Communication', value: formatValue(report.issuerCommunication) },
      {
        label: 'Block 22 - Issuer Name and Declaration',
        subFields: [
          { label: 'Issuer\'s Name', value: this.issuerFullName }
        ]
      }
    ];
  }

  private getPreferredPhoneNumber(phones: any[]): any {
    if (!phones?.length) {
      return null;
    }

    const priorityOrder = [
      PhxConstants.ProfilePhoneType.CL,  // Cell
      PhxConstants.ProfilePhoneType.HM,  // Home
      PhxConstants.ProfilePhoneType.BZ   // Business
    ];

    for (const phoneType of priorityOrder) {
      const phone = phones.find(p => p.PhoneCode === phoneType && p.Phone);
      if (phone) {
        return phone;
      }
    }

    // If no preferred phone types found, return the first available phone
    return phones.find(p => p.Phone);
  }

  private loadContactInformation(contactId: number): Observable<void> {
    return this.peopleApiService.getVersions(contactId).pipe(
      map(versions => versions.find(v => v.StatusCode === 'Active')?.VersionNumber),
      switchMap(activeVersion => {
        if (!activeVersion) {
          return of({
            profiles: [],
            peopleObject: {
              Contact: {
                FirstName: '',
                LastName: ''
              }
            }
          });
        }
        return forkJoin({
          profiles: this.peopleApiService.getContactProfiles(contactId, activeVersion),
          peopleObject: this.peopleApiService.getPeopleObject(contactId, activeVersion)
        });
      }),
      map(({ profiles, peopleObject }) => {
        // Prioritize internal profile for phone number
        const internalProfile = profiles.find(profile =>
          profile.ProfileTypeCode === PhxConstants.CodeUserProfileType.Internal
        );

        let phoneFound = false;
        if (internalProfile?.UserProfilePhones?.length) {
          const preferredPhone = this.getPreferredPhoneNumber(internalProfile.UserProfilePhones);
          if (preferredPhone) {
            this.contactPhoneNumber = this.formatPhoneNumber(
              preferredPhone.PhoneCode,
              preferredPhone.Phone,
              preferredPhone.Extension
            );
            phoneFound = true;
          }
        }

        // If no phone found in internal profile, check other profiles
        if (!phoneFound) {
          for (const profile of profiles) {
            if (profile.UserProfilePhones?.length) {
              const preferredPhone = this.getPreferredPhoneNumber(profile.UserProfilePhones);
              if (preferredPhone) {
                this.contactPhoneNumber = this.formatPhoneNumber(
                  preferredPhone.PhoneCode,
                  preferredPhone.Phone,
                  preferredPhone.Extension
                );
                break;
              }
            }
          }
        }

        if (peopleObject?.Contact) {
          this.contactFirstName = peopleObject.Contact.FirstName;
          this.contactLastName = peopleObject.Contact.LastName;
        }
      })
    );
  }

  private loadRoeIssuerInformation(): Observable<void> {
    return this.authService.getCurrentProfile().pipe(
      takeUntil(this.isDestroyed$),
      switchMap(profile => {
        return this.peopleApiService.getPeopleObject(profile.Id);
      }),
      map(peopleObject => {
        this.issuerFullName = `${peopleObject.Contact.LastName}, ${peopleObject.Contact.FirstName}`;

        if (peopleObject.UserProfile.UserProfilePhones?.length) {
          const preferredPhone = this.getPreferredPhoneNumber(peopleObject.UserProfile.UserProfilePhones);
          if (preferredPhone) {
            this.issuerPhoneNumber = this.formatPhoneNumber(
              preferredPhone.PhoneCode,
              preferredPhone.Phone,
              preferredPhone.Extension
            );
          }
        }
      })
    );
  }

  private formatOccupation(occupation: string): string {
    if (!occupation) {
      return 'Unknown';
    }

    const positionTitle = this.codeValueService.getCodeValueByCode(
      occupation,
      CodeValueGroups.PositionTitle
    );

    const functionTitle = this.codeValueService.getCodeValueByCode(
      occupation,
      CodeValueGroups.JobFunction
    );

    return positionTitle?.text || functionTitle?.text || occupation;
  }

  private formatTransactionType(transactionType: string): string {
    if (transactionType === 'Filler') {
      return transactionType;
    }

    const codeValue = this.codeValueService.getCodeValueByCode(
      transactionType,
      CodeValueGroups.TransactionType
    );

    return codeValue?.description || transactionType;
  }

  private formatPhoneNumber(phoneCode: string, phone: string, extension: string): string {
    return [
      phoneCode,
      phone,
      extension ? `ext.${extension}` : null
    ]
      .filter(Boolean)
      .join(' ')
      .trim();
  }

  private formatAssignmentList(assignments: number[]): string {
    if (assignments.length === 0) { return '' }
    if (assignments.length === 1) { return assignments[0].toString() }
    if (assignments.length === 2) { return `${assignments[0]} and ${assignments[1]}` }
    
    const lastAssignment = assignments[assignments.length - 1];
    const otherAssignments = assignments.slice(0, -1);
    return `${otherAssignments.join(', ')}, and ${lastAssignment}`;
  }
}
