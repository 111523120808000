import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';

import { EntityList, FormServiceHelper, IFormService, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkOrder, IWorkplaceSafetyInsurance } from '../../models';
import { WorkorderService } from '../workorder.service';

@Injectable()
export class WorkplaceSafetyInsuranceFormService implements IFormService {

  formGroup: FormGroup<IWorkplaceSafetyInsurance>;
  checkEarningvalidate = false; // Why is this done here (public, set outside)? Isn't there a better way to calculate this in this form servcie?
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private workOrderService: WorkorderService    
  ) {
  }

  get workerCompensationIdFormControl(): FormControl<number> {
    return this.formGroup.get('WorkerCompensationId') as FormControl<number>;
  }

  get wCBIsAppliedFormControl(): FormControl<boolean> {
    return this.formGroup.get('WCBIsApplied') as FormControl<boolean>;
  }

  get wCBIsAppliedValue(): boolean {
    return this.wCBIsAppliedFormControl.value;
  }

  get workerCompensationIdChanges$() {
    return this.workerCompensationIdFormControl.valueChanges;
  }

  mapWorkOrderToFormData(workorder: IWorkOrder): IWorkplaceSafetyInsurance {
    return {
      WorkerCompensationId: workorder.WorkOrderVersion.WorkerCompensationId,
      WCBIsApplied: workorder.WorkOrderVersion.WCBIsApplied,
      WCBPositionTitle: workorder.WorkOrderVersion.WCBPositionTitle
    };
  }

  updateValidatorsAndDisabled() {
    FormServiceHelper.setValidators(this.formGroup, this.onGetValidators());
  }

  onGetValidators(): ValidatorsConfig<IWorkplaceSafetyInsurance> {
    return {
      WCBPositionTitle: [Validators.maxLength(128)],
      WorkerCompensationId: this.checkEarningvalidate ? [Validators.required] : null,
      WCBIsApplied: this.checkEarningvalidate ? [Validators.required] : null
    };
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<IWorkplaceSafetyInsurance> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IWorkplaceSafetyInsurance>(formValue);

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.workerCompensationIdChanges$
      .pipe(takeUntil(this.isRootComponentDestroyed$))
      .subscribe(() => {
        this.wCBIsAppliedFormControl.patchValue(null, { emitEvent: false });
        this.updateValidatorsAndDisabled();
      });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const formGroupSafetyInsuranceValues: IWorkplaceSafetyInsurance = this.formGroup.value;
    workOrder.WorkOrderVersion.WorkerCompensationId = formGroupSafetyInsuranceValues.WorkerCompensationId;
    workOrder.WorkOrderVersion.WCBIsApplied = formGroupSafetyInsuranceValues.WCBIsApplied;
    workOrder.WorkOrderVersion.WCBPositionTitle = formGroupSafetyInsuranceValues.WCBPositionTitle;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(formValue, { emitEvent: false });

    this.updateValidatorsAndDisabled();

  }

  updateWCBIsApplied(value: boolean, emitEvent = false) {
    this.wCBIsAppliedFormControl.patchValue(value, { emitEvent });
  }

  updateWorkerCompensationId(value: number, emitEvent = false) {
    this.workerCompensationIdFormControl.patchValue(value, { emitEvent });
  }

  getValidatorForWorkerCompensation(workOrder: IWorkOrder): Promise<boolean> {
    return new Promise(resolve => {
      const subdivisionId = workOrder.WorkOrderVersion.WorkerLocationId;
      if (!subdivisionId || !workOrder.OrganizationIdInternal) {resolve(false);}
      this.workOrderService.getWCBCodesBySubdivisionId(subdivisionId, workOrder.OrganizationIdInternal)
        .subscribe((response: EntityList<any> | null) => {
          resolve(response?.Items?.length > 0);
        });
    });
  }

  setWorkerCompensationByWorkerLocation(subdivisionId: number, organizationIdInternal: number): void {
    this.workOrderService.getWCBCodesBySubdivisionId(subdivisionId, organizationIdInternal).subscribe((response: any) => {
      if (response?.Items) {
        this.updateWorkerCompensationId(response.Items[0].WorkerCompensationId);
        this.updateWCBIsApplied(true);
      }
    });
  }

}
