import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { IIncentiveCompensation, IIncentiveCompensationPaymentInfo, IPaymentInvoice } from '../../models/work-order-form.interface';
import { PaymentInfoFormService } from './payment-info-form.service';

@Injectable()
export class PartyIncentiveCompensationFormService implements IFormService {

  formGroup: FormGroup<IIncentiveCompensation>;
  private isRootComponentDestroyed$: Subject<boolean>;

  get isEligibleForCommissionFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsEligibleForCommission') as FormControl<boolean>;
  }

  get isThirdPartyImportFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsThirdPartyImport') as FormControl<boolean>;
  }

  get isEligibleForCommissionChange$() {
    return this.isEligibleForCommissionFormControl.valueChanges;
  }

  get isThirdPartyImportChange$() {
    return this.isThirdPartyImportFormControl.valueChanges;
  }

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService
  ) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const incentiveCompensation: IIncentiveCompensation = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IIncentiveCompensation>({
      IsEligibleForCommission: incentiveCompensation.IsEligibleForCommission,
      IsEligibleForAllowance: incentiveCompensation.IsEligibleForAllowance,
      IsThirdPartyImport: incentiveCompensation.IsThirdPartyImport,
      CommissionThirdPartyWorkerReference: incentiveCompensation.CommissionThirdPartyWorkerReference,
      IsCommissionVacation: incentiveCompensation.IsCommissionVacation,
      PaymentParties: this.fb.array<IIncentiveCompensationPaymentInfo>(incentiveCompensation.PaymentParties.map(paymentParty => {
        return this.fb.group<IIncentiveCompensationPaymentInfo>({
          PaymentInvoice: this.paymentInfoFormService.createPaymentInvoiceFormGroup(paymentParty.PaymentInvoice)
        });
      }))
    });

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  updateValidatorsAndDisabled() {
    FormServiceHelper.setValidators(this.formGroup, this.onGetValidators(this.formGroup));
    FormServiceHelper.setDisabled(this.formGroup, this.onGetDisabled(this.formGroup));

    const paymentParties = this.formGroup.get("PaymentParties") as FormArray<IIncentiveCompensationPaymentInfo>;
    paymentParties.controls.forEach(paymentInfo => {
      this.updatePaymentInfoValidatorsAndDisabled(paymentInfo as FormGroup<IIncentiveCompensationPaymentInfo>);
    })
  }

  private onGetValidators(formGroup: FormGroup<IIncentiveCompensation>): ValidatorsConfig<IIncentiveCompensation> {
    return {
      IsThirdPartyImport: [Validators.required],
      CommissionThirdPartyWorkerReference: [Validators.required]
    };
  }

  private onGetDisabled(formGroup: FormGroup<IIncentiveCompensation>): DisabledConfig<IIncentiveCompensation> {
    const isEligibleForCommission = formGroup.get("IsEligibleForCommission").value;
    const isThirdPartyImport = formGroup.get("IsThirdPartyImport").value;

    return {
      IsThirdPartyImport: !isEligibleForCommission,
      CommissionThirdPartyWorkerReference: !(isEligibleForCommission && isThirdPartyImport),
      IsCommissionVacation: !(isEligibleForCommission && isThirdPartyImport),
      PaymentParties: !isEligibleForCommission
    };
  }

  private updatePaymentInfoValidatorsAndDisabled(formGroup: FormGroup<IIncentiveCompensationPaymentInfo>) {
    const paymentInvoice = formGroup.get("PaymentInvoice") as FormGroup<IPaymentInvoice>;
    this.paymentInfoFormService.updatePaymentInvoiceValidatorsAndDisabled(paymentInvoice)
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.isEligibleForCommissionChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      this.updateValidatorsAndDisabled();
    });

    this.isThirdPartyImportChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      this.updateValidatorsAndDisabled();
    });

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const incentiveDetails: IIncentiveCompensation = this.formGroup.value;

    workOrder.WorkOrderVersion.IsEligibleForCommission = incentiveDetails.IsEligibleForCommission;
    workOrder.WorkOrderVersion.IsEligibleForAllowance = incentiveDetails.IsEligibleForAllowance;
    workOrder.WorkOrderVersion.IsThirdPartyImport = incentiveDetails.IsThirdPartyImport;
    workOrder.WorkOrderVersion.CommissionThirdPartyWorkerReference = incentiveDetails.CommissionThirdPartyWorkerReference;
    workOrder.WorkOrderVersion.PaymentInfoes.forEach(paymentInfo => {
      paymentInfo.IsCommissionVacation = incentiveDetails.IsCommissionVacation;
    });
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const incentiveCompensation: IIncentiveCompensation = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue({
      IsEligibleForCommission: incentiveCompensation.IsEligibleForCommission,
      IsEligibleForAllowance: incentiveCompensation.IsEligibleForAllowance,
      IsThirdPartyImport: incentiveCompensation.IsThirdPartyImport,
      CommissionThirdPartyWorkerReference: incentiveCompensation.CommissionThirdPartyWorkerReference,
      IsCommissionVacation: incentiveCompensation.IsCommissionVacation
    }, { emitEvent: false });

    this.updateValidatorsAndDisabled();
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): IIncentiveCompensation {
    return {
      IsEligibleForCommission: workorder.WorkOrderVersion.IsEligibleForCommission,
      IsEligibleForAllowance: workorder.WorkOrderVersion.IsEligibleForAllowance,
      IsThirdPartyImport: workorder.WorkOrderVersion.IsThirdPartyImport,
      CommissionThirdPartyWorkerReference: workorder.WorkOrderVersion.CommissionThirdPartyWorkerReference,
      IsCommissionVacation: workorder.WorkOrderVersion.PaymentInfoes[0].IsCommissionVacation,
      PaymentParties: workorder.WorkOrderVersion.PaymentInfoes.map(paymentInfo => {
        const paymentInvoice = paymentInfo.PaymentInvoices.find(invoice => invoice.InvoiceTypeId == PhxConstants.InvoiceType.IncentiveCompensation);
        return {
          PaymentInvoice: this.paymentInfoFormService.getPaymentInvoiceFormData(paymentInvoice)
        };
      })
    };
  }
}
