import { PhxConstants } from '@common/model';

import { Theme } from '../models/theme.model';

export const DEMO_SANDBOX_THEME: Theme = {
  tenantCode: PhxConstants.Tenant.DEMO_SANDBOX,
  tenantLogo: '/assets/logos/gorillaworks-light-logo.svg',
  attributes: [
    {
      value: '#FFFFFF',
      variableName: 'side-nav-text-hover'
    },
    {
      value: '#FFFFFF',
      variableName: 'logo-background'
    },
    {
      value: '#F09105',
      variableName: 'accent-color'
    },
    {
      value: 'url("/assets/images/todo-list-check-procom.svg")',
      variableName: 'todo-list-check'
    },
    {
      value: 'url("/assets/timeline/status_current.svg")',
      variableName: 'timeline-current-icon'
    },
    {
      value: 'url("/assets/timeline/status_done.svg")',
      variableName: 'timeline-done-icon'
    }
  ]
};
