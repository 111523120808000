<div class="panel-container">
    <!-- NOTE: header -->
    <div class="header-container">
        <div class="title-container">
            <h3>
                <div class="material-icons-outlined col-xs-1">sticky_note_2</div>
                <div class="col-xs-4" i18n="@@common.notes">Notes</div>
            </h3>
        </div>
    </div>
    <div class="note-list-container">
        <!-- NOTE: no notes message -->
        <div class="note-item-container" *ngIf="!notes?.length">
            <div class="note-item">
                <div class="note-row no-notes">
                    <mat-icon fontSet="material-icons-outlined">
                        do_not_disturb_on
                    </mat-icon>
                    <div>Currently, there are no notes.</div>
                </div>
            </div>
        </div>
        <!-- NOTE: note list -->
        <ng-container *ngFor="let note of notes; let i=index">
            <div class="note-item-container">
                <div class="note-item">
                    <div class="note-row note-header">
                        <span class="note-status">
                            <button mat-icon-button
                                *ngIf="note.IsRead"
                                title="Mark as unread"
                                (click)="toggleIsRead(note, false)">
                                <mat-icon>drafts</mat-icon>
                            </button>
                            <button mat-icon-button
                                *ngIf=" !note.IsRead"
                                title="Mark as read"
                                (click)="toggleIsRead(note, true)">
                                <mat-icon>mail</mat-icon>
                            </button>
                        </span>
                        <div class="note-header-text">
                            <div class="note-date">             
                                <span>
                                    {{note.CreatedDatetime| date:dateColumnFormat}}
                                </span>
                            </div>
                            <div class="note-by">by {{note.CommentOwnerName}}</div>
                        </div>
                    </div>
                    <div class="note-row note-description">
                        <span [innerHTML]="note.Comment"></span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
