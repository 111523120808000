import { InjectionToken } from '@angular/core';

import { InMemoryStorageService, StorageService, StorageTranscoders } from 'ngx-webstorage-service';

import { IframeService } from './iframe.service';

/**
 * localStorage wrapper, used to ensure the storage is available,
 *  when the window localStorage access is limited due to iframe or browser settings.
 *
 *  NOTE: any change to the name of this token should be accompanied by a change in .eslintrc.js,
 *  since it references its name.
 * */
export const AppStorageService = new InjectionToken<StorageService>('APP_STORAGE');

/**
 * sessionStorage wrapper, used to ensure the storage is available,
 *  when the window sessionStorage access is limited due to iframe or browser settings.
 *
 *  NOTE: any change to the name of this token should be accompanied by a change in .eslintrc.js,
 *  since it references its name.
 * */
export const AppSessionStorageService = new InjectionToken<StorageService>('APP_SESSION_STORAGE');

export const appStorageServiceFactory = (iframeService: IframeService, storageFactory: () => StorageService): StorageService<string> => {
  let storage: StorageService;
  if (iframeService.isIframe) {
    storage = new InMemoryStorageService();
  } else {
    storage = storageFactory();
  }

  return storage.withDefaultTranscoder(StorageTranscoders.STRING);
};
