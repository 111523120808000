import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { IBillingInvoice, IPaymentInvoice, ITabExpenseBillingInfo, ITabExpenseInvoice, ITabExpensePaymentInfo } from '../../models/work-order-form.interface';
import { BillingInfoFormService } from './billing-info-form.service';
import { ExpenseDetailFormService } from './expense-detail-form.service';
import { PaymentInfoFormService } from './payment-info-form.service';

@Injectable()
export class ExpenseInvoiceTabFormService implements IFormService {

  formGroup: FormGroup<ITabExpenseInvoice>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService,
    private billingInfoFormService: BillingInfoFormService,
    private expenseDetailFormService: ExpenseDetailFormService,
  ) { }

  get billingInfosFormArray(): FormArray<ITabExpenseBillingInfo> {
    return this.formGroup.get('TabExpenseInvoiceBillingInfoes') as FormArray<ITabExpenseBillingInfo>;
  }

  get paymentInfosFormArray(): FormArray<ITabExpensePaymentInfo> {
    return this.formGroup.get('TabExpenseInvoicePaymentInfoes') as FormArray<ITabExpensePaymentInfo>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabExpenseInvoice> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabExpenseInvoice>({
      TabExpenseInvoiceDetail: this.expenseDetailFormService.createForm(workorder, isDestroyed$),
      TabExpenseInvoiceBillingInfoes: this.createBillingInfosFormArray(formValue.TabExpenseInvoiceBillingInfoes),
      TabExpenseInvoicePaymentInfoes: this.createPaymentInfosFormArray(formValue.TabExpenseInvoicePaymentInfoes)
    });

    return this.formGroup;
  }

  onGetDisabled(formGroup: FormGroup<ITabExpenseInvoice>): DisabledConfig<ITabExpenseInvoice> {
    const expenseMethodologyId = formGroup.get('TabExpenseInvoiceDetail').get('ExpenseMethodologyId').value;
    const noExpense = !expenseMethodologyId || expenseMethodologyId == PhxConstants.ExpenseMethodology.NoExpense;

    return {
      TabExpenseInvoiceBillingInfoes: noExpense,
      TabExpenseInvoicePaymentInfoes: noExpense
    };
  }

  private createBillingInfosFormArray(billingInfos: ITabExpenseBillingInfo[]): FormArray<ITabExpenseBillingInfo> {
    return this.fb.array<ITabExpenseBillingInfo>(billingInfos.map(billingInfo => {
      return this.createBillingInfoFormGroup(billingInfo)
    }));
  }

  private createBillingInfoFormGroup(billingInfo: ITabExpenseBillingInfo): FormGroup<ITabExpenseBillingInfo> {
    return this.fb.group<ITabExpenseBillingInfo>({
      ...billingInfo,
      ...{
        BillingInvoice: this.billingInfoFormService.createBillingInvoiceFormGroup(billingInfo.BillingInvoice)
      }
    })
  }

  private createPaymentInfosFormArray(paymentInfos: ITabExpensePaymentInfo[]): FormArray<ITabExpensePaymentInfo> {
    return this.fb.array<ITabExpensePaymentInfo>(paymentInfos.map(paymentInfo => {
      return this.createPaymentInfoFormGroup(paymentInfo);
    }));
  }

  private createPaymentInfoFormGroup(paymentInfo: ITabExpensePaymentInfo): FormGroup<ITabExpensePaymentInfo> {
    return this.fb.group<ITabExpensePaymentInfo>({
      ...paymentInfo,
      ...{
        PaymentInvoice: this.fb.group<IPaymentInvoice>(paymentInfo.PaymentInvoice)
      }
    })
  }

  private mapWorkOrderToFormData(workOrder: IWorkOrder): ITabExpenseInvoice {
    return {
      TabExpenseInvoiceDetail: this.expenseDetailFormService.mapWorkOrderToFormData(workOrder),
      TabExpenseInvoiceBillingInfoes: workOrder.WorkOrderVersion.BillingInfoes.map(billingInfo => {
        const billingInvoice = billingInfo.BillingInvoices.find(invoice => invoice.InvoiceTypeId == PhxConstants.InvoiceType.Expense);
        return {
          BillingInvoice: this.billingInfoFormService.getBillingInvoiceFormData(billingInvoice)
        };
      }),
      TabExpenseInvoicePaymentInfoes: workOrder.WorkOrderVersion.PaymentInfoes.map(paymentInfo => {
        const paymentInvoice = paymentInfo.PaymentInvoices.find(invoice => invoice.InvoiceTypeId == PhxConstants.InvoiceType.Expense);
        return {
          PaymentInvoice: this.paymentInfoFormService.getPaymentInvoiceFormData(paymentInvoice)
        };
      })
    };
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.expenseDetailFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {

    const formValue = this.formGroup.value;
    
    const workOrderVersion = workOrder.WorkOrderVersion;

    const invoiceTypeId = PhxConstants.InvoiceType.Expense;
    
    workOrderVersion.BillingInfoes = formValue.TabExpenseInvoiceBillingInfoes
      .map((billingParty, index) => {
        const billingInfoDto = workOrderVersion.BillingInfoes[index];

        return {
          ...billingInfoDto,
          BillingInvoices: this.billingInfoFormService.patchBillingInvoicesForInvoiceType(billingInfoDto, billingParty.BillingInvoice, invoiceTypeId)
        };
      });

    workOrderVersion.PaymentInfoes = formValue.TabExpenseInvoicePaymentInfoes
      .map((paymentParty, index) => {
        const paymentInfoDto = workOrderVersion.PaymentInfoes[index];

        return {
          ...paymentInfoDto,
          PaymentInvoices: this.paymentInfoFormService.patchPaymentInvoicesForInvoiceType(paymentInfoDto, paymentParty.PaymentInvoice, invoiceTypeId)
        };
      });

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.expenseDetailFormService.updateForm(workorder);

    this.updateBillingInfoes(formValue);
    this.updatePaymentInfoes(formValue);
  }

  updateBillingInfoes(formValue: ITabExpenseInvoice) {
    const billingInfoFormArray = this.formGroup.get('TabExpenseInvoiceBillingInfoes') as FormArray<ITabExpenseBillingInfo>;

    billingInfoFormArray.patchValue(formValue.TabExpenseInvoiceBillingInfoes);

    FormServiceHelper.addRemoveFormArrayControls(billingInfoFormArray, this.fb, (value) => this.createBillingInfoFormGroup(value));
  }

  updatePaymentInfoes(formValue: ITabExpenseInvoice) {
    const paymentInfoFormArray = this.formGroup.get('TabExpenseInvoicePaymentInfoes') as FormArray<ITabExpensePaymentInfo>;

    paymentInfoFormArray.patchValue(formValue.TabExpenseInvoicePaymentInfoes);

    FormServiceHelper.addRemoveFormArrayControls(paymentInfoFormArray, this.fb, (value) => this.createPaymentInfoFormGroup(value));
  }

  getInvoiceFormGroup(infoIndex: number): FormGroup<IBillingInvoice> {
    const infoFormGroup = this.billingInfosFormArray.at(infoIndex);
    return infoFormGroup.get('BillingInvoice') as FormGroup<IBillingInvoice>;
  }

  setBillingReferenceContactProfileId(userProfileId: number) {
    this.billingInfosFormArray.controls.forEach(billingInfo => {
      billingInfo.get('BillingInvoice').get('BillingReferenceContactProfileId').setValue(userProfileId, { emitEvent: false });
    });
  }

  addTimesheetBillingInvoiceNote(infoIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.addTimesheetBillingInvoiceNote(invoiceFormGroup);
  }

  removeTimesheetBillingInvoiceNote(infoIndex: number, timeSheetIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.removeTimeSheetBillingInvoiceNote(
      timeSheetIndex,
      invoiceFormGroup
    );
  }

  addBillingRecipient(infoIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.addBillingRecipientFormGroup(invoiceFormGroup);
  }

  removeBillingRecipient(infoIndex: number, recipientIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.removeBillingRecipientFormGroup(
      recipientIndex,
      invoiceFormGroup
    );
  }
}
