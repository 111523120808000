import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

import { ApiService, PhxToastService, SignalrService, CodeValueService, PhxConstants, CodeValueGroups } from '../../common';
import { PhxToastType } from '../../common/components/phx-toast/phx-toast-types';
import { CommandResponse, EntityList } from '../../common/model';
import { ICommissionChangeImpactSummary } from '../commission-change-impact/commission-change-impact.interface';
import { ICommissionRateReassignItemsByRestrictionDto as IReassignItemsByRestrictionDto } from '../commission-rate.interface';

@Injectable({
  providedIn: 'root'
})
export class CommissionRateService {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private toastService: PhxToastService,
    private signalrSvc: SignalrService,
    private codeValueService: CodeValueService
  ) { }

  public canViewOtherCommissionUserRates() {
    return this.apiService.query<boolean>('Commission/canViewOtherCommissionUserRates');
  }
  public navigateToCommissionRate(headerId: number, versionId: number, tabName?: string, extras?: NavigationExtras) {
    if (!tabName) {
      tabName = PhxConstants.CommissionRateNavigationName.detail;
    }
    return new Promise<boolean>((resolve, reject) => {
      const route = ['/next/commission/rate', headerId, versionId, tabName];
      this.router.navigate(route, extras)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          console.error('Error navigating to commission rate: ', route);
          reject(error);
        });
    });
  }

  public navigateToCommissionUser(userProfileId: number) {
    this.router.navigate(['/next/commission/rates-search', userProfileId]);
  }

  public executeStateAction(commandName: string, payload: any, successMessage?: string) {

    return new Promise<CommandResponse>((resolve, reject) => {
      this.apiService.command(commandName, payload)
        .then(response => {
          if (successMessage) {
            this.toastService.showToast('Success:', successMessage, PhxToastType.success);
          }

          resolve(response);
        })
        .catch(error => reject(error));
    });
  }

  public getReassignmentItems(commissionRateHeaderId: number, minEffectiveDate: string) {
    const url = `Commission/reassignmentItems/${commissionRateHeaderId}?minEffectiveDate=${minEffectiveDate}`;
    
    return this.apiService
      .query<EntityList<IReassignItemsByRestrictionDto>>(url, false)
      .pipe(
        map(response => {
          response.Items.forEach(dto => {
            dto.BranchName = this.codeValueService.getCodeValueText(dto.BranchId, CodeValueGroups.InternalOrganizationDefinition1);
            dto.LineOfBusinessName = this.codeValueService.getCodeValueText(dto.LineOfBusinessId, CodeValueGroups.LineOfBusiness);
          });

          const items = _(response.Items)
            .sortBy([
              dto => dto.BranchName,
              dto => dto.InternalOrganizationName,
              dto => dto.ClientOrganizationName,
              dto => dto.LineOfBusinessName
            ])
            .value();

          return items;
        })
      );
  }

  public commissionChangeImpact(command) {
    return this.apiService.command('CommissionCorrectionCalculateImpact', command);
  }

  public getApprovedCommissionChangeImpactForCommissionRate(entityTypeId: number, entityId: number): Promise<ICommissionChangeImpactSummary> {
    return this.apiService.queryWithPromise(`commission/getApprovedCommissionChangeImpact/EntityTypeId/${entityTypeId}/EntityId/${entityId}`);
  }

  getCommissionChangeImpact(callback: (event, data) => void) {
    return this.signalrSvc.onPrivate('CommissionCorrectionCalculateImpact', callback);
  }

  notifyCommissionCorrectionSteps(commandName: string, callback: (event, data) => void) {
    return this.signalrSvc.onPrivate(commandName, callback);
  }
}
