/* eslint-disable @typescript-eslint/naming-convention */

import { MAIL_CAMPAIGN_PATHS } from '../../mail-campaign/mass-mailer-routing/mail-campaign-paths.const';

export const MODULE_ROUTES = {
  ACCESS_CODE: 'access-code',
  ACCESS_TOKEN: 'access_token',
  ACCOUNT: 'account',
  ACCOUNT_FORGOT: 'accountforgot',
  ACTIVITY_CENTRE: 'activity-centre',
  ADMIN: 'admin',
  ATTRIBUTIONS: 'attributions',
  COMMISSION: 'commission',
  COMPLIANCE: 'compliance',
  CONTACT: 'contact',
  CONTACT_LEGACY: 'contact-legacy',
  CONTACT_SUBSCRIPTIONS: 'contact/subscriptions',
  DEMO: 'demo',
  DOCUMENT: 'document-2',
  DOCUMENT_LEGACY: 'document',
  DOWNLOAD_CENTRE: 'download-center',
  EXPENSE: 'expense',
  FOREIGN_EXCHANGE: 'foreignexchange',
  GLOBAL_SEARCH: 'global-search',
  HOME: 'home',
  INVOICE: 'invoice',
  JOURNAL: 'journal',
  LOGIN: 'login',
  MAIN: 'next',
  ONBOARDING: 'onboarding',
  ORGANIZATION: 'organization',
  OVERTIME: 'overtime',
  PAYMENT: 'payment',
  PAYROLL: 'payroll',
  PERMANENT_PLACEMENT: 'permanent-placement',
  PII: 'pii',
  PORTAL: 'portal',
  SSO: 'sso',
  // TODO: remove once not needed
  LEGACY_SSO: 'procomsso',
  PURCHASE_ORDER: 'purchase-order',
  REDIRECT_MAPPED_USER: 'redirect-mappeduser',
  REGISTER: 'register',
  REPORT: 'report',
  REPORT_SEARCH: 'report-azsearch',
  RESET: 'reset',
  SERVICE_UNAVAILABLE: 'service-unavailable',
  STAT_HOLIDAY: 'statholiday',
  SUBSCRIPTION: 'subscription',
  TEAM: 'team',
  TEMPLATE: 'template',
  TIMESHEET: 'timesheet',
  TRANSACTION: 'transaction',
  USER_GUIDES: 'user-guides',
  VIEW_EMAIL_IN_BROWSER: 'view-email-in-browser',
  VMS: 'vms',
  VMS_DAILY_TIMESHEET: 'vms-daily-timesheet',
  WORK_ORDER: 'workorder-2',
  WORK_ORDER_LEGACY: 'workorder',
  NO_ACCESS: 'no-access',
  MAIL_CAMPAIGN: MAIL_CAMPAIGN_PATHS.modulePath
} as const;
