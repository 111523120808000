import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { IFormService } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ICommissionRate, IJobOwner, ITabCoreCommissions, IWorkOrder, IWorkOrderVersionCommission } from '../../models';
import { ExpenseInvoiceTabFormService } from './expense-invoice-tab-form.service';
import { TimeMaterialInvoiceTabFormService } from './time-material-invoice-tab-form.service';

@Injectable()
export class CoreCommissionFormService implements IFormService {
  isBusinessRulesEnabled = false;
  formGroup: FormGroup<ITabCoreCommissions>;

  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private expenseInvoiceTabFormService: ExpenseInvoiceTabFormService,
    private timeMaterialInvoiceTabFormService: TimeMaterialInvoiceTabFormService
  ) {
  }

  get usesSupportFormControl(): FormControl<boolean> {
    return this.formGroup.get('JobOwnerUsesSupport') as FormControl<boolean>;
  }

  get salePatternIdFormControl(): FormControl<number> {
    return this.formGroup.get('SalesPatternId') as FormControl<number>;
  }

  get jobOwnerFormGroup(): FormGroup<IJobOwner> {
    return this.formGroup.get('JobOwner') as FormGroup<IJobOwner>;
  }

  get recruitersFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('Recruiters') as FormArray<IJobOwner>;
  }

  get commissionsFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('WorkOrderVersionCommissions') as FormArray<IJobOwner>;
  }

  get supportingJobOwnerFormArray(): FormArray<IJobOwner> {
    return this.formGroup.get('SupportingJobOwners') as FormArray<IJobOwner>;
  }

  get isRecruiterRemovedFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsRecruiterRemoved') as FormControl<boolean>;
  }

  get usesSupportChange$() {
    return this.usesSupportFormControl.valueChanges;
  }

  get salePatternIdChange$() {
    return this.salePatternIdFormControl.valueChanges;
  }

  get jobOwnerChange$() {
    return this.jobOwnerFormGroup.valueChanges;
  }

  get userProfileIdSaleChange$() {
    return this.jobOwnerFormGroup.get('UserProfileIdSales').valueChanges;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    this.isBusinessRulesEnabled = workorder.readOnlyStorage.IsCommissionsEditable;

    const commission: ITabCoreCommissions = this.mapWorkOrderToFormData(workorder);

    const validate = true;

    this.formGroup = this.fb.group<ITabCoreCommissions>({
      SalesPatternId: [commission.SalesPatternId],
      JobOwnerUsesSupport: [
        commission.JobOwnerUsesSupport,
        (!commission.SalesPatternId && validate)
          ? Validators.required
          : null
      ],
      JobOwner: this.createJobOwnerFormGroup(commission.JobOwner, false),
      SupportingJobOwners: this.createJobOwnerFormArray(commission.SupportingJobOwners, false,
        'WorkOrderVersion.SupportingJobOwners', false),
      Recruiters: this.createJobOwnerFormArray(commission.Recruiters, false,
        'WorkOrderVersion.Recruiters', false),
      IsRecruiterRemoved: [commission.IsRecruiterRemoved],
      WorkOrderVersionCommissions: this.createJobOwnerFormArray(commission.WorkOrderVersionCommissions, true,
        'WorkOrderVersion.WorkOrderVersionCommissions', false)
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.pipe(takeUntil(this.isRootComponentDestroyed$)).subscribe(() => {
      this.destroyForm();
    });
    this.observeSalesPatternChange();
    this.observeJobOwnerFormGroup();
  }

  updateUserProfileIdSale(value: number, emitEvent = false) {
    this.jobOwnerFormGroup.get('UserProfileIdSales').setValue(value, { emitEvent });
  }

  resetSupportingJobOwner() {
    this.clearArray(this.supportingJobOwnerFormArray);
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const commissionDetails: ITabCoreCommissions = this.formGroup.value;

    workOrder.WorkOrderVersion.WorkOrderVersionCommissions = commissionDetails.WorkOrderVersionCommissions as IWorkOrderVersionCommission[];
    workOrder.WorkOrderVersion.SalesPatternId = commissionDetails.SalesPatternId;
    workOrder.WorkOrderVersion.JobOwnerUsesSupport = commissionDetails.JobOwnerUsesSupport;
    workOrder.WorkOrderVersion.JobOwner = commissionDetails.JobOwner;
    workOrder.WorkOrderVersion.Recruiters = commissionDetails.Recruiters;
    workOrder.WorkOrderVersion.IsRecruiterRemoved = commissionDetails.IsRecruiterRemoved;
    workOrder.WorkOrderVersion.SupportingJobOwners = commissionDetails.SupportingJobOwners;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    this.isBusinessRulesEnabled = workorder.readOnlyStorage.IsCommissionsEditable;

    const commission: ITabCoreCommissions = this.mapWorkOrderToFormData(workorder);

    const validate = true;

    if (!commission.SalesPatternId && validate) {
      this.usesSupportFormControl.setValidators(Validators.required);
    } else {
      this.usesSupportFormControl.setValidators(null);
    }

    this.formGroup.patchValue({
      SalesPatternId: commission.SalesPatternId,
      IsRecruiterRemoved: commission.IsRecruiterRemoved,
      JobOwnerUsesSupport: commission.JobOwnerUsesSupport
    }, { emitEvent: false });

    this.updateJobOwnerFormGroup(this.jobOwnerFormGroup, commission.JobOwner, false);

    this.updateJobOwnerFormArray(this.supportingJobOwnerFormArray, commission.SupportingJobOwners, false,
      'WorkOrderVersion.SupportingJobOwners', false);

    this.updateJobOwnerFormArray(this.recruitersFormArray, commission.Recruiters, false,
      'WorkOrderVersion.Recruiters', false);

    this.updateVersionCommissionFormArray(commission.WorkOrderVersionCommissions);
  }

  updateVersionCommissionFormArray(commissions: Array<IJobOwner>) {
    this.updateJobOwnerFormArray(this.commissionsFormArray, commissions, true,
      'WorkOrderVersion.WorkOrderVersionCommissions', false);
  }

  resetRecruiterFormArray() {
    this.clearArray(this.recruitersFormArray);
  }

  createJobOwnerFormArray(
    jobOwners: Partial<IJobOwner>[],
    isCommissionRateHeaderValidation: boolean = false,
    modelPrefix?: string,
    requireNonEmpty: boolean = false
  ): FormArray<IJobOwner> {
    const arr = jobOwners?.length || !requireNonEmpty ? jobOwners : new Array<Partial<IJobOwner>>({});
    return this.fb.array<IJobOwner>(
      arr.map((support: any) =>
        this.createJobOwnerFormGroup(support, isCommissionRateHeaderValidation)
      )
    );
  }

  createJobOwnerFormGroup(
    jobOwner: Partial<IJobOwner> = {},
    isCommissionRateHeaderValidation: boolean = false
  ): FormGroup<IJobOwner> {
    return this.fb.group<IJobOwner>({
      Id: [jobOwner.Id ? jobOwner.Id : 0],
      UserProfileIdSales: [
        jobOwner.UserProfileIdSales,
        ((isCommissionRateHeaderValidation && jobOwner.IsApplicable) || !isCommissionRateHeaderValidation)
          ? Validators.required
          : null
      ],
      CommissionRoleId: [jobOwner.CommissionRoleId],
      IsApplicable: [jobOwner.IsApplicable],
      CommissionRateHeaderId: [
        jobOwner.CommissionRateHeaderId,
        isCommissionRateHeaderValidation && jobOwner.IsApplicable
          ? Validators.required
          : null
      ],
      FullName: [jobOwner.FullName],
      Description: [jobOwner.Description],
      CommissionRates: this.createCommissionRateFormArray(jobOwner.CommissionRates, jobOwner.CommissionRateHeaderId),
      IsHouseAccount: [jobOwner.IsHouseAccount]
    });
  }

  createBlankPartialJobOwnerFormGroup(): FormGroup<Partial<IJobOwner>> {
    return this.fb.group<Partial<IJobOwner>>({
      Id: [0],
      UserProfileIdSales: [null, [Validators.required]],
      CommissionRoleId: [null],
      IsApplicable: [null],
      CommissionRateHeaderId: [null],
      FullName: [null],
      Description: [null],
      CommissionRates: this.fb.array([]),
      IsHouseAccount: [null]
    });
  }

  checkRecruiters(isRecruitersAllowed: boolean) {
    const recruiters = this.recruitersFormArray.value;
    const isRecruiterRemoved = this.isRecruiterRemovedFormControl.value;
    if (recruiters.length === 0 && isRecruitersAllowed && !isRecruiterRemoved) {
      this.addRecruiter();
    }
  }

  addRecruiter() {
    const formArray = this.recruitersFormArray as FormArray<Partial<IJobOwner>>;
    formArray.push(this.createBlankPartialJobOwnerFormGroup());
    this.isRecruiterRemovedFormControl.patchValue(false, { emitEvent: false });
  }

  deleteRecruiter(index: number) {
    this.recruitersFormArray.removeAt(index);
    if (this.recruitersFormArray.length === 0) {
      this.isRecruiterRemovedFormControl.patchValue(true, { emitEvent: false });
    }
  }

  addSupportingJobOwner() {
    const formArray = this.supportingJobOwnerFormArray as FormArray<Partial<IJobOwner>>;
    formArray.push(this.createBlankPartialJobOwnerFormGroup());
  }

  deleteSupportingJobOwner(index: number) {
    this.supportingJobOwnerFormArray.removeAt(index);
  }

  private observeSalesPatternChange() {
    this.salePatternIdChange$.pipe(
      filter(() => this.isBusinessRulesEnabled),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      if (value) {
        this.usesSupportFormControl.setValidators(null);
      } else {
        let emitEvent = false;
        if (this.jobOwnerFormGroup.value) {
          this.jobOwnerFormGroup.get('UserProfileIdSales').setValue(null, { emitEvent: false });
          emitEvent = true;
        }
        this.usesSupportFormControl.setValue(null, { emitEvent });
        this.clearArray(this.supportingJobOwnerFormArray);
      }
    });
  }

  private observeJobOwnerFormGroup() {
    this.jobOwnerChange$.pipe(
      filter(() => this.isBusinessRulesEnabled),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      const jobOwner = value.UserProfileIdSales;
      this.timeMaterialInvoiceTabFormService.setBillingReferenceContactProfileId(jobOwner);
      this.expenseInvoiceTabFormService.setBillingReferenceContactProfileId(jobOwner);
    });
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabCoreCommissions {
    return {
      SalesPatternId: workorder.WorkOrderVersion.SalesPatternId,
      JobOwnerUsesSupport: workorder.WorkOrderVersion.JobOwnerUsesSupport,
      JobOwner: workorder.WorkOrderVersion.JobOwner,
      SupportingJobOwners: workorder.WorkOrderVersion.SupportingJobOwners,
      Recruiters: workorder.WorkOrderVersion.Recruiters,
      IsRecruiterRemoved: workorder.WorkOrderVersion.IsRecruiterRemoved,
      WorkOrderVersionCommissions: workorder.WorkOrderVersion.WorkOrderVersionCommissions
    };
  }

  private updateJobOwnerFormArray(
    formArray: FormArray<IJobOwner>,
    items: Partial<IJobOwner>[],
    isCommissionRateHeaderValidation: boolean = false,
    modelPrefix?: string,
    requireNonEmpty: boolean = false
  ) {
    if (formArray.length && items.length) {
      items.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IJobOwner>;
        if (formGroup) {
          this.updateJobOwnerFormGroup(formGroup, item, isCommissionRateHeaderValidation);
        } else {
          formArray.push(this.createJobOwnerFormGroup(item, isCommissionRateHeaderValidation));
        }
      });
      if (formArray.length > items.length) {
        this.clearArray(formArray, items.length);
      }
    } else if (items.length) {
      const array = this.createJobOwnerFormArray(items, isCommissionRateHeaderValidation, modelPrefix, requireNonEmpty);
      array.controls.forEach(group => {
        formArray.push(group);
      });
    } else {
      this.clearArray(formArray);
    }
  }

  private updateJobOwnerFormGroup(
    formGroup: FormGroup<IJobOwner>,
    jobOwner: Partial<IJobOwner> = {},
    isCommissionRateHeaderValidation: boolean = false) {

    if ((isCommissionRateHeaderValidation && jobOwner.IsApplicable) || !isCommissionRateHeaderValidation) {
      formGroup.get('UserProfileIdSales').setValidators(Validators.required);
    } else {
      formGroup.get('UserProfileIdSales').setValidators(null);
    }

    if (isCommissionRateHeaderValidation && jobOwner.IsApplicable) {
      formGroup.get('CommissionRateHeaderId').setValidators(Validators.required);
    } else {
      formGroup.get('CommissionRateHeaderId').setValidators(null);
    }

    formGroup.patchValue({
      Id: jobOwner.Id ? jobOwner.Id : 0,
      CommissionRoleId: jobOwner.CommissionRoleId,
      IsApplicable: jobOwner.IsApplicable,
      CommissionRateHeaderId: jobOwner.CommissionRateHeaderId,
      FullName: jobOwner.FullName,
      Description: jobOwner.Description,
      IsHouseAccount: jobOwner.IsHouseAccount,
      UserProfileIdSales: jobOwner.UserProfileIdSales
    }, { emitEvent: false });

    const formArray = formGroup.get('CommissionRates') as FormArray<ICommissionRate>;
    this.updateCommissionRateFormArray(formArray, jobOwner.CommissionRates, jobOwner.CommissionRateHeaderId);
  }

  private createCommissionRateFormGroup(rate: Partial<ICommissionRate>): FormGroup<ICommissionRate> {
    const formGroup = this.fb.group<Partial<ICommissionRate>>({});
    Object.keys(rate).forEach(key => formGroup.addControl(key, new FormControl(rate[key])));
    return formGroup as FormGroup<ICommissionRate>;
  }

  private createCommissionRateFormArray(rates: Array<Partial<ICommissionRate>> = [], commissionRateHeaderId?: number): FormArray<ICommissionRate> {
    if (rates.length === 1) {
      rates[0].CommissionRateHeaderId = commissionRateHeaderId;
    }
    return this.fb.array<ICommissionRate>(
      rates.map((rate: ICommissionRate) => this.createCommissionRateFormGroup(rate))
    );
  }

  private updateCommissionRateFormArray(formArray: FormArray<ICommissionRate>, items: Array<Partial<ICommissionRate>> = [], commissionRateHeaderId?: number) {
    if (items.length === 1) {
      items[0].CommissionRateHeaderId = commissionRateHeaderId;
    }

    if (formArray.length && items.length) {
      items.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<ICommissionRate>;
        if (formGroup) {
          formGroup.patchValue(item, { emitEvent: false });
        } else {
          formArray.push(this.createCommissionRateFormGroup(item));
        }
      });
      if (formArray.length > items.length) {
        this.clearArray(formArray, items.length);
      }
    } else if (items.length) {
      const array = this.createCommissionRateFormArray(items, commissionRateHeaderId);
      array.controls.forEach(group => {
        formArray.push(group);
      });
    } else {
      this.clearArray(formArray);
    }
  }

  private clearArray(formArray: FormArray<IJobOwner | ICommissionRate>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }
}
