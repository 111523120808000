import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IStatutoryHoliday, IWorkOrder } from '../../models';

@Injectable()
export class StatutoryHolidayFormService implements IFormService {

  formGroup: FormGroup<IStatutoryHoliday>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(private fb: FormBuilder) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<IStatutoryHoliday> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<IStatutoryHoliday>({
      ApplyFlatStatPay: [workorder.WorkOrderVersion.ApplyFlatStatPay]
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const formGrouppStatutoryHolidayValues: IStatutoryHoliday = this.formGroup.value;
    workOrder.WorkOrderVersion.ApplyFlatStatPay = formGrouppStatutoryHolidayValues.ApplyFlatStatPay;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    this.formGroup.patchValue({ ApplyFlatStatPay: workorder.WorkOrderVersion.ApplyFlatStatPay }, { emitEvent: false });
  }

}
