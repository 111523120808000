import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { ICommissionRateDto, IRoot } from '../../commission-rate.interface';
import { CommissionRateDetailFormService } from './commission-rate-detail-form.service';

@Injectable()
export class CommissionRateFormService implements IFormService {
  formGroup: FormGroup<IRoot>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private detailFormService: CommissionRateDetailFormService,
  ) { }

  createForm(commissionRate: ICommissionRateDto, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    this.formGroup = this.fb.group<IRoot>({
      Id: commissionRate.Id,
      TabDetails: this.detailFormService.createForm(commissionRate, isDestroyed$),
    });
    this.setupFormListeners();
    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.detailFormService.setupFormListeners();
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(commissionRate: ICommissionRateDto, versionId: number): Partial<ICommissionRateDto> {
    return {
      ...this.detailFormService.formGroupToPartial(commissionRate, versionId),
    };
  }

  updateForm(commissionRate: ICommissionRateDto) {
    this.formGroup.get('Id').setValue(commissionRate.Id, { emitEvent: false });
    this.detailFormService.updateForm(commissionRate);
    this.formGroup.updateValueAndValidity({ emitEvent: false });
    this.formGroup.markAsPristine();
  }
}

