import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { FormServiceHelper, IFormService, PhxConstants } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { IBillingInvoice, IPaymentInvoice, ITabTimeBillingInfo, ITabTimeMaterialInvoice, ITabTimePaymentInfo } from '../../models/work-order-form.interface';
import { BillingInfoFormService } from './billing-info-form.service';
import { PaymentInfoFormService } from './payment-info-form.service';
import { TimeMaterialInvoiceDetailFormService } from './time-material-invoice-detail-form.service';

@Injectable()
export class TimeMaterialInvoiceTabFormService implements IFormService {

  formGroup: FormGroup<ITabTimeMaterialInvoice>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private paymentInfoFormService: PaymentInfoFormService,
    private billingInfoFormService: BillingInfoFormService,
    private timeMaterialInvoiceDetailFormService: TimeMaterialInvoiceDetailFormService,
  ) { }

  get billingInfoFormArray(): FormArray<ITabTimeBillingInfo> {
    return this.formGroup.get('TabTimeMaterialInvoiceBillingInfoes') as FormArray<ITabTimeBillingInfo>;
  }

  get paymentInfoFormArray(): FormArray<ITabTimePaymentInfo> {
    return this.formGroup.get('TabTimeMaterialInvoicePaymentInfoes') as FormArray<ITabTimePaymentInfo>;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTimeMaterialInvoice> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabTimeMaterialInvoice>({
      TabTimeMaterialInvoiceDetail: this.timeMaterialInvoiceDetailFormService.createForm(workorder, isDestroyed$),
      TabTimeMaterialInvoiceBillingInfoes: this.createBillingInfosFormArray(formValue.TabTimeMaterialInvoiceBillingInfoes),
      TabTimeMaterialInvoicePaymentInfoes: this.createPaymentInfosFormArray(formValue.TabTimeMaterialInvoicePaymentInfoes)
    });

    return this.formGroup;
  }

  private createBillingInfosFormArray(billingInfos: ITabTimeBillingInfo[]): FormArray<ITabTimeBillingInfo> {
    return this.fb.array<ITabTimeBillingInfo>(billingInfos.map(billingInfo => {
      return this.createBillingInfoFormGroup(billingInfo)
    }));
  }

  private createBillingInfoFormGroup(billingInfo: ITabTimeBillingInfo): FormGroup<ITabTimeBillingInfo> {
    return this.fb.group<ITabTimeBillingInfo>({
      ...billingInfo,
      ...{
        BillingInvoice: this.billingInfoFormService.createBillingInvoiceFormGroup(billingInfo.BillingInvoice)
      }
    })
  }

  private createPaymentInfosFormArray(paymentInfos: ITabTimePaymentInfo[]): FormArray<ITabTimePaymentInfo> {
    return this.fb.array<ITabTimePaymentInfo>(paymentInfos.map(paymentInfo => {
      return this.createPaymentInfoFormGroup(paymentInfo);
    }));
  }

  private createPaymentInfoFormGroup(paymentInfo: ITabTimePaymentInfo): FormGroup<ITabTimePaymentInfo> {
    return this.fb.group<ITabTimePaymentInfo>({
      ...paymentInfo,
      ...{
        PaymentInvoice: this.fb.group<IPaymentInvoice>(paymentInfo.PaymentInvoice)
      }
    })
  }

  private mapWorkOrderToFormData(workOrder: IWorkOrder): ITabTimeMaterialInvoice {
    return {
      TabTimeMaterialInvoiceDetail: this.timeMaterialInvoiceDetailFormService.mapWorkOrderToFormData(workOrder),
      TabTimeMaterialInvoiceBillingInfoes: workOrder.WorkOrderVersion.BillingInfoes.map(billingInfo => {
        const billingInvoice = billingInfo.BillingInvoices.find(invoice => invoice.InvoiceTypeId == PhxConstants.InvoiceType.TimeSheet);
        return {
          BillingInvoice: this.billingInfoFormService.getBillingInvoiceFormData(billingInvoice)
        };
      }),
      TabTimeMaterialInvoicePaymentInfoes: workOrder.WorkOrderVersion.PaymentInfoes.map(paymentInfo => {
        const paymentInvoice = paymentInfo.PaymentInvoices.find(invoice => invoice.InvoiceTypeId == PhxConstants.InvoiceType.TimeSheet);
        return {
          PaymentInvoice: this.paymentInfoFormService.getPaymentInvoiceFormData(paymentInvoice)
        };
      })
    };
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.timeMaterialInvoiceDetailFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.timeMaterialInvoiceDetailFormService.formGroupToPartial(workOrder);

    const formValue = this.formGroup.value;
    
    const workOrderVersion = workOrder.WorkOrderVersion;

    const invoiceTypeId = PhxConstants.InvoiceType.TimeSheet;
    
    workOrderVersion.BillingInfoes = formValue.TabTimeMaterialInvoiceBillingInfoes
      .map((billingParty, index) => {
        const billingInfoDto = workOrderVersion.BillingInfoes[index];

        return {
          ...billingInfoDto,
          BillingInvoices: this.billingInfoFormService.patchBillingInvoicesForInvoiceType(billingInfoDto, billingParty.BillingInvoice, invoiceTypeId)
        };
      });

    workOrderVersion.PaymentInfoes = formValue.TabTimeMaterialInvoicePaymentInfoes
      .map((paymentParty, index) => {
        const paymentInfoDto = workOrderVersion.PaymentInfoes[index];

        return {
          ...paymentInfoDto,
          PaymentInvoices: this.paymentInfoFormService.patchPaymentInvoicesForInvoiceType(paymentInfoDto, paymentParty.PaymentInvoice, invoiceTypeId)
        };
      });

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const formValue = this.formGroup.value;
    
    this.timeMaterialInvoiceDetailFormService.updateForm(workorder);

    this.updateBillingInfoes(formValue);
    this.updatePaymentInfoes(formValue);
  }

  updateBillingInfoes(formValue: ITabTimeMaterialInvoice) {
    const billingInfoFormArray = this.formGroup.get('TabTimeMaterialInvoiceBillingInfoes') as FormArray<ITabTimeBillingInfo>;

    billingInfoFormArray.patchValue(formValue.TabTimeMaterialInvoiceBillingInfoes);

    FormServiceHelper.addRemoveFormArrayControls(billingInfoFormArray, this.fb, (value) => this.createBillingInfoFormGroup(value));
  }

  updatePaymentInfoes(formValue: ITabTimeMaterialInvoice) {
    const paymentInfoFormArray = this.formGroup.get('TabTimeMaterialInvoicePaymentInfoes') as FormArray<ITabTimePaymentInfo>;

    paymentInfoFormArray.patchValue(formValue.TabTimeMaterialInvoicePaymentInfoes);

    FormServiceHelper.addRemoveFormArrayControls(paymentInfoFormArray, this.fb, (value) => this.createPaymentInfoFormGroup(value));
  }

  addPaymentInfo() {
    const paymentInfo: ITabTimePaymentInfo = {
      PaymentInvoice: {
        InvoiceTypeId: PhxConstants.InvoiceType.TimeSheet,
        PaymentInvoiceTemplateId: PhxConstants.PaymentInvoiceTemplate.PCGLStandardPaymentVoucher,
        PaymentMethodId: PhxConstants.PaymentMethodType.FromPayeeProfile,
        PaymentReleaseScheduleId: null,
        PaymentFrequency: null,
        PaymentInvoiceTermsId: null,
        IsSalesTaxAppliedOnVmsImport: null
      }
    } ;

    this.paymentInfoFormArray.push(this.createPaymentInfoFormGroup(paymentInfo));
  }

  getInvoiceFormGroup(infoIndex: number): FormGroup<IBillingInvoice> {
    const infoFormGroup = this.billingInfoFormArray.at(infoIndex);
    return infoFormGroup.get("BillingInvoice") as FormGroup<IBillingInvoice>;
  }

  setBillingReferenceContactProfileId(userProfileId: number) {
    this.billingInfoFormArray.controls.forEach(billingInfo => {
      billingInfo.get('BillingInvoice').get('BillingReferenceContactProfileId').setValue(userProfileId, { emitEvent: false });
    });
  }

  addTimesheetBillingInvoiceNote(infoIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.addTimesheetBillingInvoiceNote(invoiceFormGroup);
  }

  removeTimesheetBillingInvoiceNote(infoIndex: number, timeSheetIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.removeTimeSheetBillingInvoiceNote(
      timeSheetIndex,
      invoiceFormGroup
    );
  }

  addBillingRecipient(infoIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.addBillingRecipientFormGroup(invoiceFormGroup);
  }

  removeBillingRecipient(infoIndex: number, recipientIndex: number) {
    const invoiceFormGroup = this.getInvoiceFormGroup(infoIndex);
    this.billingInfoFormService.removeBillingRecipientFormGroup(
      recipientIndex,
      invoiceFormGroup
    );
  }

  private setBillingInfoesValidations(workOrder: IWorkOrder) {
    const methodologyId = workOrder.WorkOrderVersion.TimeSheetMethodologyId;

    const valid = {
      isBillingValid: (methodologyId
        && (methodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval
          || methodologyId === PhxConstants.TimeSheetMethodology.OfflineApproval)
        && workOrder.WorkOrderVersion.IsTimeSheetUsesProjects),
      isNoExpenseValid: false
    };
    return valid;
  }

  private setPaymentInfoesValidations() {
    const valid = {
      isNoExpenseValid: false
    };
    return valid;
  }
}
