import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { BaseComponentOnDestroy } from '../epics/base-component-on-destroy';
import { EntityVersion, PhxConstants } from '../model';
import { IPanelItem } from '../model/panel-bar';
import { IChecklistRefreshedEvent } from '../model/rule-engine';

@Injectable({
  providedIn: 'root'
})
export class PhxSideBarService {
  private entity: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private entityId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private entityStatus: BehaviorSubject<string> = new BehaviorSubject<string>(PhxConstants.StatusType.None);
  private entityType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private entityTypeId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  /** NOTE: this is implemented to make more entity details accessible in a side panel - can we get a type?  */
  private entityMetaData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private actionName: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private panelList: BehaviorSubject<IPanelItem[]> = new BehaviorSubject(null);
  private panelTypeToOpenOnLoad: BehaviorSubject<PhxConstants.PanelBarType | null> = new BehaviorSubject(null);
  private showSideBar = new BehaviorSubject<boolean>(false);
  private sidebarIsEnabled = new BehaviorSubject<boolean>(false);
  private entityVersions: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private currentVersion: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private currentGroup: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private statusGroup: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private versionsAreMultiple = new BehaviorSubject<any>(false);

  public onRefresh: EventEmitter<any> = new EventEmitter<any>();
  public readonly refreshWithRefreshedDocumentComplianceData$ = new Subject<{ complianceDocumentId: number, filePublicId: string, documentName: string }>();
  public onRefreshDocumentComplianceRules: EventEmitter<{ complianceDocumentId: number, filePublicId: string, documentName: string; }> = 
    new EventEmitter<{ complianceDocumentId: number, filePublicId: string, documentName: string; }>();
  public onIncludeOffboardingChecklist: EventEmitter<boolean> = new EventEmitter<boolean>();
  public checklistRefreshed: EventEmitter<IChecklistRefreshedEvent> = new EventEmitter<IChecklistRefreshedEvent>();
  public selectedVersion: EventEmitter<EntityVersion> = new EventEmitter<EntityVersion>();

  private activePanel$$ = new BehaviorSubject<IPanelItem | null>(null);

  public get activePanel$(): Observable<IPanelItem> {
    return this.activePanel$$;
  }
  public activePanel: PhxConstants.PanelBarType;
  public currentEntityType: PhxConstants.SideBarEntityType;

  public setActivePanel(type: PhxConstants.PanelBarType): void {
    this.activePanel = type;
    this.panelList.pipe(first()).subscribe(panels => {
      const activePanel = panels?.find(p => p.type === type);
      if (!activePanel) {
        // eslint-disable-next-line no-console
        console.error('Active panel type not found');
      } else {
        this.activePanel$$.next(activePanel);
      }
    });
  }

  public updateEntityStatus(newStatus: string) {
    this.entityStatus.next(newStatus);
  }

  public entityStatusChange$(): Observable<string> {
    return this.entityStatus.asObservable();
  }

  public setOpenOnLoadPanelType(panelBarType: PhxConstants.PanelBarType | null) {
    this.panelTypeToOpenOnLoad.next(panelBarType);
  }

  public openOnLoadPanelType$() {
    return this.panelTypeToOpenOnLoad.asObservable();
  }

  public updateEntity(newState: any) {
    this.entity.next(newState);
  }
  public entityChange$(): Observable<any> {
    return this.entity.asObservable();
  }

  public updateEntityId(newId: number) {
    this.entityId.next(newId);
  }
  public entityIdChange$(): Observable<number> {
    return this.entityId.asObservable();
  }

  public updateEntityType(value: PhxConstants.SideBarEntityType) {
    this.currentEntityType = value;
    this.entityType.next(value);
  }
  public entityTypeChange$(): Observable<PhxConstants.SideBarEntityType> {
    return this.entityType.asObservable();
  }

  public updateEntityTypeId(typeId: number) {
    this.entityTypeId.next(typeId);
  }
  public entityTypeIdChange$(): Observable<number> {
    return this.entityTypeId.asObservable();
  }

  public updateEntityMetaData(metaData: any) {
    this.entityMetaData.next(metaData);
  }
  public entityMetaDataChange$(): Observable<any> {
    return this.entityMetaData.asObservable();
  }

  public updateActionName(value: string) {
    this.actionName.next(value);
  }

  public actionNameChange$(): Observable<string> {
    return this.actionName.asObservable();
  }

  public updatePanelList(value: IPanelItem[]) {
    this.panelList.next(value);
  }

  public panelListChange$(): Observable<IPanelItem[]> {
    return this.panelList.asObservable();
  }

  public enableSideBar(hostComponent?: BaseComponentOnDestroy) {
    this.sidebarIsEnabled.next(true);
    if (hostComponent) {
      this.scheduleDisableSidebar(hostComponent);
    }
  }

  public disableSideBar() {
    this.sidebarIsEnabled.next(false);

    this.updateCurrentVersion$(null);
    this.updateCurrentGroup$(null);
    this.updateEntity(null);
    this.updateEntityType(null);
    this.updateActionName(null);
    this.updateStatusGroup$(null);
    this.closeSidebar();
    this.updatePanelList([]);
    this.setOpenOnLoadPanelType(null);
    this.updateEntityVersions$([]);
  }

  public getSideBarIsEnabled(): Observable<boolean> {
    return this.sidebarIsEnabled.asObservable();
  }

  public toggleSideBar() {
    this.showSideBar.next(!this.showSideBar.getValue());
  }

  public openSidebar() {
    this.showSideBar.next(true);
  }

  public closeSidebar() {
    this.showSideBar.next(false);
  }

  public showSideBar$(): Observable<boolean> {
    return this.showSideBar.asObservable();
  }

  //#region - VERSIONS
  public updateEntityVersions$(value: EntityVersion[]) {
    this.entityVersions.next(value);
  }
  public getEntityVersions$(): Observable<Array<EntityVersion>> {
    return this.entityVersions.asObservable();
  }

  public resetCurrentVersion(): void {
    this.currentVersion.next(null);
  }
  public updateCurrentVersion$(value: any) {
    this.currentVersion.next(value);
  }
  public getCurrentVersion$(): Observable<any> {
    return this.currentVersion.asObservable();
  }

  public resetCurrentGroup(): void {
    this.currentGroup.next(null);
  }
  public updateCurrentGroup$(value: any) {
    this.currentGroup.next(value);
  }
  public updateCurrentGroupAndVersion$(group: string, version: any) {
    this.currentGroup.next(group);
    this.currentVersion.next(version);
  }
  public getCurrentGroup$(): Observable<any> {
    return this.currentGroup.asObservable();
  }

  // FIXME: the setter is never used, so anything connected to getVersionsAreMultiple$ is false
  public updateVersionsAreMultiple(areMutiple: boolean) {
    this.versionsAreMultiple.next(areMutiple);
  }
  public getVersionsAreMultiple$(): Observable<boolean> {
    return this.versionsAreMultiple.asObservable();
  }
  //#endregion

  public updateStatusGroup$(value: any) {
    this.statusGroup.next(value);
  }

  public getStatusGroup$(): Observable<any> {
    return this.statusGroup.asObservable();
  }

  private scheduleDisableSidebar(componentInstance: BaseComponentOnDestroy): void {
    componentInstance.isDestroyed$.pipe(componentInstance.takeUntilDestroyed).subscribe({
      complete: () => {
        this.disableSideBar();
      }
    });
  }
}
