import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { PartyRateNegotiationFormService } from './party-rate-negotiation-form.service';
import { PartyIncentiveCompensationFormService } from './party-incentive-compensation-form.service';
import { PartyPaymentInfoFormService } from './party-payment-info-form.service';
import { PartyBillingInfoFormService } from './party-billing-info-form.service';
import { ITabPartiesandRates } from '../../models/work-order-form.interface';

@Injectable()
export class PartyTabFormService implements IFormService {

  formGroup: FormGroup<ITabPartiesandRates>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private partyRateNegotiationFormService: PartyRateNegotiationFormService,
    private partyIncentiveCompensationFormService: PartyIncentiveCompensationFormService,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
    private partyBillingInfoFormService: PartyBillingInfoFormService
  ) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabPartiesandRates> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<ITabPartiesandRates>({
      TabPartyBillingInfoes: this.partyBillingInfoFormService.createForm(workorder, isDestroyed$),
      TabPartyPaymentInfoes: this.partyPaymentInfoFormService.createForm(workorder, isDestroyed$),
      IncentiveCompensation: this.partyIncentiveCompensationFormService.createForm(workorder, isDestroyed$),
      RateNegotiation: this.partyRateNegotiationFormService.createForm(workorder, isDestroyed$)
    });
    
    this.updateValiatorsAndDisabled(workorder);

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.partyBillingInfoFormService.setupFormListeners();
    //this.partyPaymentInfoFormService.setupFormListeners();
    this.partyIncentiveCompensationFormService.setupFormListeners();
    this.partyRateNegotiationFormService.setupFormListeners();
  }

  updateValiatorsAndDisabled(workOrder: IWorkOrder) {
    const validatorsConfig = this.onGetValidators();
    FormServiceHelper.setValidators(this.formGroup, validatorsConfig);

    const disabledConfig = this.onGetDisabled(workOrder);
    FormServiceHelper.setDisabled(this.formGroup, disabledConfig);
  }

  onGetValidators(): ValidatorsConfig<ITabPartiesandRates> {
    return {
      // Nothing
    };
  }

  onGetDisabled(workOrder: IWorkOrder): DisabledConfig<ITabPartiesandRates> {

    return {
      RateNegotiation: !workOrder.WorkOrderVersion.IsRateNegotiationEnabled
    }
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.partyBillingInfoFormService.formGroupToPartial(workOrder);
    workOrder = this.partyPaymentInfoFormService.formGroupToPartial(workOrder);
    workOrder = this.partyIncentiveCompensationFormService.formGroupToPartial(workOrder);
    workOrder = this.partyRateNegotiationFormService.formGroupToPartial(workOrder);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    this.partyBillingInfoFormService.updateForm(workorder);
    this.partyPaymentInfoFormService.updateForm(workorder);
    this.partyIncentiveCompensationFormService.updateForm(workorder);
    this.partyRateNegotiationFormService.updateForm(workorder);

    this.updateValiatorsAndDisabled(workorder);
  }
}
