import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { DisabledConfig, FormServiceHelper, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IPaymentInfo, IPaymentInvoiceDto } from '../../models';
import { IPaymentInvoice } from '../../models/work-order-form.interface';

@Injectable()
export class PaymentInfoFormService {

  constructor(
    private fb: FormBuilder
  ) { }

  patchPaymentInvoicesForInvoiceType(paymentInfoDto: IPaymentInfo, invoice: IPaymentInvoice, invoiceType: PhxConstants.InvoiceType): IPaymentInvoiceDto[] {
    const existingInvoiceDto = paymentInfoDto.PaymentInvoices.find(dto => dto.InvoiceTypeId == invoiceType);

    // filter + concat: Combine invoices that aren't of this type with the new value for this invoice type
    return paymentInfoDto.PaymentInvoices
        .filter(invoiceDto => invoiceDto != existingInvoiceDto) 
        .concat([this.formGroupToPartialPaymentInvoice(invoice, existingInvoiceDto)]);
  }

  formGroupToPartialPaymentInvoice(invoice: IPaymentInvoice, invoiceDto: IPaymentInvoiceDto): IPaymentInvoiceDto {
    return {
      ...invoiceDto,
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      PaymentInvoiceTermsId: invoice.PaymentInvoiceTermsId,
      PaymentReleaseScheduleId: invoice.PaymentReleaseScheduleId,
      PaymentFrequency: invoice.PaymentFrequency,
      PaymentInvoiceTemplateId: invoice.PaymentInvoiceTemplateId,
      PaymentMethodId: invoice.PaymentMethodId
    };
  }

  updatePaymentInvoiceValidatorsAndDisabled(formGroup: FormGroup<IPaymentInvoice>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentInvoiceValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetPaymentInvoiceDisabled(formGroup));
  }

  private onGetPaymentInvoiceValidators(formGroup: FormGroup<IPaymentInvoice>): ValidatorsConfig<IPaymentInvoice> {
    
    return {
      IsSalesTaxAppliedOnVmsImport: [Validators.required],
      PaymentInvoiceTermsId: [Validators.required],
      PaymentReleaseScheduleId: [Validators.required],
      PaymentFrequency: [Validators.required],
      PaymentInvoiceTemplateId: [Validators.required],
      PaymentMethodId: [Validators.required],
    };
  }

  private onGetPaymentInvoiceDisabled(formGroup: FormGroup<IPaymentInvoice>): DisabledConfig<IPaymentInvoice> {
    // TODO: Disable whole invoice if Expense and ExpenseMethodology == NoExpense (ExpenseInvoiceTabFormService?)
    // TODO: Disable whole invoice if IncentiveCompensation and !IsEligibleForCommission (PartyIncentiveCompensationFormService?)

    // If whole Invoice is disabled, do it in the parent form service, e.g. ExpenseInvoiceTabFormService, not here
    
    const invoiceTypeId = formGroup.get("InvoiceTypeId").value;

    const expenseMethodologyId = invoiceTypeId == PhxConstants.InvoiceType.Expense
      ? null // TODO
      : null;

    const paymentInvoiceTermsId = formGroup.get("PaymentInvoiceTermsId").value;

    return {
      IsSalesTaxAppliedOnVmsImport: expenseMethodologyId != PhxConstants.ExpenseMethodology.ThirdPartyImport,
      PaymentReleaseScheduleId: paymentInvoiceTermsId != PhxConstants.PaymentInvoiceTerms.ScheduledTerms,
      PaymentFrequency: paymentInvoiceTermsId != PhxConstants.PaymentInvoiceTerms.Term
    };
  }

  getPaymentInvoiceFormData(invoice: IPaymentInvoiceDto): IPaymentInvoice {
    return {
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      PaymentInvoiceTermsId: invoice.PaymentInvoiceTermsId,
      PaymentReleaseScheduleId: invoice.PaymentReleaseScheduleId,
      PaymentFrequency: invoice.PaymentFrequency,
      PaymentInvoiceTemplateId: invoice.PaymentInvoiceTemplateId,
      PaymentMethodId: invoice.PaymentMethodId
    };
  }

  createPaymentInvoiceFormGroup(invoice: IPaymentInvoice) {
    return this.fb.group<IPaymentInvoice>({
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      PaymentInvoiceTermsId: invoice.PaymentInvoiceTermsId,
      PaymentReleaseScheduleId: invoice.PaymentReleaseScheduleId,
      PaymentFrequency: invoice.PaymentFrequency,
      PaymentInvoiceTemplateId: invoice.PaymentInvoiceTemplateId,
      PaymentMethodId: invoice.PaymentMethodId,
    });
    // Don't update validator/disabled here. We don't know what the parent form group is yet, and enabling a child control might enable the parent?
  }

  updatePaymentInvoiceFormGroup(
    formGroup: FormGroup<IPaymentInvoice>,
    invoice: IPaymentInvoice
  ) {

    formGroup.patchValue({
      InvoiceTypeId: invoice.InvoiceTypeId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      PaymentInvoiceTermsId: invoice.PaymentInvoiceTermsId,
      PaymentReleaseScheduleId: invoice.PaymentReleaseScheduleId,
      PaymentFrequency: invoice.PaymentFrequency,
      PaymentInvoiceTemplateId: invoice.PaymentInvoiceTemplateId,
      PaymentMethodId: invoice.PaymentMethodId,
    }, { emitEvent: false });
    // Don't update validator/disabled here. We don't know what the parent form group is yet, and enabling a child control might enable the parent?
  }

}
