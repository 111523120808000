import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormGroup, FormArray } from '../../../common/ngx-strongly-typed-forms/model';
import { IPaymentInfo, IWorkOrder } from '../../models';
import { ITabEarningsAndDeductions, IPaymentSourceDeductions, ISourceDeductions } from '../../models/work-order-form.interface';
import { OtherEarningsFormService } from './other-earnings-form.service';
import { StatutoryHolidayFormService } from './statutory-holiday-form.service';
import { WorkplaceSafetyInsuranceFormService } from './workplace-safety-insurance-form.service';

@Injectable()
export class EarningsDeductionsTabFormService implements IFormService {

  formGroup: FormGroup<ITabEarningsAndDeductions>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private otherEarningsFormService: OtherEarningsFormService,
    private statutoryHolidayFormService: StatutoryHolidayFormService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
  ) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabEarningsAndDeductions> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    // TODO: We can get the whole form value here? Do we still need to do this in the other child form services?
    const formValue = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabEarningsAndDeductions>({
      ...formValue,
      OtherEarnings: this.otherEarningsFormService.createForm(workorder, isDestroyed$),
      SourceDeductions: this.createSourceDeductionFormGroup(formValue.SourceDeductions),
      WorkplaceSafetyInsurance: this.workplaceSafetyInsuranceFormService.createForm(workorder, isDestroyed$),
      StatutoryHoliday: this.statutoryHolidayFormService.createForm(workorder, isDestroyed$),
    });

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  updateValidatorsAndDisabled() {
    FormServiceHelper.setDisabled(this.formGroup, this.onGetFormDisabled(this.formGroup));

    this.updateSourceDeductionsValidatorsAndDisabled(this.formGroup.get('SourceDeductions') as FormGroup<ISourceDeductions>);
  }

  onGetFormDisabled(formGroup: FormGroup<ITabEarningsAndDeductions>): DisabledConfig<ITabEarningsAndDeductions> {
    const workerHasSourceDeductions = true;
    return {
      SourceDeductions: !workerHasSourceDeductions
    }
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.otherEarningsFormService.setupFormListeners();
    this.workplaceSafetyInsuranceFormService.setupFormListeners();
    this.statutoryHolidayFormService.setupFormListeners();
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): ITabEarningsAndDeductions {

    const workOrderVersion = workorder.WorkOrderVersion;
    const paymentInfoDto = workOrderVersion.PaymentInfoes[0];

    return {
      AccrueEmployerHealthTaxLiability: workOrderVersion.AccrueEmployerHealthTaxLiability,
      SourceDeductions:  {
        IsUseUserProfileWorkerSourceDeduction: paymentInfoDto.IsUseUserProfileWorkerSourceDeduction,
        SubdivisionIdSourceDeduction: paymentInfoDto.SubdivisionIdSourceDeduction,
        PaymentSourceDeductions: (paymentInfoDto.PaymentSourceDeductions ?? []).map(sourceDeductionDto => {
          return {
            SourceDeductionTypeId: sourceDeductionDto.SourceDeductionTypeId,
            IsApplied: sourceDeductionDto.IsApplied,
            RateAmount: sourceDeductionDto.RateAmount
          }
        })
      },
      OtherEarnings: {
        PaymentOtherEarnings:(paymentInfoDto.PaymentOtherEarnings ?? []).map(otherEarningDto => {
          return {
            PaymentOtherEarningTypeId: otherEarningDto.PaymentOtherEarningTypeId,
            IsApplied: otherEarningDto.IsApplied,
            IsAccrued: otherEarningDto.IsAccrued,
            RatePercentage: otherEarningDto.RatePercentage,
            UseWorkerProfileVacationSetup: otherEarningDto.UseWorkerProfileVacationSetup
          }
        })
      },
      WorkplaceSafetyInsurance: {
        WCBIsApplied: workOrderVersion.WCBIsApplied,
        WCBPositionTitle: workOrderVersion.WCBPositionTitle,
        WorkerCompensationId: workOrderVersion.WorkerCompensationId
      },
      StatutoryHoliday: {
        ApplyFlatStatPay: workOrderVersion.ApplyFlatStatPay
      }
    };
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.statutoryHolidayFormService.formGroupToPartial(workOrder);
    workOrder = this.workplaceSafetyInsuranceFormService.formGroupToPartial(workOrder);

    const workOrderVersion = workOrder.WorkOrderVersion;

    const paymentInfoDto = workOrderVersion.PaymentInfoes[0];

    const formValue = this.formGroup.value;

    // Patch the form changes to the PaymentInfoDto
    // TODO: clear values form payment infoes where index > 0?
    workOrderVersion.PaymentInfoes[0] = {
      ...paymentInfoDto,
      ...{
        SubdivisionIdSalesTax: formValue.SourceDeductions.SubdivisionIdSourceDeduction,
        IsUseUserProfileWorkerSourceDeduction: formValue.SourceDeductions.IsUseUserProfileWorkerSourceDeduction,
        PaymentSourceDeductions: (formValue.SourceDeductions?.PaymentSourceDeductions ?? []).map((sourceDeduction, index) => {
          const sourceDeductionDto = paymentInfoDto.PaymentSourceDeductions[index]; // TODO: match SD type?

          return {
            ...sourceDeductionDto,
            ...{
              ...sourceDeduction // TODO: figure out the fields, the dto is any!
            }
          };
        }),
        PaymentOtherEarnings: (formValue.OtherEarnings.PaymentOtherEarnings ?? []).map((otherEarning, index) => {
          const otherEarningDto = paymentInfoDto.PaymentOtherEarnings[index]; // TODO: match on OtherEarning type?
          return {
            ...otherEarningDto,
            ...{
              ...otherEarning // TODO: map field by field if necessary
            }
          }
        })        
      }
    };

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    const formValue = this.mapWorkOrderToFormData(workorder);

    this.otherEarningsFormService.updateForm(workorder);
    this.workplaceSafetyInsuranceFormService.updateForm(workorder);
    this.statutoryHolidayFormService.updateForm(workorder);

    this.formGroup.patchValue({
      AccrueEmployerHealthTaxLiability: workorder.WorkOrderVersion.AccrueEmployerHealthTaxLiability,
    }, { emitEvent: false });

    this.updateSourceDeductionFormGroup(this.formGroup.get("SourceDeductions") as FormGroup<ISourceDeductions>, workorder);

    this.updateValidatorsAndDisabled();
  }

  getPaymentSourceDeductionsFormGroup(paymentSourceDeduction: IPaymentSourceDeductions) {
    return this.createPaymentSourceDeductionsFormGroup(paymentSourceDeduction);
  }

  updateAccrueEmployerHealthTaxLiability(value: boolean, emitEvent = false) {
    this.formGroup.get('AccrueEmployerHealthTaxLiability').patchValue(value, { emitEvent });
  }

  get subdivisionIdSourceDeductionControl() {
    return this.formGroup.get('SourceDeductions')?.get("SubdivisionIdSourceDeduction");
  }

  // updateFromInernalOrganizationChange? Why not just call updateForm?
  updateSubdivisionIdSourceDeduction(paymentInfoes: Array<IPaymentInfo>, workOrder: IWorkOrder, emitEvent = false) {

    const paymentInfo = paymentInfoes[0];
    // TODO: Is this really needed?
    this.subdivisionIdSourceDeductionControl?.patchValue(paymentInfo.SubdivisionIdSourceDeduction, { emitEvent });
    
    this.updateSourceDeductionFormGroup(this.formGroup.get('SourceDeductions') as FormGroup<ISourceDeductions>, workOrder);
  }

  updateSourceDeductionsValidatorsAndDisabled(formGroup: FormGroup<ISourceDeductions>) {
    FormServiceHelper.setValidators(formGroup, this.onGetSourceDeductionValidators(formGroup));

    const sourceDeductionsFormArray = formGroup.get('PaymentSourceDeductions') as FormArray<IPaymentSourceDeductions>;
    sourceDeductionsFormArray.controls.forEach(sourceDeduction => {
      this.updatePaymentSourceDeductionValidatorsAndDisabled(sourceDeduction as FormGroup<IPaymentSourceDeductions>);
    })
  }

  onGetSourceDeductionValidators(formGroup: FormGroup<ISourceDeductions>): ValidatorsConfig<ISourceDeductions> {
    return {
      SubdivisionIdSourceDeduction: [Validators.required],
      IsUseUserProfileWorkerSourceDeduction: [Validators.required]
    };
  }

  private createSourceDeductionFormGroup(formValue: ISourceDeductions): FormGroup<ISourceDeductions> {
    return this.fb.group<ISourceDeductions>({
      ...formValue,
      PaymentSourceDeductions: this.createPaymentSourceDeductionsFormArray(formValue.PaymentSourceDeductions)
    });
  }

  updatePaymentSourceDeductionValidatorsAndDisabled(formGroup: FormGroup<IPaymentSourceDeductions>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentSourceDeductionValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetPaymentSourceDeductionDisabled(formGroup));
  }

  onGetPaymentSourceDeductionValidators(formGroup: FormGroup<IPaymentSourceDeductions>): ValidatorsConfig<IPaymentSourceDeductions> {
    return {
      IsApplied: [Validators.required],
      RateAmount: [Validators.required],
    };
  }

  onGetPaymentSourceDeductionDisabled(formGroup: FormGroup<IPaymentSourceDeductions>): DisabledConfig<IPaymentSourceDeductions> {
    const isApplied = formGroup.get("IsApplied").value;
    const sourceDeductionTypeId = formGroup.get("SourceDeductionTypeId").value;
    return {
      RateAmount: !(isApplied && sourceDeductionTypeId == PhxConstants.SourceDeductionType.AdditionalTax)
    };
  }

  private createPaymentSourceDeductionsFormArray(paymentSourceDeductions: Array<IPaymentSourceDeductions>): FormArray<IPaymentSourceDeductions> {
    return this.fb.array<IPaymentSourceDeductions>(paymentSourceDeductions.map((paymentSourceDeduction: any, index: number) =>
      this.createPaymentSourceDeductionsFormGroup(paymentSourceDeduction))
    );
  }

  private createPaymentSourceDeductionsFormGroup(paymentSourceDeduction: IPaymentSourceDeductions): FormGroup<IPaymentSourceDeductions> {
    return this.fb.group<IPaymentSourceDeductions>({
      IsApplied: paymentSourceDeduction.IsApplied,
      RateAmount: paymentSourceDeduction.RateAmount,
      SourceDeductionTypeId: paymentSourceDeduction.SourceDeductionTypeId,
    });
  }

  private updateSourceDeductionFormGroup(
    formGroup: FormGroup<ISourceDeductions>,
    workorder: IWorkOrder
  ) {
    const paymentInfo = workorder.WorkOrderVersion.PaymentInfoes[0];

    formGroup.patchValue({
      SubdivisionIdSourceDeduction: paymentInfo.SubdivisionIdSourceDeduction,
      IsUseUserProfileWorkerSourceDeduction: paymentInfo.IsUseUserProfileWorkerSourceDeduction,
      PaymentSourceDeductions: paymentInfo.PaymentSourceDeductions
    }, { emitEvent: false });

    FormServiceHelper.addRemoveFormArrayControls(formGroup.get("PaymentSourceDeductions") as FormArray<IPaymentSourceDeductions>, this.fb);
  }

  updatePaymentSourceDeductions(paymentSourceDeductions: IPaymentSourceDeductions[]) {
    const sourceDeductionsFormGroup = this.formGroup.get("SourceDeductions") as FormGroup<ISourceDeductions>;
    const formArray = sourceDeductionsFormGroup.get("PaymentSourceDeductions") as FormArray<IPaymentSourceDeductions>

    // FormService.addRemoveFormControls doesn't work here because patchValue won't remove values if not in the new list?
    FormServiceHelper.updateFormArrayControls(formArray, paymentSourceDeductions, this.fb);

    this.updateSourceDeductionsValidatorsAndDisabled(sourceDeductionsFormGroup);
  }

}
