export interface IRecordOfEmploymentValidationResult {
  individualValidationResults: Array<IRecordOfEmploymentIndividualValidationResult>;
  groupValidationResults: IRecordOfEmploymentGroupValidationResult;
}

export interface IRecordOfEmploymentIndividualValidationResult {
  workOrderFullNumber: string;
  hasNoGovernmentRuling: boolean;
  hasRequestRoeInOffboardingChecklist: boolean;
  hasTimesheets: boolean;
  areAllAssignmentTimesheetCyclesConsistent: boolean
  areAllAssignmentPayCyclesConsistent: boolean
  areAllWorkOrderTimesheetsApproved: boolean;
  areAllTransactionsInPaidOrSuppressedStatus: boolean;
  areAllVacationPaymentsComplete: boolean;
  isSuccess: boolean;
}

export interface IRecordOfEmploymentGroupValidationResult {
  misalignedTimesheetCycleAssignments: number[];
  misalignedPayCycleAssignments: number[];
  areAllWorkOrdersSequential: boolean;
  areAllTimesheetCyclesConsistent: boolean;
  areAllPayCyclesConsistent: boolean;
}

export const RecordOfEmploymentValidationErrorMessages = {
  // individual validation messages
  hasNoGovernmentRuling: 'The profile is configured under the SP Government Ruling and does not require a Record of Employment (ROE).',
  hasRequestRoeInOffboardingChecklist: 'A Record of Employment (ROE) has not been requested for the selected work order.',
  hasTimesheets: 'There are no timesheets for the selected work order.',
  areAllAssignmentTimesheetCyclesConsistent: 'Assignment ${assignment} has work order versions or extensions with different timesheet cycles. Please process this Record of Employment (ROE) manually.',
  areAllAssignmentPayCyclesConsistent: 'Assignment ${assignment} has work order versions or extensions with different pay cycles. Please process this Record of Employment (ROE) manually.',
  areAllWorkOrderTimesheetsApproved: 'All timesheets for assignment ${assignment} are not yet approved. Please ensure they are approved before proceeding.',
  areAllTransactionsInPaidOrSuppressedStatus: 'Transactions for the selected work order are pending payment to the consultant.',
  areAllVacationPaymentsComplete: 'Vacation payment is still pending.',
  // group validation messages
  areAllWorkOrdersSequential: 'The selected work orders are not consecutive. Please verify if a separate Record of Employment (ROE) is needed for each work order.',
  areAllTimesheetCyclesConsistent: 'Assignment ${timesheetCycleAssignmentIds} have work orders with different timesheet cycles. Please process this Record of Employment (ROE) manually.',
  areAllPayCyclesConsistent: 'Assignment ${payCycleAssignmentIds} have work orders with different pay cycles. Please process this Record of Employment (ROE) manually.'
};

export interface IRoeFeatureConfiguration {
  skipRoeChecklistValidation: boolean;
}
