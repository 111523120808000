<div class="data-table-container">
  <app-phx-data-table #workOrdersGrid [configuration]="dataTableConfiguration" [dataSourceUrl]="dataSourceUrl" [dataSourceParams]='oDataParams'
    [columns]="columns" [exportFileName]="'workorder'" [componentName]="dataGridComponentName" (rowClick)="onRowClick($event)"
    (contextMenuPreparing)="onContextMenuPreparing($event)" (cellPrepared)="onCellPrepared($event)" (selectionChanged)="onSelectionChanged($event)">
    <button *ngIf="isRoeGenerationEnabled"
      toolbar
      i18n="@@contact.contactAssociatedWorkOrders.generateroe"
      class="btn btn-secondary add-selected-recipients"
      [disabled]="!anyWorkOrdersSelected" (click)="generateRoeReport()">
      Generate ROE Report
    </button>
  </app-phx-data-table>
</div>

<ng-template #roeReportTemplate>
  <app-roe-modal
    [modalTitle]="modalTitle"
    [roeFields]="roeFields"
    [detailedRows]="detailedRows"
    [softValidations]="softValidations"
    [dialogRef]="dialogRef">
  </app-roe-modal>
</ng-template>
