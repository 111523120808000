import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { IWorkOrderEntity, IWorkOrderTreeDto } from '../models/work-order-dtos.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderApiService { // TODO: Rename back to WorkOrderDataService?

  private readonly apiEndpoint: string;

  constructor(private apiService: ApiService) {
    this.apiEndpoint = environment.workOrderServiceApiEndpoint;
  }

  create(data: IWorkOrderTreeDto) {
    return this.apiService.httpPostRequest<IWorkOrderTreeDto>(`workorder`, data, this.apiEndpoint);
  }

  executeAction(id: number, actionName: string, data: IWorkOrderTreeDto) {
    return this.apiService.httpPostRequest(`workorder/${id}/actions/${actionName}`, data, this.apiEndpoint);
  }

  get(id: number): Observable<IWorkOrderEntity> {
    return this.apiService.httpGetRequest<IWorkOrderEntity>(`workorder/${id}`, this.apiEndpoint);
  }

  getTree(id: number, version: number): Observable<IWorkOrderTreeDto> {
    return this.apiService.httpGetRequest<IWorkOrderTreeDto>(`workorder/${id}/version/${version}/tree`, this.apiEndpoint);
  }

  getWorkOrderTreeByVersionId(id: number) {
    return this.apiService.httpGetRequest<IWorkOrderTreeDto>(`workorderversion/${id}/tree`, this.apiEndpoint);
  }

  // getTree(id: number, workOrderVersionId: number): Observable<IWorkOrderTreeDto> {
  //   return this.apiService.httpGetRequest<IWorkOrderTreeDto>(`workorder/${id}/work-order-version/${workOrderVersionId}/tree`, this.apiEndpoint);
  // }
}
