import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { IFormService } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { ICoreTabRoot, IWorkOrder } from '../../models';
import { CoreCollaboratorFormService } from './core-collaborator-form.service';
import { CoreCommissionFormService } from './core-commission-form.service';
import { CoreDetailFormService } from './core-detail-form.service';
import { CoreJobFormService } from './core-job-form.service';

@Injectable()
export class CoreTabFormService implements IFormService {
  formGroup: FormGroup<ICoreTabRoot>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private jobFormService: CoreJobFormService,
    private detailFormService: CoreDetailFormService,
    private commissionFormService: CoreCommissionFormService,
    private collaboratorFormService: CoreCollaboratorFormService) {
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    this.formGroup = this.fb.group<ICoreTabRoot>({
      Id: [workorder.Id],
      Details: this.detailFormService.createForm(workorder, isDestroyed$),
      Job: this.jobFormService.createForm(workorder, isDestroyed$),
      Commissions: this.commissionFormService.createForm(workorder, isDestroyed$),
      Collaborators: this.collaboratorFormService.createForm(workorder, isDestroyed$),
    });
    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.detailFormService.setupFormListeners();
    this.jobFormService.setupFormListeners();
    this.commissionFormService.setupFormListeners();
    this.collaboratorFormService.setupFormListeners();
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.detailFormService.formGroupToPartial(workOrder);
    workOrder = this.jobFormService.formGroupToPartial(workOrder);
    workOrder = this.commissionFormService.formGroupToPartial(workOrder);
    workOrder = this.collaboratorFormService.formGroupToPartial(workOrder);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    this.formGroup.get('Id').setValue(workorder.Id, { emitEvent: false });
    this.detailFormService.updateForm(workorder);
    this.jobFormService.updateForm(workorder);
    this.commissionFormService.updateForm(workorder);
    this.collaboratorFormService.updateForm(workorder);
  }

}
