import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs/internal/Subject';
import { FormBuilder, FormGroup } from '../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../workorder-2/models';
import { CustomField } from './model/custom-field';

@Injectable()
export class ClientSpecificFieldsFormService {
  formGroup: FormGroup<any>;
  private isRootComponentDestroyed$: Subject<boolean>;

  // TODO : Move IsClientSpecificFieldsIncluded from workorder.component.ts
  private clientSpecificFieldsData: CustomField[] = [];

  constructor(
    private fb: FormBuilder
  ) {
  }

  set fieldsData(clientSpecificFieldsData: Array<CustomField>) {
    this.clientSpecificFieldsData = clientSpecificFieldsData;
  }

  createForm(workOrder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<any> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const existingClientSpecificData = workOrder.WorkOrderVersion.ClientBasedEntityCustomFieldValue;
    const formData = this.getFormData(this.clientSpecificFieldsData, existingClientSpecificData);
    const controlsConfig = {};

    this.clientSpecificFieldsData.forEach((field) => {
      const validators = [];
      if (field.IsMandatory) {
        validators.push(Validators.required);
      }
      if (field.RegEx) {
        validators.push(Validators.pattern(field.RegEx));
      }
      let fieldValue = field.DefaultValue;
      if (formData[field.FieldName] != null) {
        fieldValue = formData[field.FieldName];
      }
      controlsConfig[field.FieldName] = [fieldValue, validators];
      if (field.SelectValues) {
        field.SelectValueArray = field.SelectValues.split(',');
      }
    });

    this.formGroup = this.fb.group(controlsConfig);

    this.clientSpecificFieldsData
      .filter(field => field.FieldType === 'Dropdown' && formData[field.FieldName])
      .forEach((field) => this.formGroup.controls[field.FieldName].setValue(formData[field.FieldName].trim()));

    return this.formGroup;
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder.WorkOrderVersion.ClientBasedEntityCustomFieldValue = this.formGroup.value;
    return workOrder;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  updateForm(workOrder: IWorkOrder) {
    const existingClientSpecificData = workOrder.WorkOrderVersion.ClientBasedEntityCustomFieldValue;
    const formData = this.getFormData(this.clientSpecificFieldsData, existingClientSpecificData);
    const formFields = Object.keys(this.formGroup.controls);

    this.clientSpecificFieldsData.forEach((field) => {
      const validators = [];
      if (field.IsMandatory) {
        validators.push(Validators.required);
      }
      if (field.RegEx) {
        validators.push(Validators.pattern(field.RegEx));
      }
      let fieldValue = field.DefaultValue;
      if (formData[field.FieldName] != null) {
        fieldValue = formData[field.FieldName];
      }

      const formField = this.formGroup.get(field.FieldName);

      if (formField) {
        this.formGroup.get(field.FieldName).setValidators(validators);

        this.formGroup.get(field.FieldName).patchValue(
          fieldValue as any,
          { emitEvent: false });
        const foundIndex: number = formFields.findIndex(item => item === field.FieldName);
        if (foundIndex > -1) {
          formFields.splice(foundIndex, 1);
        }
      } else {
        this.formGroup.addControl(field.FieldName, this.fb.control(fieldValue as any, validators));
      }

      if (field.SelectValues) {
        field.SelectValueArray = field.SelectValues.split(',');
      }
    });

    this.clientSpecificFieldsData
      .filter(field => field.FieldType === 'Dropdown' && formData[field.FieldName])
      .forEach((field) => this.formGroup.get(field.FieldName).patchValue(formData[field.FieldName].trim()));

    if (formFields.length) {
      formFields.forEach(field => this.formGroup.removeControl(field));
    }
  }

  private getFormData(fields: CustomField[], existingData?: any): any {
    const formData = cloneDeep(existingData) || {};
    fields.forEach(field => {
      const fieldAlreadyExists = formData.hasOwnProperty(field.FieldName);
      if (field.FieldValue && !fieldAlreadyExists) {
        if (field.FieldType === 'Datepicker') {
          formData[field.FieldName] = new Date(field.FieldValue);
        } else if (field.FieldType === 'DecimalNumber') {
          formData[field.FieldName] = parseFloat(field.FieldValue);
        } else {
          formData[field.FieldName] = field.FieldValue.trim();
        }
      }
    });
    return formData;
  }

}
