<div class="login-container">
    <div class="login-form mat-elevation-z4 text-center">
        <div class="login-img">
            <img src="../../../assets/portal/img-portal-migration.png" alt=""/>
        </div>
        <div class="login-title">
            <h3 i18n="@@account.portal.portalMessageTitle">We have moved!</h3>
        </div>
        <div class="login-text" i18n="@@account.portal.portalMessageText">
            Please log in via the Contractor Portal. If you do not have the link, kindly contact a customer service representative to obtain the correct one.
        </div>
    </div>
  </div>
  