import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DisabledConfig, FormServiceHelper, IFormService, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { IRateNegotiation } from '../../models/work-order-form.interface';

@Injectable()
export class PartyRateNegotiationFormService implements IFormService {

  formGroup: FormGroup<IRateNegotiation>;
  private isRootComponentDestroyed$: Subject<boolean>;

  get isRateCardUsedFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsRateCardUsed') as FormControl<boolean>;
  }

  get isRateCardUsedChange$() {
    return this.isRateCardUsedFormControl.valueChanges;
  }

  constructor(
    private fb: FormBuilder,
  ) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const rateNegotiation: IRateNegotiation = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<IRateNegotiation>(rateNegotiation);

    this.updateValiatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.isRateCardUsedChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(() => {
      this.updateValiatorsAndDisabled();
    });

    this.formGroup.get('InitialAskPayRate').valueChanges
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(() => {
      this.updateValiatorsAndDisabled();
    });

  }

  updateValiatorsAndDisabled() {
    const validatorsConfig = this.onGetValidators();
    FormServiceHelper.setValidators(this.formGroup, validatorsConfig);

    const disabledConfig = this.onGetDisabled();
    FormServiceHelper.setDisabled(this.formGroup, disabledConfig);
  }

  onGetValidators(): ValidatorsConfig<IRateNegotiation> {
    return {
      InitialAskPayRate: [Validators.required, Validators.min(0)],
      InitialAskBillRate: [Validators.required, Validators.min(0)],
      FeeSavings: [Validators.required, Validators.min(0)],
      BurdenSavings: [Validators.required, Validators.min(0)],
      AdditionalRateSavings: [Validators.required, Validators.min(0)],
      JobPostingNumber: [Validators.required, Validators.maxLength(20)],
      RateCardJobTitle: [Validators.required, Validators.maxLength(100)],
      ClientGroup: [Validators.maxLength(100)],
      RateNegotiationAdditionalInfo: [Validators.maxLength(100)],
      MaxBillRate: [Validators.required, Validators.min(0)],
      BurdenUsedPercentage: [Validators.required, Validators.min(0), Validators.max(100)],
      IsRatePrenegotiated: [Validators.required],
      IsRateCardUsed: [Validators.required],
      IsRateWithinRateCard: [Validators.required],
      SubvendorActualPayRate: [Validators.min(0)],
      SubvendorActualBurdenCosts: [Validators.min(0)],
      SubvendorMargin: [Validators.min(0)]
    };
  }

  onGetDisabled(): DisabledConfig<IRateNegotiation> {
    
    const isRateCardUsed = this.formGroup.get('IsRateCardUsed').value;

    return {
      IsRateWithinRateCard: !isRateCardUsed  
    };
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const rateNegotiation: IRateNegotiation = this.formGroup.value;
    workOrder.WorkOrderVersion.InitialAskPayRate = rateNegotiation.InitialAskPayRate;
    workOrder.WorkOrderVersion.InitialAskBillRate = rateNegotiation.InitialAskBillRate;
    workOrder.WorkOrderVersion.FeeSavings = rateNegotiation.FeeSavings;
    workOrder.WorkOrderVersion.BurdenSavings = rateNegotiation.BurdenSavings;
    workOrder.WorkOrderVersion.AdditionalRateSavings = rateNegotiation.AdditionalRateSavings;
    workOrder.WorkOrderVersion.JobPostingNumber = rateNegotiation.JobPostingNumber;
    workOrder.WorkOrderVersion.RateCardJobTitle = rateNegotiation.RateCardJobTitle;
    workOrder.WorkOrderVersion.ClientGroup = rateNegotiation.ClientGroup;
    workOrder.WorkOrderVersion.RateNegotiationAdditionalInfo = rateNegotiation.RateNegotiationAdditionalInfo;
    workOrder.WorkOrderVersion.MaxBillRate = rateNegotiation.MaxBillRate;
    workOrder.WorkOrderVersion.BurdenUsedPercentage = rateNegotiation.BurdenUsedPercentage;
    workOrder.WorkOrderVersion.IsRatePrenegotiated = rateNegotiation.IsRatePrenegotiated;
    workOrder.WorkOrderVersion.IsRateCardUsed = rateNegotiation.IsRateCardUsed;
    workOrder.WorkOrderVersion.IsRateWithinRateCard = rateNegotiation.IsRateWithinRateCard;
    workOrder.WorkOrderVersion.SubvendorActualPayRate = rateNegotiation.SubvendorActualPayRate;
    workOrder.WorkOrderVersion.SubvendorActualProfileTypeId = rateNegotiation.SubvendorActualProfileTypeId;
    workOrder.WorkOrderVersion.SubvendorActualBurdenCosts = rateNegotiation.SubvendorActualBurdenCosts;
    workOrder.WorkOrderVersion.SubvendorMargin = rateNegotiation.SubvendorMargin;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const rateNegotiation: IRateNegotiation = this.mapWorkOrderToFormData(workorder);

    // TODO: setValue vs patchValue
    this.formGroup.patchValue(rateNegotiation, { emitEvent: false });

    this.updateValiatorsAndDisabled();
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): IRateNegotiation {
    return {
      InitialAskPayRate: workorder.WorkOrderVersion.InitialAskPayRate,
      InitialAskBillRate: workorder.WorkOrderVersion.InitialAskBillRate,
      FeeSavings: workorder.WorkOrderVersion.FeeSavings,
      BurdenSavings: workorder.WorkOrderVersion.BurdenSavings,
      AdditionalRateSavings: workorder.WorkOrderVersion.AdditionalRateSavings,
      JobPostingNumber: workorder.WorkOrderVersion.JobPostingNumber,
      RateCardJobTitle: workorder.WorkOrderVersion.RateCardJobTitle,
      ClientGroup: workorder.WorkOrderVersion.ClientGroup,
      RateNegotiationAdditionalInfo: workorder.WorkOrderVersion.RateNegotiationAdditionalInfo,
      MaxBillRate: workorder.WorkOrderVersion.MaxBillRate,
      BurdenUsedPercentage: workorder.WorkOrderVersion.BurdenUsedPercentage,
      IsRatePrenegotiated: workorder.WorkOrderVersion.IsRatePrenegotiated,
      IsRateCardUsed: workorder.WorkOrderVersion.IsRateCardUsed,
      IsRateWithinRateCard: workorder.WorkOrderVersion.IsRateWithinRateCard,
      SubvendorActualPayRate: workorder.WorkOrderVersion.SubvendorActualPayRate,
      SubvendorActualProfileTypeId: workorder.WorkOrderVersion.SubvendorActualProfileTypeId,
      SubvendorActualBurdenCosts: workorder.WorkOrderVersion.SubvendorActualBurdenCosts,
      SubvendorMargin: workorder.WorkOrderVersion.SubvendorMargin
    };
  }
}
