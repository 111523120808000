import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, filter, pairwise, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ClientSpecificFieldsFormService } from 'src/app/client-specific-fields-2/client-specific-fields-form.service';
import { CodeValueService, CommonService, DialogService } from 'src/app/common';
import { ICommonListsItem, IHolidayScheduleName } from 'src/app/common/lists';
import { CommonListsObservableService } from 'src/app/common/lists/lists.observable.service';

import { CodeValueGroups, IFormService, PhxConstants } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IBillingInfo, IPaymentInfo, IRoot, IWorkOrder, IWorkOrderVersion } from '../../models';
import { WorkOrderDataService } from '../work-order-data.service';
import { WorkorderService } from '../workorder.service';
import { CoreCommissionFormService } from './core-commission-form.service';
import { CoreDetailFormService } from './core-detail-form.service';
import { CoreTabFormService } from './core-tab-form.service';
import { EarningsDeductionsTabFormService } from './earnings-deductions-tab-form.service';
import { ExpenseInvoiceTabFormService } from './expense-invoice-tab-form.service';
import { FooterFormService } from './footer-form.service';
import { PartyPaymentInfoFormService } from './party-payment-info-form.service';
import { PartyTabFormService } from './party-tab-form.service';
import { TaxesTabFormService } from './taxes-tab-form.service';
import { TimeMaterialInvoiceTabFormService } from './time-material-invoice-tab-form.service';
import { WorkplaceSafetyInsuranceFormService } from './workplace-safety-insurance-form.service';

@Injectable()
export class WorkOrderFormService implements IFormService {
  formGroup: FormGroup<IRoot>;
  private isRootComponentDestroyed$: Subject<boolean>;
  private holidayScheduleNames: Array<IHolidayScheduleName>;

  private WorkerProfilesMap: { [id: number]: any } = {};
  private OrganizationInternalMap: { [id: number]: any } = {};

  private workOrderSubject: BehaviorSubject<IWorkOrder | undefined> = new BehaviorSubject(null);
  workOrder$: Observable<IWorkOrder> = this.workOrderSubject.asObservable();

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private dialogService: DialogService,
    private workOrderService: WorkorderService,
    private codeValueService: CodeValueService,
    private commonListsObservableService: CommonListsObservableService,
    private workOrderDataService: WorkOrderDataService,
    private workplaceSafetyInsuranceFormService: WorkplaceSafetyInsuranceFormService,
    private coreTabFormService: CoreTabFormService,
    private coreDetailFormService: CoreDetailFormService,
    private coreCommissionFormService: CoreCommissionFormService,
    private partyTabFormService: PartyTabFormService,
    private footerFormService: FooterFormService,
    private taxesTabFormService: TaxesTabFormService,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService,
    private timeMaterialInvoiceTabFormService: TimeMaterialInvoiceTabFormService,
    private clientSpecificFieldsFormService: ClientSpecificFieldsFormService,
    private expenseInvoiceTabFormService: ExpenseInvoiceTabFormService,
    private earningsDeductionsTabFormService: EarningsDeductionsTabFormService
  ) {
    this.loadMaps();
    this.loadHolidayScheduleNames();
  }

  private get workOrder() {
    return this.workOrderSubject.value;
  }

  private set workOrder(workOrder: IWorkOrder) {
    this.workOrderSubject.next(workOrder);
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    this.formGroup = this.fb.group<IRoot>({
      Id: [workorder.WorkOrderVersion.Id],
      Footer: this.footerFormService.createForm(workorder, isDestroyed$),
      TabCore: this.coreTabFormService.createForm(workorder, isDestroyed$),
      TabParties: this.partyTabFormService.createForm(workorder, isDestroyed$),
      TabTimeMaterialInvoice: this.timeMaterialInvoiceTabFormService.createForm(workorder, isDestroyed$),
      TabTaxes: this.taxesTabFormService.createForm(workorder, isDestroyed$),
      ClientSpecificFields: this.clientSpecificFieldsFormService.createForm(workorder, isDestroyed$),
      TabExpenseInvoice: this.expenseInvoiceTabFormService.createForm(workorder, isDestroyed$),
      TabEarningsAndDeductions: this.earningsDeductionsTabFormService.createForm(workorder, isDestroyed$)
    });

    this.setupFormListeners();

    this.workOrder = workorder;

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
    this.workOrder = null;
    this.holidayScheduleNames = [];
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.footerFormService.setupFormListeners();
    this.coreTabFormService.setupFormListeners();
    this.partyTabFormService.setupFormListeners();
    this.timeMaterialInvoiceTabFormService.setupFormListeners();
    this.taxesTabFormService.setupFormListeners();
    this.clientSpecificFieldsFormService.setupFormListeners();
    this.expenseInvoiceTabFormService.setupFormListeners();
    this.earningsDeductionsTabFormService.setupFormListeners();

    this.coreDetailFormService.workSiteIdChange$.pipe(
      debounceTime(100),
      startWith(this.coreDetailFormService.workSiteIdFormControl.value),
      distinctUntilChanged(),
      pairwise(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([prev, _]) => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeWorksiteId(workOrder, prev);
      this.expenseInvoiceTabFormService.updateForm(workOrder); // TODO: expose method to update only billing infos?
      this.timeMaterialInvoiceTabFormService.updateForm(workOrder); // TODO: expose method to update only billing infos?
      this.taxesTabFormService.updateForm(workOrder);  // TODO: expose method to update only billing infos?
    });

    this.coreDetailFormService.workerLocationIdChange$.pipe(
      debounceTime(100),
      startWith(this.coreDetailFormService.workerLocationIdFormControl.value),
      distinctUntilChanged(),
      pairwise(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([prev, _]) => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeWorkerLocationId(workOrder, prev);
    });

    this.coreDetailFormService.organizationIdInternalChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async () => {
      const workOrder = this.formGroupToPartial(this.workOrder);
      await this.onChangeOrganizationIdInternal(workOrder);
      this.earningsDeductionsTabFormService.updateSubdivisionIdSourceDeduction(workOrder.WorkOrderVersion.PaymentInfoes, workOrder);
    });

    merge(
      this.coreDetailFormService.lineOfBusinessIdChange$,
      this.coreDetailFormService.organizationIdInternalChange$,
      this.coreDetailFormService.internalOrganizationDefinition1IdChange$,
      this.coreCommissionFormService.usesSupportChange$,
      this.coreCommissionFormService.userProfileIdSaleChange$,
      this.coreCommissionFormService.recruitersFormArray.valueChanges,
      this.coreCommissionFormService.supportingJobOwnerFormArray.valueChanges
    ).pipe(
      debounceTime(100),
      distinctUntilChanged(),
      withLatestFrom(this.workOrderSubject),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([_, workOrder]) => {
      workOrder = {
        ...workOrder,
        ...this.formGroupToPartial(workOrder)
      };
      workOrder = await this.workOrderDataService.updateCommissionRate(workOrder);
      
      this.coreCommissionFormService.updateVersionCommissionFormArray(workOrder.WorkOrderVersion.WorkOrderVersionCommissions);
      this.workOrder = workOrder;
    });

    this.coreCommissionFormService.salePatternIdChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      withLatestFrom(this.workOrderSubject),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(async ([value, workOrder]) => {
      workOrder = {
        ...workOrder,
        ...this.formGroupToPartial(workOrder)
      };
      workOrder = await this.workOrderDataService.onChangeSalesPattern(workOrder, value);
      this.coreCommissionFormService.updateForm(workOrder);
      this.workOrder = workOrder;
    });

  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    workOrder = this.footerFormService.formGroupToPartial(workOrder);
    workOrder = this.coreTabFormService.formGroupToPartial(workOrder);
    workOrder = { ...this.partyTabFormService.formGroupToPartial(workOrder) };
    workOrder = { ...this.timeMaterialInvoiceTabFormService.formGroupToPartial(workOrder) };
    workOrder = { ...this.expenseInvoiceTabFormService.formGroupToPartial(workOrder) };
    workOrder = this.taxesTabFormService.formGroupToPartial(workOrder);
    workOrder = this.earningsDeductionsTabFormService.formGroupToPartial(workOrder);
    workOrder = this.clientSpecificFieldsFormService.formGroupToPartial(workOrder);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder) {
    this.formGroup.get('Id').setValue(workorder.WorkOrderVersion.Id, { emitEvent: false });
    this.footerFormService.updateForm(workorder);
    this.coreTabFormService.updateForm(workorder);
    this.partyTabFormService.updateForm(workorder);
    this.timeMaterialInvoiceTabFormService.updateForm(workorder);
    this.taxesTabFormService.updateForm(workorder);
    this.clientSpecificFieldsFormService.updateForm(workorder);
    this.expenseInvoiceTabFormService.updateForm(workorder);
    this.earningsDeductionsTabFormService.updateForm(workorder);

    this.formGroup.updateValueAndValidity({ emitEvent: false });
    this.formGroup.markAsPristine();
    this.workOrder = workorder;
  }

  setWorkerProfiles(workerProfiles: Array<ICommonListsItem>) {
    this.WorkerProfilesMap = workerProfiles.reduce((map, val) => {
      map[val.Id] = val;
      return map;
    }, {});
  }

  getWorkerProfile(userProfileIdWorker: number) {
    if (Object.keys(this.WorkerProfilesMap).length) {
      return this.WorkerProfilesMap[userProfileIdWorker];
    }
    return null;
  }

  private loadMaps() {
    // assign organizations to a map
    this.commonListsObservableService.listOrganizationInternals$()
      .subscribe(response => {
        const list = response ? response.map(item => item.Data) : [];
        if (list.length) {
          this.OrganizationInternalMap = list.reduce((map, val) => {
            map[val.Id] = val;
            return map;
          }, {});
        }
      });
  }

  public loadHolidayScheduleNames() {
    this.commonListsObservableService.listHolidayScheduleName$()
    .subscribe((schedules: ICommonListsItem[]) => {
      this.holidayScheduleNames = schedules ? schedules.map(item => item.Data) : [];
    });
  }

  private async onChangeOrganizationIdInternal(workOrder: IWorkOrder): Promise<void> {
    const profile = this.WorkerProfilesMap[workOrder.UserProfileIdWorker];
    const org = this.OrganizationInternalMap[workOrder.OrganizationIdInternal];


    if ([PhxConstants.UserProfileType.WorkerTemp, PhxConstants.UserProfileType.WorkerCanadianSp, PhxConstants.UserProfileType.WorkerUnitedStatesW2].indexOf(profile.ProfileTypeId) === -1) {
      // not of the allowed profile types
      return;
    }

    await this.updateWorkerClassification(workOrder, 0, workOrder.WorkOrderVersion.WorkerLocationId);

    // Compare If the worker subdivisionId matches the organization passed
    const FindDefaultSourceDeductionSubdivision = (workerSub: number, organization: any): number => {
      // if the country of the head office is outside of canada don't default;
      if (organization.OrganizationAddresses.find(i => i.IsPrimary).CountryId !== PhxConstants.CountryCanada) {
        return null;
      }

      for (const organizationAddress of organization.OrganizationAddresses) {
        if (organizationAddress.SubdivisionId === workerSub) {
          return workerSub; // match found so return the workerSub
        }
      }

      // no match found at this point so return the sub id of the head office of the org
      return organization.OrganizationAddresses.find(i => i.IsPrimary).SubdivisionId;
    };

    let subdivisionIdSourceDeduction = null;
    // determine where to get the tax province from for the worker
    if (org) {
      if (profile.TaxSubdivisionId) {
        subdivisionIdSourceDeduction = FindDefaultSourceDeductionSubdivision(profile.TaxSubdivisionId, org);
      } else if (workOrder.RootObject.WorkerSubdivisionId) {
        subdivisionIdSourceDeduction = FindDefaultSourceDeductionSubdivision(workOrder.RootObject.WorkerSubdivisionId, org);
      }
    }

    // assign the new subdivision id to each payment info on the wov
    workOrder.WorkOrderVersion.PaymentInfoes.forEach(i => i.SubdivisionIdSourceDeduction = subdivisionIdSourceDeduction);
  }

  private async onChangeWorkerLocationId(workOrder: IWorkOrder, oldWorkerLocationId: number): Promise<void> {
    const workOrderVersion: IWorkOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
    if (workOrderVersion) {
      const workerLocationChangedMessages = [];

      await this.updateHolidaySchedule(workOrder, workerLocationChangedMessages);

      await this.updateWorkerClassification(workOrder, oldWorkerLocationId, workOrderVersion.WorkerLocationId, workerLocationChangedMessages);

      const billingInfo = workOrderVersion.BillingInfoes ? workOrderVersion.BillingInfoes[0] : null;
      const organizationClientSalesTaxDefaultId = billingInfo ? billingInfo.OrganizationClientSalesTaxDefaultId : null;

      if (organizationClientSalesTaxDefaultId !== PhxConstants.ClientSalesTaxDefault.HeadOffice &&
          workOrder.workerProfileTypeId === PhxConstants.UserProfileType.WorkerTemp &&
          this.changePaymentRates(workOrderVersion.PaymentInfoes, workOrderVersion.WorkerLocationId)) {
        workerLocationChangedMessages.push('The default setting for applying vacation pay has been updated.');
        this.partyPaymentInfoFormService.updateForm(workOrder);
      }

      if (workerLocationChangedMessages.length > 0) {
        this.dialogService.notify('The Worker Location has Changed', workerLocationChangedMessages.join('<br>'), { backdrop: 'static', size: 'md' });
      }
    }
  }

  private subdivisionIsCanadian(subdivisionId: number): boolean {
    if (subdivisionId) {
      const countryId = this.codeValueService.getCodeValue(subdivisionId, 'geo.CodeSubdivision').parentId;
      return countryId === PhxConstants.CountryCanada;
    }
    return false;
  }

  private async onChangeWorksiteId(workOrder: IWorkOrder, oldWorksiteId: number): Promise<void> {
    const workOrderVersion: IWorkOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
    if (workOrderVersion) {
      const worksiteChangedMessage = [];
      const billingInfo = workOrderVersion.BillingInfoes ? workOrderVersion.BillingInfoes[0] : null;
      const organizationClientSalesTaxDefaultId = billingInfo ? billingInfo.OrganizationClientSalesTaxDefaultId : null;
      const subdivisionIdSalesTax = billingInfo ? billingInfo.SubdivisionIdSalesTax : null;
      const worksiteId = workOrderVersion.WorksiteId;
      const subdivisionIdByWorksite = this.workOrderService.getSubdivisionIdByWorksiteId(worksiteId);

      if (subdivisionIdByWorksite) {
        if (organizationClientSalesTaxDefaultId !== PhxConstants.ClientSalesTaxDefault.HeadOffice) {
          //  when organizationClient is head office, then any changes of Worksite will NOT effect any messages or changes of billingInfo.SubdivisionIdSalesTax
          if (!subdivisionIdSalesTax && billingInfo.OrganizationIdClient) {
            await this.onChangeOrganizationIdClient(workOrder, billingInfo);
          } else {
            if (subdivisionIdSalesTax && subdivisionIdSalesTax !== subdivisionIdByWorksite) {
              const subdivisionByWorksiteName = this.codeValueService.getCodeValueText(subdivisionIdByWorksite, CodeValueGroups.Subdivision);
              const subdivisionSalesTaxName = this.codeValueService.getCodeValueText(subdivisionIdSalesTax, CodeValueGroups.Subdivision);
              worksiteChangedMessage.push('The Client Sales Tax Territory has been updated from "' + subdivisionSalesTaxName + '" to "' + subdivisionByWorksiteName + '"');
            }
            this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdByWorksite);
          }
        }
      }

      if (worksiteChangedMessage.length > 0) {
        this.dialogService.notify('The Client Worksite Province has Changed', worksiteChangedMessage.join('<br>'), { backdrop: 'static', size: 'md' });
      }
    }
  }

  private async updateHolidaySchedule(workOrder: IWorkOrder, changedMessages: any[]): Promise<void> {
    const { WorkOrderVersion } = workOrder;
    const billingInfo = WorkOrderVersion.BillingInfoes ? WorkOrderVersion.BillingInfoes[0] : null;
    const { WorkerLocationId } = WorkOrderVersion;

    if (WorkerLocationId && this.subdivisionIsCanadian(WorkerLocationId)) {
      let useDefaultSchedule = false;

      const response = await this.commonListsObservableService.listDetailedOrganizationClients$().pipe(filter(res => res != null), take(1)).toPromise();

      if (response) {
        const listOrganizationClient = response.map(i => i.Data);
        const organizationIdClient = billingInfo ? billingInfo.OrganizationIdClient : null;
        const organizationClient = listOrganizationClient ? listOrganizationClient.find(o => o.Id === organizationIdClient) : null;

        if (organizationClient?.OrganizationClientRoles) {
          const clientHolidayScheduleDefaultId = organizationClient.OrganizationClientRoles[0].HolidayScheduleDefaultId;
          if (clientHolidayScheduleDefaultId) {
            if (WorkOrderVersion.HolidayScheduleNameId !== clientHolidayScheduleDefaultId) {
              WorkOrderVersion.HolidayScheduleNameId = clientHolidayScheduleDefaultId;
              changedMessages.push('The Holiday Schedule has been updated.');
            }
          } else {
            useDefaultSchedule = true;
          }
        } else {
          useDefaultSchedule = true;
        }
      } else {
        useDefaultSchedule = true;
      }

      if (useDefaultSchedule) {
        const defaultSchedule = this.holidayScheduleNames.find(i => i.DefaultSubdivisionId === WorkerLocationId && i.IsActive === true);
        if (defaultSchedule) {
          WorkOrderVersion.HolidayScheduleNameId = defaultSchedule.HolidayScheduleNameId;
          changedMessages.push('The Holiday Schedule has been updated.');
        } else {
          WorkOrderVersion.HolidayScheduleNameId = null;
        }
      }

    } else {
      WorkOrderVersion.HolidayScheduleNameId = null;
    }

    this.coreDetailFormService.updateHolidayScheduleNameId(WorkOrderVersion.HolidayScheduleNameId);
  }

  private async updateWorkerClassification(workOrder: IWorkOrder, oldSubdivisionId: number, subdivisionId: number, changedMessages?: any[]): Promise<void> {
    const { WorkOrderVersion } = workOrder;
    if (oldSubdivisionId !== subdivisionId) {
      const checkEarningValidate = await this.workplaceSafetyInsuranceFormService.getValidatorForWorkerCompensation(workOrder);
      if (changedMessages && (WorkOrderVersion.WorkerCompensationId || this.workplaceSafetyInsuranceFormService.checkEarningvalidate !== checkEarningValidate)) {
        // notify user when (1) WCB was selected, or (2) field mandatory settings changed
        changedMessages.push('The Workplace Safety Insurance Worker Classification list has been updated.');
      }
      if (checkEarningValidate) {
        this.workplaceSafetyInsuranceFormService.setWorkerCompensationByWorkerLocation(subdivisionId, workOrder.OrganizationIdInternal);
      }
      else {
        WorkOrderVersion.WCBIsApplied = null;
        WorkOrderVersion.WorkerCompensationId = null;
      }
      console.log('checkEarningvalidate', 'work-order-form.service updateWorkerClassification', checkEarningValidate);
      this.workplaceSafetyInsuranceFormService.checkEarningvalidate = checkEarningValidate;
      this.workplaceSafetyInsuranceFormService.updateForm(workOrder);
    }
  }

  private async onChangeOrganizationIdClient(workOrder: IWorkOrder, billingInfo: IBillingInfo): Promise<void> {
    const response = await this.commonListsObservableService.listDetailedOrganizationClients$().pipe(filter(res => res != null), take(1)).toPromise();
    if (response) {
      const listOrganizationClient = response.map(i => i.Data);
      const workOrderVersion = workOrder ? workOrder.WorkOrderVersion : null;
      const organizationIdClient = billingInfo ? billingInfo.OrganizationIdClient : null;
      const organizationClient = listOrganizationClient ? listOrganizationClient.find(o => o.Id === organizationIdClient) : null;

      if (workOrderVersion && organizationClient) {
        billingInfo.OrganizationClientDisplayName = organizationClient.DisplayName;

        //  http://tfs:8080/tfs/DefaultCollection/Development/_workitems#_a=edit&id=15523
        if (!organizationClient.OrganizationClientRoles || organizationClient.OrganizationClientRoles.length !== 1) {
          return Promise.reject('Client Organization MUST have ONE OrganizationClientRole');
        }

        if (organizationClient.OrganizationClientRoles[0].IsChargeSalesTax === true) {
          if (organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId === PhxConstants.ClientSalesTaxDefault.HeadOffice) {
            const organizationAddressPrimary = organizationClient.OrganizationAddresses.find(address => address.IsPrimary);
            if (organizationAddressPrimary) {
              this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(organizationAddressPrimary.SubdivisionId);
            } else {
              this.commonService.logError('Client Organization MUST have Primary Address');
              this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(0);
            }
          } else if (organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId === PhxConstants.ClientSalesTaxDefault.WorkOrderWorksite) {
            const subdivisionIdSalesTax = this.workOrderService.getSubdivisionIdByWorksiteId(workOrderVersion.WorksiteId);
            this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdSalesTax);
          } else {
            this.commonService.logError('Client Organization ClientSalesTaxDefaultId "' + organizationClient.OrganizationClientRoles[0].ClientSalesTaxDefaultId + '" does NOT supported');
          }
        } else {
          const subdivisionIdSalesTax = this.workOrderService.getSubdivisionIdByWorksiteId(workOrderVersion.WorksiteId);
          this.taxesTabFormService.billingInfoesFormArray.at(0).get('SubdivisionIdSalesTax').setValue(subdivisionIdSalesTax);
        }

        if (organizationClient.OrganizationClientRoles[0].UsesThirdPartyImport) {
          workOrderVersion.TimeSheetMethodologyId = PhxConstants.ExpenseMethodology.ThirdPartyImport; // WRONG! should be TimeSheetMethodology.ThirdPartyImport
          if (billingInfo.BillingInvoices?.length) {
            billingInfo.BillingInvoices.forEach(billingInvoice => {
              if (billingInvoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) {
                billingInvoice.IsSalesTaxAppliedOnVmsImport = organizationClient.OrganizationClientRoles[0].IsBillSalesTaxAppliedOnExpenseImport;
              }
            });
          }
          if (workOrderVersion.PaymentInfoes?.length) {
            workOrderVersion.PaymentInfoes.forEach(paymentInfo => {
              if (paymentInfo?.PaymentInvoices?.length) {
                paymentInfo.PaymentInvoices.forEach(paymentInvoice => {
                  if (paymentInvoice.InvoiceTypeId === PhxConstants.InvoiceType.Expense) {
                    paymentInvoice.IsSalesTaxAppliedOnVmsImport = organizationClient.OrganizationClientRoles[0].IsPaySalesTaxAppliedOnExpenseImport;
                  }
                });
              }
            });
          }
        }
      }
    }
  }

  private changePaymentRates(paymentInfos: Array<IPaymentInfo>, subdivisionId: number): boolean {
    let isChanged = false;

    if (paymentInfos && subdivisionId) {
      paymentInfos.forEach(paymentInfo => {
        if (paymentInfo?.PaymentRates) {
          paymentInfo.PaymentRates.forEach(paymentRate => {
            const defaultDeductionConfig = PhxConstants.DefaultPaymentRateDeductions.find(r => r.RateTypeId === paymentRate.RateTypeId);

            const defaultConfig = defaultDeductionConfig ? defaultDeductionConfig.defaults.find(config => config.SubdivisionId === subdivisionId) : null;

            if (defaultConfig) {

              if (paymentRate.IsApplyVacation !== defaultConfig.IsApplyVacation || paymentRate.IsApplyDeductions !== defaultConfig.IsApplyDeductions) {
                isChanged = true;
                paymentRate.IsApplyVacation = defaultConfig.IsApplyVacation;
                paymentRate.IsApplyDeductions = defaultConfig.IsApplyDeductions;
              }
            }
          });
        }
      });
    }

    return isChanged;
  }

}
