// SRC: https://github.com/d-kostov-dev/ng2-mdf-validation-messages

import { AbstractControl, Validators, ValidatorFn, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { formatDate } from '@angular/common';

import { PhoneNumberUtil } from 'google-libphonenumber';

import { PhxConstants } from '../../model';
import { PhxLocalizationService } from '../../services/phx-localization.service';

/**
 * NOTE: if this class is removed, deal with the MessageProvider class, relying on it.
 * The structure for returned error messages is coupled with MessageProvider.getErrorMessage logic.
 * */
export class ValidationExtensions {
  /**
   * Set the input as required.
   * @param message Custom error message that will be shown to the user.
   */
  static required(message: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!Validators.required(control)) {
        return null;
      }

      return {
        required: {
          message
        }
      };
    };
  }

  /**
   * Set the minimal required length of the input value
   * @param length Minimal length.
   * @param message Custom error message that will be shown to the user. Supports placeholders.
   */
  static minLength(length: number, message: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      const validationResult = Validators.minLength(length)(control);

      if (validationResult) {
        validationResult.minlength.message = message;
      }

      return validationResult;
    };
  }

    /**
   * Set the maximal required length of the input value
   * @param length maximal length.
   * @param message Custom error message that will be shown to the user. Supports placeholders.
   */
    static maxLength(length: number, message: string = null): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } => {
        if (Validators.required(control)) {
          return null;
        }
  
        const validationResult = Validators.maxLength(length)(control);
  
        if (validationResult) {
          validationResult.maxlength.message = message;
        }
  
        return validationResult;
      };
    }

  /**
   * Set the minimal required value of the number input
   * @param min Minimal value.
   * @param message Custom error message that will be shown to the user. Supports placeholders.
   */
  static minNumber(min: number, message: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      if (isNaN(control.value) || +control.value >= min) {
        return null;
      }

      return {
        minNumber: {
          requiredRange: min,
          message
        }
      };
    };
  }

  /**
   * Set the minimal required value of the date input
   * @param min Minimal value.
   */
  static minDate(min: Date): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      if (new Date(control.value) >= min) {
        return null;
      }

      return {
        minDate: {
          requiredRange: formatDate(min, PhxConstants.DateFormat.shortDate, PhxLocalizationService.locale)
        }
      };
    };
  }

  /**
   * Set the maximal required value of the date input
   * @param max Maximal value.
   * @param message Custom error message that will be shown to the user. Supports placeholders.
   */
  static maxDate(max: Date, message: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      const controlVal = !!control.value ? new Date(control.value) : null;

      if (controlVal <= max) {
        return null;
      }

      return {
        maxDate: {
          requiredRange: formatDate(max, PhxConstants.DateFormat.shortDate, PhxLocalizationService.locale),
          message
        }
      };
    };
  }

  /**
   * Requires the input to follow a specific pattern.
   * @param pattern The required pattern.
   * @param message Custom error message that will be shown to the user.
   */
  static pattern(pattern: string | RegExp, message: string = null): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      const validationResult = Validators.pattern(pattern)(control);

      if (validationResult) {
        validationResult.pattern.message = message;
      }

      return validationResult;
    };
  }

  /**
   * Requires all values in a group to be equal. Like the 'areEqual' validation extension, but with specific passwords message.
   */
  static passwords(): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      if (ValidationExtensions.areGroupInputValuesEqual(group)) {
        return null;
      }

      return {
        passwords: {}
      };
    };
  }

  static custom(validatorFunc: ValidatorFn, message: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const result = validatorFunc(control);
      if (!result) {
        return null;
      }
      for (const validatorName in result) {
        if (result.hasOwnProperty(validatorName)) {
          if (typeof result[validatorName] === 'object') {
            result[validatorName] = { ...result[validatorName], message };
          } else {
            result[validatorName] = { message };
          }
        }
      }
      return result;
    };
  }

  /**
   * It accepts phone number as input
   * returns true or false
   * based on the phone number
   */
  static phoneNumberValidator(phone: string): boolean {
    if (phone) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      if ((phone || '').trim().length !== 0) {
        try {
          const phoneNumber = phoneNumberUtil.parse(phone);
          return phoneNumberUtil.isValidNumber(phoneNumber);
        } catch (error) {
          return false;
        }
      }
    }
  }

  /**
   * Will not accept input containing only empty spaces.
   */
  static noEmpty(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (Validators.required(control)) {
        return null;
      }

      if (control.value.trim() !== '') {
        return null;
      }

      return {
        noEmpty: {}
      };
    };
  }

  /**
   * Re-calculates the value and validation status of the entire controls tree.
   * Based on: https://microeducate.tech/angular-form-updatevalueandvalidity-of-all-children-controls/
   */
  static updateTreeValidity(group: UntypedFormGroup | UntypedFormArray, emitEvent?: boolean): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.controls[key];

      if (abstractControl instanceof UntypedFormGroup || abstractControl instanceof UntypedFormArray) {
        this.updateTreeValidity(abstractControl);
      } else {
        (abstractControl as AbstractControl).updateValueAndValidity({ emitEvent });
      }
    });
  }

  private static areGroupInputValuesEqual(group: UntypedFormGroup): boolean {
    const keys: string[] = Object.keys(group.controls);
    const keysLength = keys.length;

    if (!keysLength) {
      return true;
    }

    const initialControl = group.controls[keys[0]];

    for (let i = 1; i < keysLength; i++) {
      const currentKey = keys[i];

      if (initialControl.value !== group.controls[currentKey].value) {
        return false;
      }
    }

    return true;
  }
}
