import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IFormService, PhxConstants } from '../../../common/model';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '../../../common/ngx-strongly-typed-forms/model';
import { ICommissionRateDto, ICommissionRateRestriction, ITabDetails } from '../../commission-rate.interface';
import { Validators } from '@angular/forms';

@Injectable()
export class CommissionRateDetailFormService implements IFormService {

  formGroup: FormGroup<ITabDetails>;
  private isRootComponentDestroyed$: Subject<boolean>;

  get restrictionFormArray(): FormArray<ICommissionRateRestriction> {
    return this.formGroup.get('CommissionRateRestrictions') as FormArray<ICommissionRateRestriction>;
  }

  constructor(private fb: FormBuilder) { }

  createForm(commissionRate: ICommissionRateDto, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;
    const currentVersion = commissionRate.CurrentVersion;

    this.formGroup = this.fb.group<ITabDetails>({
      EffectiveDate: [currentVersion.EffectiveDate,
      [Validators.required]
      ],
      Description: [commissionRate.Description, [Validators.required]],
      ScheduledChangeRateApplicationId: [currentVersion.ScheduledChangeRateApplicationId, [Validators.required]],
      Percentage: [currentVersion.Percentage, [Validators.required]],
      CommissionRateRestrictions: this.createRestrictionFormArray(commissionRate.CommissionRateRestrictions, commissionRate.CommissionRoleId)
    });
    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(commissionRate: ICommissionRateDto, versionId: number): Partial<ICommissionRateDto> {
    const details: ITabDetails = this.formGroup.value;
    const index = commissionRate.CommissionRateVersions.findIndex(a => a.Id === Number(versionId));
    commissionRate.CommissionRateVersions[index].EffectiveDate = details.EffectiveDate;
    commissionRate.Description = details.Description;
    commissionRate.CommissionRateVersions[index].ScheduledChangeRateApplicationId = details.ScheduledChangeRateApplicationId;
    commissionRate.CommissionRateVersions[index].Percentage = details.Percentage;
    commissionRate.CommissionRateRestrictions = details.CommissionRateRestrictions;
    return commissionRate;
  }

  updateForm(commissionRate: ICommissionRateDto) {

    const currentVersion = commissionRate.CurrentVersion;

    this.formGroup.patchValue({
      EffectiveDate: currentVersion.EffectiveDate,
      Description: commissionRate.Description,
      ScheduledChangeRateApplicationId: currentVersion.ScheduledChangeRateApplicationId,
      Percentage: currentVersion.Percentage
    }, { emitEvent: false });

    this.updateRestrictionFormArray(commissionRate.CommissionRateRestrictions, commissionRate.CommissionRoleId);

  }

  private createRestrictionFormArray(items: Array<ICommissionRateRestriction>, commissionRoleId: PhxConstants.CommissionRole): FormArray<ICommissionRateRestriction> {
    const formArray = this.fb.array<ICommissionRateRestriction>(
      items.map((item: ICommissionRateRestriction) => this.createRestrictionFormGroup(item))
    );
    formArray.setValidators([this.validateRestrictionSelection(commissionRoleId)]);
    return formArray;
  }

  private createRestrictionFormGroup(commissionRateRestriction: ICommissionRateRestriction) {
    return this.fb.group<ICommissionRateRestriction>({
      Id: [commissionRateRestriction.Id ? commissionRateRestriction.Id : 0],
      CommissionRateHeaderId: [commissionRateRestriction.CommissionRateHeaderId],
      CommissionRateRestrictionTypeId: [commissionRateRestriction.CommissionRateRestrictionTypeId],
      IsInclusive: [commissionRateRestriction.IsInclusive],
      Name: [commissionRateRestriction.Name],
      OrganizationIdInternal: [commissionRateRestriction.OrganizationIdInternal],
      OrganizationIdClient: [commissionRateRestriction.OrganizationIdClient],
      LineOfBusinessId: [commissionRateRestriction.LineOfBusinessId],
      InternalOrganizationDefinition1Id: [commissionRateRestriction.InternalOrganizationDefinition1Id]
    });
  }

  private commissionRateRestrictionTypeExists(restrictions: ICommissionRateRestriction[], restrictionTypeId: PhxConstants.CommissionRateRestrictionType) {
    return restrictions.some(restriction => {
      return restriction.CommissionRateRestrictionTypeId === restrictionTypeId;
    });
  }

  private validateRestrictionSelection(commissionRoleId: PhxConstants.CommissionRole): ValidatorFn<ICommissionRateRestriction[]> {
    const CommissionRole = PhxConstants.CommissionRole;
    const RestrictionType = PhxConstants.CommissionRateRestrictionType;

    return (control: AbstractControl<ICommissionRateRestriction[]>): { [key: string]: any } => {
      let validationMessage: string = null;
      if (commissionRoleId === CommissionRole.NationalAccountsRole && !this.commissionRateRestrictionTypeExists(control.value, RestrictionType.ClientOrganization)) {
        validationMessage = 'Client restriction must be selected for a "National Accounts Role"';
      } else if (commissionRoleId === CommissionRole.BranchManagerRole && !this.commissionRateRestrictionTypeExists(control.value, RestrictionType.InternalOrganizationDefinition1)) {
        validationMessage = 'Branch restriction must be selected for a "Branch Manager Role"';
      }

      return validationMessage ? {
        restrictionsRequired: {
          message: validationMessage
        }
      }
        : null;
    };
  }

  updateRestrictionFormArray(items: Array<ICommissionRateRestriction>, commissionRoleId: PhxConstants.CommissionRole): void {
    const formArray: FormArray<ICommissionRateRestriction> = this.restrictionFormArray;

    if (formArray.length && items.length) {
      items.forEach((item, index) => {
        if (formArray.at(index)) {
          formArray.at(index).patchValue(item);
        } else {
          formArray.push(this.createRestrictionFormGroup(item));
        }
      });
      if (formArray.length > items.length) {
        this.clearArray(formArray, items.length);
      }
    } else if (items.length) {
      const array = this.createRestrictionFormArray(items, commissionRoleId);
      array.controls.forEach(group => {
        formArray.push(group);
      });
    } else {
      this.clearArray(formArray);
    }
  }

  private clearArray(formArray: FormArray<ICommissionRateRestriction>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
