<app-phx-dialog [config]="phxDialogComponentConfigModel"></app-phx-dialog>

<app-phx-modal title="Save New Template" #modalTemplate showCloseButton="true">
  <app-workorder-save-as-template [Templatemodal] = "modalTemplate" [workorderDetails] = "workOrderDetails">
  </app-workorder-save-as-template>
</app-phx-modal>

<app-phx-modal title="Unterminate Work Order" #phxModalComponent showCloseButton="true">
  <ng-container *ngIf="showPhxModal">
    <div style="line-height: 21px; color: #5E6E90;">
      {{phxDialogComponentConfigModel?.BodyMessage}}
    </div>
    <div class="col-lg-12" style="height:300px">
      <textarea style="width:100%;height:200px;margin-top:15px" name="ObjectComment"
        maxlength="{{phxDialogComponentConfigModel?.ObjectComment?.LengthMax}}"
        minlength="{{phxDialogComponentConfigModel?.ObjectComment?.LengthMin}}"
        required="{{phxDialogComponentConfigModel?.ObjectComment?.IsRequired}}"
        [(ngModel)]="phxDialogComponentConfigModel?.ObjectComment.Value"
        placeholder="{{phxDialogComponentConfigModel?.ObjectComment?.PlaceHolder}}"
        (focusout)="onCommentChange()"></textarea>
      <span class="help-block">{{phxDialogComponentConfigModel?.ObjectComment?.HelpBlock}}</span>
      <ng-container *ngIf="!isCommentValid">
        <ng-container *ngFor="let message of phxDialogComponentConfigModel?.CommentValidationMessage">
            <span style="color:rgb(171, 30, 30)" *ngIf="message.showMessage()">{{message.Message}}</span>
        </ng-container>
      </ng-container>
    </div>
    <div class="col-lg-12" style="text-align: right">
      <hr/>
      <button type="button" class="btn" *ngFor="let btn of phxDialogComponentConfigModel?.Buttons|orderBy:'SortOrder'" style="margin:5px"
        [ngClass]="btn.Class" (click)="onPhxModalButtonClick(btn)" [disabled]="isUnterminateBtnDisabled(btn)">{{btn.Name}}</button>
    </div>
  </ng-container>
</app-phx-modal>

<app-workorder-terminate-modal #TerminationModal (commandValidation)="terminateWorkorder($event)">

</app-workorder-terminate-modal>
