import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms';
import { IWorkorderSetup } from '../../models';
import { ValidationExtensions } from 'src/app/common';
import { Validators } from '@angular/forms';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Injectable()
export class AssignmentCreateSetupFormService implements IFormService {

  formGroup: FormGroup<IWorkorderSetup>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder
  ) {
  }

  createForm(workOrderSetup: IWorkorderSetup, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    this.formGroup = this.fb.group<IWorkorderSetup>({
      ImportFromAts: workOrderSetup.ImportFromAts,
      AtsPlacementId: workOrderSetup.AtsPlacementId,
      AtsSourceId: workOrderSetup.AtsSourceId,
      OrganizationIdClient: workOrderSetup.OrganizationIdClient,
      UserProfileIdWorker: workOrderSetup.UserProfileIdWorker,
      TemplateId: null,
      LineOfBusinessCode: PhxConstants.LineOfBusiness[PhxConstants.LineOfBusiness.SubVendorPayroll]
    });

    this.setupFormListeners();

    this.setValidators(this.formGroup);
    this.setDisabled(this.formGroup);

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
    this.observeImportFromAtsChange();
  }

  setValidators(formGroup: FormGroup<IWorkorderSetup>) {
    var config = this.onGetValidators(formGroup);

    for(const prop in formGroup.controls) {
      const validators = config[prop] ?? null;

      const formControl = formGroup.get(prop as any);
      if (formControl) {
        formControl.setValidators(validators);
      } else {
        console.warn(`Missing form control ${prop} in setValidators`);
      }
    }

    ValidationExtensions.updateTreeValidity(formGroup as any);
  }

  onGetValidators(formGroup: FormGroup<IWorkorderSetup>): ValidatorsConfig<IWorkorderSetup> {
    return {
      OrganizationIdClient: [Validators.required],
      UserProfileIdWorker: [Validators.required],
      LineOfBusinessCode: [Validators.required],
      AtsPlacementId: [Validators.required],
    };
  }

  setDisabled(formGroup: FormGroup<IWorkorderSetup>) {
    var config = this.onGetDisabled(formGroup);

    for(const prop in formGroup.controls) {
      const disable = config[prop] ?? false;
      const formControl = formGroup.get(prop as any);

      if (formControl) {
        if (disable != formControl.disabled) {
          if (disable) {
            formControl.disable();
          } else {
            formControl.enable();
          }
        }
      } else {
        console.warn(`Missing form control ${prop} in setDisabled`);
      }
    }

    ValidationExtensions.updateTreeValidity(formGroup as any);
  }

  onGetDisabled(formGroup: FormGroup<IWorkorderSetup>): DisabledConfig<IWorkorderSetup> {
    const config: DisabledConfig<IWorkorderSetup> = {};

    var importFromAts = formGroup.get("ImportFromAts").value;

    if (importFromAts) {
      config.OrganizationIdClient = true;
      config.UserProfileIdWorker = true;
      config.LineOfBusinessCode = true;
    } else {
      config.AtsSourceId = true;
      config.AtsPlacementId = true;
    }

    return config;
  }

  updateForm(data: IWorkorderSetup): void {
  }

  updateLineOfBusinessCodeFormControl(value: string | null) {
    this.formGroup.get("LineOfBusinessCode").setValue(value, { emitEvent: false });
  }

  updateTemplateIdFormControl(value: number | null) {
    this.formGroup.get("TemplateId").setValue(value, { emitEvent: false });
  }

  private observeImportFromAtsChange() {
    this.formGroup.get("ImportFromAts").valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.isRootComponentDestroyed$))
      .subscribe(() => {
        this.setDisabled(this.formGroup);
      });
  }
}
