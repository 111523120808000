import { Injectable } from '@angular/core';
import { from as observableFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, PhxConstants } from '../../common';
import { EntityList } from '../../common/model';
import { CommonDataService } from '../../common/services/commonData.service';
import { ICommissionUserProfileDto } from '../commission-rate.interface';
import { CommissionRateHeaderUsersCollection, ICommissionAdjustmentJobOwner, CommissionSalesPattern, IBatchRecord } from '../model';

declare const oreq: any;

@Injectable()
export class CommissionService {

  constructor(private apiService: ApiService, private commonDataService: CommonDataService) {
  }

  public getCommissionUserProfileListWithRatesOnly(oDataParams): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code'])
        .url();
    return this.apiService.query('Commission/getCommissionUserProfileListWithRatesOnly?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public getPendingInterestTotal(userProfileId): Promise<any> {
    return this.apiService.queryWithPromise('commission/PendingInterestTotal/' + userProfileId);
  }

  public getCommissionAdjustmentCurrencyForInternalOrganization(internalOrganizationId: number): Observable<any> {
    return this.apiService.query('commission/getCommissionAdjustmentCurrencyByInternalOrganization/' + internalOrganizationId);
  }

  public getCommissionReport(reportCommand) {
    return this.apiService.command('CommissionReport', reportCommand);
  }

  public finalizeCommissionReport(reportCommand: any) {
    // return this.apiService.command('CommissionReportFinalize', reportCommand, true);
    return this.apiService.command('CommissionTransactionFinalize', reportCommand, true);
  }

  public getCommissionTemplateById(commissionTemplateId: number): Observable<any> {
    return this.apiService.query(`template/${commissionTemplateId}`);
  }

  public getCommissionRateHeaderUsers(): Observable<CommissionRateHeaderUsersCollection> {
    return this.apiService.query<CommissionRateHeaderUsersCollection>('commission/getCommissionRateHeaderUsers');
  }

  public getCommissionAdjustmentJobOwners(): Observable<ICommissionAdjustmentJobOwner[]> {
    return this.apiService.query<ICommissionAdjustmentJobOwner[]>('commission/getCommissionAdjustmentJobOwners')
      .pipe(map(response => {
        response.map(r => r.FullName = r.FirstName + ' ' + r.LastName);
        return response;
      }));
  }

  public getSalesPattern(salesPatternId: number): Observable<CommissionSalesPattern> {
    return this.apiService.query<CommissionSalesPattern>(`commission/getSalesPattern/${salesPatternId}`);
  }

  public discardCommissionSalesPattern(command) {
    return this.apiService.command('CommissionDiscardSalesPattern', command, true);
  }

  public saveCommissionSalesPattern(saveCommand) {
    return this.apiService.command('CommissionSaveSalesPattern', saveCommand);
  }

  getListOrganizationInternal() {
    return observableFrom(this.commonDataService.getListOrganizationInternal());
  }

  getListOrganizationClient1(oDataParams?: any) {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withExpand(['OrganizationAddresses, OrganizationClientRoles'])
        .withSelect(['Id', 'DisplayName', 'OrganizationAddresses/IsPrimary',
          'OrganizationAddresses/SubdivisionId', 'OrganizationClientRoles/IsChargeSalesTax', 'OrganizationClientRoles/ClientSalesTaxDefaultId'])
        .url();
    return this.apiService.query('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole?' + oDataParams);
  }

  updateTemplate(command) {
    command.WorkflowPendingTaskId = -1;
    return observableFrom(this.apiService.command('UpdateTemplateBody', command));
  }

  getInternalUserProfileList(oDataParams?: any) {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['CommissionUserProfileId', 'CommissionUserProfileFirstName', 'CommissionUserProfileLastName', 'CommissionUserProfileStatusId'])
        .url();
    return this.apiService.query('Commission/getInternalUserProfileList?' + (oDataParams ? oDataParams + '&' : ''));
  }

  getCommissionHeaderById(commissionId: number, oDataParams?: any) {
    return this.apiService.query('commission/getCommissionHeaderById/' + commissionId + (oDataParams ? '?' + oDataParams : ''));
  }

  saveCommissionTransaction(saveCommand) {
    return observableFrom(this.apiService.command('CommissionAdjustmentNew', saveCommand));
  }

  activateRecurring(command) {
    return observableFrom(this.apiService.command('CommissionAdjustmentHeaderActivate', command));
  }

  deactivateRecurring(command) {
    return observableFrom(this.apiService.command('CommissionAdjustmentHeaderDeactivate', command));
  }

  getCommissionRateTemplates() {
    const filter = oreq.filter('StatusId').eq(PhxConstants.TemplateStatus.Active);
    const params = oreq
      .request()
      .withFilter(filter)
      .url();

    return this.apiService.query<EntityList<any>>('template/getCommissionRateTemplates?' + params);
  }

  public getCommissionUserProfileById(userProfileId: number) {
    const oDataParams = oreq.request()
      .withSelect([
        'CommissionUserProfileId',
        'CommissionContactId',
        'CommissionUserProfileStatusId',
        'CommissionUserProfileFirstName',
        'CommissionUserProfileLastName'
      ])
      .url();

    return this.apiService.query<EntityList<ICommissionUserProfileDto>>(`Commission/getCommissionUserProfileById/${userProfileId}?${oDataParams}`)
      .pipe(map(response => {
        return response?.Items?.length ? response.Items[0] : null;
      }));
  }

  getCommissionRateHeadersByCommissionUserProfile(commissionUserProfileId: number, oDataParams?: any) {
    return this.apiService.query('Commission/getCommissionUserProfileById/' + commissionUserProfileId + (oDataParams ? '?' + oDataParams : ''));
  }

  getTemplate(id: number) {
    return this.apiService.query('template/' + id);
  }

  public executeCommissionAdjustmentBatchCreate(commandName: string, records: IBatchRecord[], publicId: string, createResultFile: boolean, selectedClientOrganization: number) {
    const command = {
      BatchRecords: records,
      DocumentPublicId: publicId,
      CreateUnmatchedBatchFile: createResultFile,
      clientId : selectedClientOrganization
    };
    return observableFrom(this.apiService.command(commandName, command));
  }
}
