import { Injectable } from '@angular/core';
import { CodeValueGroups, CodeValueService, PhxConstants } from 'src/app/common';
import { IBillingInvoiceDto, IPaymentInvoiceDto, ITimesheetApproverDto, IWorkOrderTreeDto } from '../../models/work-order-dtos.interface';
import { 
  IRoot, 
  ITabCoreDetails, 
  ITabCoreCollaborators, 
  ITabCoreCommissions,
  ITabCoreJob,
  ITabTimeMaterialInvoice,
  ITimeSheetApprover,
  IBillingInvoice,
  ITabTimePaymentInfo,
  IPaymentInvoice
} from '../../models/work-order-form.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderFormMapperService {

  constructor(private codeValueService: CodeValueService) { }

  dtoToForm(dto: IWorkOrderTreeDto): IRoot {
    return {
      Id: null,
      TabCore: {
        Details: this.dtoToTabCoreDetails(dto),
        Collaborators: this.dtoToTabCoreCollaborators(dto),
        Commissions: this.dtoToTabCoreCommissions(dto),
        Job: this.dtoToTabCoreJob(dto)
      },
      TabParties: null,
      TabTimeMaterialInvoice: this.dtoToTabTimeMaterial(dto),
      TabExpenseInvoice: null,
      TabEarningsAndDeductions: null,
      TabTaxes: null,
      Footer: null,
      ClientSpecificFields: null
    };
  }

  formToDto(form: IRoot, dto: IWorkOrderTreeDto): IWorkOrderTreeDto {
    return {
      assignment: dto.assignment,
      workOrder: {
        ...dto.workOrder,
        startDate: form.TabCore.Details.StartDate,
        endDate: form.TabCore.Details.StartDate
      },
      workOrderVersion: {
        ...dto.workOrderVersion,
        effectiveDate: form.Footer.EffectiveDate,
        timesheetInfo: {
          ...dto.workOrderVersion.timesheetInfo,
          timesheetMethodologyCode: this.codeValueService.getCodeValue(form.TabTimeMaterialInvoice.TabTimeMaterialInvoiceDetail.TimeSheetMethodologyId, CodeValueGroups.TimeSheetMethodology)?.code,
          timesheetCycleCode: this.codeValueService.getCodeValue(form.TabTimeMaterialInvoice.TabTimeMaterialInvoiceDetail.TimeSheetCycleId, CodeValueGroups.TimeSheetCycle)?.code
        }
      }
    }
  }

  private dtoToTabCoreDetails(dto: IWorkOrderTreeDto): ITabCoreDetails {
    return {
      AtsPlacementId: dto.assignment.atsInfo.placementId,
      StartDate: dto.workOrder.startDate,
      EndDate: dto.workOrder.endDate,
      LineOfBusinessId: this.codeValueService.getCodeValueIdByCode(dto.workOrderVersion.lineOfBusinessCode, CodeValueGroups.LineOfBusiness),
      InternalOrganizationDefinition1Id: this.codeValueService.getCodeValueIdByCode(dto.workOrderVersion.internalOrganizationDefinition1Code, CodeValueGroups.InternalOrganizationDefinition1),
      WorksiteId: this.codeValueService.getCodeValueIdByCode(dto.workOrderVersion.worksiteCode, CodeValueGroups.Worksite),
      WorkerLocationId: this.codeValueService.getCodeValueIdByCode(dto.workOrderVersion.workerLocationSubdivisionCode, CodeValueGroups.Subdivision),
      HolidayScheduleNameId: dto.workOrderVersion.holidayScheduleNameId,
      OrganizationIdInternal: dto.assignment.internalOrganization?.organizationId,
      WorkOrderStartDateState: dto.workOrderVersion.workOrderChanges.startDate,
      WorkOrderEndDateState: dto.workOrderVersion.workOrderChanges.endDate,
      TerminationDate: dto.workOrder.terminationInfo?.terminationDate
    };
  }

  private dtoToTabCoreCollaborators(dto: IWorkOrderTreeDto): ITabCoreCollaborators {
    return {
      AssignedToUserProfileId: dto.workOrderVersion.assignedToUserProfile?.userProfileId
    };
  }

  private dtoToTabCoreCommissions(dto: IWorkOrderTreeDto): ITabCoreCommissions {
    return null;
  }

  private dtoToTabCoreJob(dto: IWorkOrderTreeDto): ITabCoreJob {
    return null;
  }

  private dtoToTabTimeMaterial(dto: IWorkOrderTreeDto): ITabTimeMaterialInvoice {
    const timeSheetInfo = dto.workOrderVersion.timesheetInfo;

    return {
      TabTimeMaterialInvoiceDetail: {
        TimeSheetMethodologyId: this.codeValueService.getCodeValueIdByCode(timeSheetInfo.timesheetMethodologyCode, CodeValueGroups.TimeSheetMethodology),
        TimeSheetCycleId: this.codeValueService.getCodeValueIdByCode(timeSheetInfo.timesheetCycleCode, CodeValueGroups.TimeSheetCycle),
        TimeSheetApprovers: timeSheetInfo.timesheetApprovers.map(x => this.dtoToTimeSheetApprover(x)),
        TimeSheetApprovalFlowId: this.codeValueService.getCodeValueIdByCode(timeSheetInfo.approvalFlowCode, CodeValueGroups.TimeSheetApprovalFlow),
        IsTimeSheetUsesProjects: timeSheetInfo.isUsesProjects,
        IsTimesheetProjectMandatory: timeSheetInfo.isProjectMandatory,
        TimeSheetDescription: timeSheetInfo.description,
        VmsWorkOrderReference: timeSheetInfo.thirdPartyWorkerReference,
        IsDisplayEstimatedInvoiceAmount: timeSheetInfo.isDisplayEstimatedInvoiceAmount,
        IsDisplayEstimatedPaymentAmount: timeSheetInfo.isDisplayEstimatedPaymentAmount,
      },
      TabTimeMaterialInvoiceBillingInfoes: [{
        BillingInvoice: dto.workOrderVersion.billingInfo.billingInvoices
          .filter(x => x.invoiceTypeCode == PhxConstants.InvoiceType[PhxConstants.InvoiceType.TimeSheet])
          .map(x => this.dtoToBillingInvoice(x))
          [0]
      }],
      TabTimeMaterialInvoicePaymentInfoes: dto.workOrderVersion.paymentInfoes
        .map<ITabTimePaymentInfo>(pi => {
          return {
            PaymentInvoice: pi.paymentInvoices
              .filter(x => x.invoiceTypeCode == PhxConstants.InvoiceType[PhxConstants.InvoiceType.TimeSheet])
              .map(x => this.dtoToPaymentInvoice(x))
              [0]
          };
        })
    };
  }

  dtoToBillingInvoice(dto: IBillingInvoiceDto): IBillingInvoice {
    return {
      InvoiceTypeId: this.codeValueService.getCodeValueIdByCode(dto.invoiceTypeCode, CodeValueGroups.InvoiceType),
      BillingConsolidationTypeId: this.codeValueService.getCodeValueIdByCode(dto.billingConsolidationTypeCode, CodeValueGroups.BillingConsolidationType),
      IsUsesAlternateBilling: dto.hasAlternateBilling,
      OrganizatonClientRoleAlternateBillingId: dto.organizatonClientRoleAlternateBillId,
      BillingFrequencyId: this.codeValueService.getCodeValueIdByCode(dto.billingFrequencyCode, CodeValueGroups.BillingFrequency),
      BillingInvoiceTermsId: this.codeValueService.getCodeValueIdByCode(dto.billingInvoiceTermsCode, CodeValueGroups.BillingInvoiceTerms),
      BillingInvoiceTemplateId: this.codeValueService.getCodeValueIdByCode(dto.billingInvoiceTemplateCode, CodeValueGroups.BillingInvoiceTemplate),
      BillingReferenceContactProfileId: dto.referenceContactUserProfile?.userProfileId,
      InvoiceNote1: dto.invoiceNote1,
      InvoiceNote2: dto.invoiceNote2,
      InvoiceNote3: dto.invoiceNote3,
      InvoiceNote4: dto.invoiceNote4,
      BillingInvoicePresentationStyleId: this.codeValueService.getCodeValueIdByCode(dto.billingInvoicePresentationStyleCode, CodeValueGroups.BillingInvoicePresentationStyle),
      BillingTransactionGenerationMethodId: this.codeValueService.getCodeValueIdByCode(dto.billingTransactionGenerationMethodCode, CodeValueGroups.BillingTransactionGenerationMethod),
      IsSalesTaxAppliedOnVmsImport: dto.isSalesTaxAppliedOnVmsImport,
      BillingRecipients: dto.billingRecipients.map(x => {
        return {
          RecipientTypeId: this.codeValueService.getCodeValueIdByCode(x.recipientTypeCode, CodeValueGroups.RecipientType),
          UserProfileId: x.userProfile?.userProfileId,
          DeliveryMethodId: this.codeValueService.getCodeValueIdByCode(x.deliveryMethodCode, CodeValueGroups.DeliveryMethod),
          FileExportFormatId: this.codeValueService.getCodeValueIdByCode(x.fileExportFormatCode, CodeValueGroups.FileExportFormat),
          DeliverToUserProfileId: x.deliverToUserProfile?.userProfileId
        };
      })
    };
  }

  dtoToPaymentInvoice(dto: IPaymentInvoiceDto): IPaymentInvoice {
    return {
      InvoiceTypeId: this.codeValueService.getCodeValueIdByCode(dto.invoiceTypeCode, CodeValueGroups.InvoiceType),
      PaymentInvoiceTermsId: this.codeValueService.getCodeValueIdByCode(dto.paymentInvoiceTermsCode, CodeValueGroups.PaymentInvoiceTerms),
      PaymentInvoiceTemplateId: this.codeValueService.getCodeValueIdByCode(dto.paymentInvoiceTemplateCode, CodeValueGroups.PaymentInvoiceTemplate),
      PaymentMethodId: this.codeValueService.getCodeValueIdByCode(dto.paymentMethodTypeCode, CodeValueGroups.PaymentMethodType),
      IsSalesTaxAppliedOnVmsImport: dto.isSalesTaxAppliedOnVmsImport,
      PaymentFrequency: dto.paymentFrequency,
      PaymentReleaseScheduleId: this.codeValueService.getCodeValueIdByCode(dto.paymentReleaseScheduleCode, CodeValueGroups.PaymentReleaseSchedule),
    };
  }

  private dtoToTimeSheetApprover(dto: ITimesheetApproverDto): ITimeSheetApprover {
    return {
      Sequence: dto.sequence,
      UserProfileId: dto.approverUserProfile?.userProfileId,
      MustApprove: true
    }
  }
}
