import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IValidationErrorResponse } from '../../model';

@Component({
  selector: 'app-phx-validation-failures',
  templateUrl: './phx-validation-failures.component.html',
  styleUrls: ['./phx-validation-failures.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxValidationFailuresComponent {

  @Input() validationErrorResponse: IValidationErrorResponse;

}
