import { Injectable } from '@angular/core';
import { from as observableFrom, Observable, Subject } from 'rxjs';
import { find } from 'lodash';

import { ApiService, CodeValueGroups, CodeValueService, CommonService, PhxConstants } from '../../common';
import {
  IAssignmentDto,
  IBillingInfo,
  IBillingSalesTax,
  IJobOwner,
  IPaymentInfo,
  IPaymentSalesTax,
  IWorkOrder,
  IWorkOrderCreateTransactionPreCheckDto,
  WorkOrderOffboardingStateHistory
} from '../models';
import { SalesTaxVersionRate } from '../../expense/model';
import { EntityList } from '../../common/model';
import { CommissionPickerInputDto, CommissionPickerSummaryDto } from '../../commission/model';
import { CommonListsObservableService } from '../../common/lists/lists.observable.service';
import { ICommissionChangeImpactSummary } from '../../commission/commission-change-impact/commission-change-impact.interface';
import { CommissionPickerService } from '../../commission/services';

@Injectable({
  providedIn: 'root'
})
export class WorkorderService {
  private navigationBar$ = new Subject<boolean>();
  private offboardingStatus$ = new Subject<boolean>();
  private refreshWorkOrderSubject = new Subject<any>();
  refreshWorkOrder$ = this.refreshWorkOrderSubject.asObservable();

  billingSalesTaxJurisdictions: Array<any>;
  paymentSalesTaxJurisdictions: Array<any> = [];

  constructor(
    private apiService: ApiService,
    private codeValueService: CodeValueService,
    private commonService: CommonService,
    private commonListsObservableService: CommonListsObservableService,
    private commissionPickerService: CommissionPickerService) {
  }

  public getWorkOrderCreateAdjustmentTransactionPreCheck(workOrderId: number): Observable<IWorkOrderCreateTransactionPreCheckDto> {
    return this.apiService.query<IWorkOrderCreateTransactionPreCheckDto>(`assignment/getWorkOrderCreateAdjustmentTransactionPreCheck/${workOrderId}`);
  }

  public updateNavigationBar() {
    this.navigationBar$.next(true);
  }

  public navigationBarChange$(): Observable<boolean> {
    return this.navigationBar$.asObservable();
  }

  public updateOffboardingStatus() {
    this.offboardingStatus$.next(true);
  }

  public offboardingStatusChange$(): Observable<boolean> {
    return this.offboardingStatus$.asObservable();
  }

  refreshWorkOrder(workorderId: number, assignmentId: number, versionId: number, templateId: number = null) {
    this.refreshWorkOrderSubject.next({
      workorderId,
      assignmentId,
      versionId,
      templateId
    });
  }

  getAssignment(assignmentId: number, workOrderId: number, workOrderVersionId: number) {
    return this.apiService.query<IAssignmentDto>(`assignment/getWorkOrderVersion/${assignmentId}/${workOrderId}/${workOrderVersionId}`);
  }

  getWorkOrderListByWorkerProfileId(userProfileId: number): Observable<EntityList<any>> {
    return this.apiService.query<EntityList<any>>(`assignment/getWorkOrderListByWorkerProfileId/${userProfileId}`);
  }

  getSalesPattern(Id: number) {
    const filterResult = oreq.filter('Id').eq(Id);
    const oDataParams = oreq
      .request()
      .withExpand(['CommissionSalesPatternSupporters'])
      .withSelect(['CommissionSalesPatternSupporters/UserProfileId', 'CommissionSalesPatternSupporters/CommissionRoleId', 'CommissionSalesPatternSupporters/FullName'])
      .withFilter(filterResult)
      .url();
    return this.apiService.query('commission/getAllSalesPatterns' + (oDataParams ? '?' + oDataParams : ''), false);
  }

  getWorkOrderVersionCommissionPicker(params: CommissionPickerInputDto) {
    const paramString = JSON.stringify(params);
    return this.apiService.httpGetRequest<Array<CommissionPickerSummaryDto>>(`assignment/getWorkOrderVersionCommissionPicker?workOrderCommissionPickerParams=${paramString}`, undefined, false);
  }

  getCommissionRateDisplayText(commissionRateHeaderId: number, commissionRateDescription: string, commissionRateHeaderStatusId: number) {
    return this.commissionPickerService.getCommissionRateDisplayText(commissionRateHeaderId, commissionRateDescription, commissionRateHeaderStatusId);
  }

  public getProfilesListOrganizationalByUserProfileType(organizationId: number, userProfileType: PhxConstants.UserProfileType = 0) {
    return this.commonListsObservableService.listUserProfileByTypeAndOrg$(userProfileType || 0, organizationId || 0, false, PhxConstants.ProfileStatus.Inactive);
  }

  public getProfilesListByOrganizationId(organizationId: number) {
    return this.getProfilesListOrganizationalByUserProfileType(organizationId);
  }

  public getProfilesListOrganizational(organizationId: number) {
    return this.getProfilesListOrganizationalByUserProfileType(organizationId, PhxConstants.UserProfileType.Organizational);
  }

  public clientRoleChargeSalesTax(organizationId) {
    return this.apiService.query('org/clientRoleChargeSalesTax/' + organizationId, false);
  }

  public getPaymentReleaseScheduleDetail(paymentReleaseScheduleId, tableState) {
    const oDataParams = this.commonService.generateRequestObject(tableState).url();
    return this.apiService.query('Payment/getPaymentReleaseScheduleDetail/' + paymentReleaseScheduleId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getSalesTaxVersionRatesBySubdivisionAndOrganization(subdivisionIdSalesTax, organizationId) {
    if (subdivisionIdSalesTax && subdivisionIdSalesTax > 0 && organizationId && organizationId > 0) {
      const oDataParams = oreq
        .request()
        .withExpand(['SalesTaxJurisdictions'])
        .withSelect(['Id', 'SalesTaxId', 'DisplayName', 'RatePercentage', 'IsApplied', 'HasNumberAssigned', 'HasJurisdictions', 'SalesTaxJurisdictions/*'])
        .url();
      return this.apiService.query<EntityList<any>>(
        'SalesTaxVersionRate/getSalesTaxVersionRatesBySubdivisionAndOrganization/subdivision/' + subdivisionIdSalesTax + '/Organization/' + organizationId + (oDataParams ? '?' + oDataParams : ''),
        false);
    }
  }

  public getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId): Observable<any> {
    return this.apiService.query('org/getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization/' + organizationId);
  }

  getWCBCodesBySubdivisionId(subdivisionId, organizationIdInternal, oDataParams?: any) {
    return this.apiService.query('Payroll/getWCBCodesBySubdivisionId/' + subdivisionId + '/' + organizationIdInternal + '/' + (oDataParams ? '?' + oDataParams : ''));
  }

  getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId(subdivisionId, oDataParams) {
    return this.apiService.query<EntityList<any>>('Payroll/getActiveCurrentlyEffectiveProvincialTaxVersionTaxTypeBySubdivisionId/' + subdivisionId + '/' + (oDataParams ? '?' + oDataParams : ''));
  }

  getWorkOrderPurchaseOrderLinesByWorkOrderId(workOrderId, oDataParams = null) {
    return this.apiService.query('purchaseorder/getWorkOrderPurchaseOrderLinesByWorkOrderId/' + workOrderId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getPurchaseOrderLineByOrganizationIdClientAndWorkOrderId(organizationIdClient, workOrderId, oDataParams) {
    return this.apiService.query('purchaseorder/getPurchaseOrderLineByOrganizationIdClientAndWorkOrderId/' + organizationIdClient + '/' + workOrderId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getByPurchaseOrderLineId(purchaseOrderLineId: number, oDataParams: any): Observable<any> {
    return this.apiService.query('purchaseorder/getByPurchaseOrderLineId/' + purchaseOrderLineId + (oDataParams ? '?' + oDataParams : ''));
  }

  public getByPurchaseOrderId(purchaseOrderId: number): Observable<any> {
    return this.apiService.query('purchaseorder?id=' + purchaseOrderId);
  }

  public workOrderPurchaseOrderLineStatusToActivate(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineStatusToActivate', command));
  }

  public workOrderPurchaseOrderLineDelete(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineDelete', command));
  }

  public PONewlineSave(command) {
    return observableFrom(this.apiService.command('WorkOrderPurchaseOrderLineCreate', command));
  }

  public updateTemplateBody(command) {
    command.WorkflowPendingTaskId = -1;
    return observableFrom(this.apiService.command('UpdateTemplateBody', command));
  }

  public getApplicationConfigurationByTypeId(typeid, oDataParams) {
    return this.apiService.query('config/getApplicationConfigurationByTypeId/' + typeid + (oDataParams ? '?' + oDataParams : ''));
  }

  getActiveCurrentlyEffectiveFederalTaxVersionBySubdivisionId(subdivisionId, oDataParams) {
    return this.apiService.query('Payroll/getActiveCurrentlyEffectiveFederalTaxVersionBySubdivisionId/' + subdivisionId + (oDataParams ? '?' + oDataParams : ''));
  }

  getAts(atsSourceId, atsPlacementId) {
    return this.apiService.query('assignment/getAts/atsSourceId/' + atsSourceId + '/atsPlacementId/' + atsPlacementId);
  }

  getTemplatesByEntityTypeId(entityTypeId): Observable<any> {
    const filter = oreq.filter('StatusId').eq(1);
    const params = oreq
      .request()
      .withFilter(filter)
      .url();
    return this.apiService.query('template/getTemplatesByEntityTypeId/' + entityTypeId + '?' + params);
  }

  workOrderNew(command) {
    return observableFrom(this.apiService.command('AssignmentCreateState', command));
  }

  getWorkOrderOffboardingStateHistory(workOrderId, showLoader: boolean = true): Promise<WorkOrderOffboardingStateHistory> {
    return this.apiService.query<WorkOrderOffboardingStateHistory>(`state/getWorkOrderOffboardingStateHistory/${workOrderId}`, showLoader).toPromise();
  }

  getDuplicateAtsWorkOrder(atsSourceId, atsPlacementId) {
    const oDataQuery = oreq
      .request()
      .withSelect(['WorkOrderFullNumber', 'WorkerName', 'StartDate', 'EndDate', 'WorkOrderStatus', 'AssignmentId', 'WorkOrderId', 'WorkOrderVersionId'])
      .withFilter(
        oreq
          .filter('AtsSourceId')
          .eq(atsSourceId)
          .and()
          .filter('AtsPlacementId')
          .eq(atsPlacementId)
          .and()
          .filter('WorkOrderStatusId')
          .ne(PhxConstants.WorkOrderStatus.Cancelled)
      )
      .url();
    return this.apiService.query('assignment/getSearch?' + oDataQuery);
  }

  public templateNew(command) {
    command.WorkflowPendingTaskId = -1;
    return observableFrom(this.apiService.command('TemplateNew', command));
  }

  getTerminationTypeAndReason(userProfileTypeId: number): Observable<any> {
    return this.apiService.query('assignment/getTerminationTypesAndReasons/' + userProfileTypeId);
  }

  public get(id) {
    if (id && !isNaN(id)) {
      return this.apiService.query('template/' + id);
    } else {
      return this.apiService.query('template');
    }
  }

  getWorker(assignment, listUserProfileWorker) {
    let worker = null;
    if (assignment.UserProfileIdWorker > 0) {
      worker = find(listUserProfileWorker, w => w.Id === assignment.UserProfileIdWorker);
      if (typeof worker !== 'undefined') {
        assignment.workerProfileTypeId = worker.ProfileTypeId;
        assignment.workerContactId = worker.ContactId;
      } else {
        assignment.workerProfileTypeId = null;
        worker = null;
      }
    } else {
      assignment.workerProfileTypeId = null;
      assignment.workerContactId = null;
    }
    return worker;
  }

  public getSubdivisionIdByWorksiteId(worksiteId: number) {
    return worksiteId ? this.codeValueService.getParentId(CodeValueGroups.Worksite, worksiteId) : null;
  }

  public getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker(subdivisionIdSalesTax: number, profileId: number) {
    const oDataParams = oreq
      .request()
      .withExpand('SalesTaxJurisdictions')
      .withSelect(['Id', 'SalesTaxId', 'DisplayName', 'RatePercentage', 'IsApplied', 'HasNumberAssigned', 'HasJurisdictions', 'SalesTaxJurisdictions/*'])
      .url();
    return this.apiService.query(
      'SalesTaxVersionRate/getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker/Subdivision/' + subdivisionIdSalesTax + '/Profile/' + profileId + (oDataParams ? '?' + oDataParams : ''),
      false);
  }

  async getPaymentSalesTaxes(paymentInfo: IPaymentInfo, index: number, userProfileIdWorker: number, workerProfileTypeId: number): Promise<Array<IPaymentSalesTax>> {
    const paymentSalesTaxes: Array<IPaymentSalesTax> = [];
    const subdivisionIdSalesTax = paymentInfo ? paymentInfo.SubdivisionIdSalesTax : null;
    const organizationIdSupplier = paymentInfo ? paymentInfo.OrganizationIdSupplier : null;
    if (subdivisionIdSalesTax) {
      let responseSalesTaxVersionRates: any;
      if (organizationIdSupplier) {
        responseSalesTaxVersionRates = await this.getSalesTaxVersionRatesBySubdivisionAndOrganization(subdivisionIdSalesTax, organizationIdSupplier).toPromise();
      } else if (userProfileIdWorker && workerProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianSp) {
        responseSalesTaxVersionRates = await this.getSalesTaxVersionRatesBySubdivisionAndUserProfileWorker(subdivisionIdSalesTax, userProfileIdWorker).toPromise();
      }
      const salesTaxVersionRates: Array<SalesTaxVersionRate> = (responseSalesTaxVersionRates ? responseSalesTaxVersionRates.Items : null) || [];

      if (paymentInfo.JurisdictionId) {
        const jurisdiction = salesTaxVersionRates.filter(st => st.HasJurisdictions).map(st => st.SalesTaxJurisdictions.find(j => j.JurisdictionId === paymentInfo.JurisdictionId))[0];
        if (jurisdiction.IsApplied) {
          const versionRate = salesTaxVersionRates.find(st => st.Id === jurisdiction.SalesTaxVersionRateId);
          paymentSalesTaxes.push({
            Id: versionRate.Id,
            SalesTaxId: versionRate.SalesTaxId,
            DisplayName: versionRate.DisplayName + ' - ' + jurisdiction.DisplayName,
            IsApplied: versionRate.HasNumberAssigned,
            ratePercentage: jurisdiction.RatePercentage,
            hasNumber: versionRate.HasNumberAssigned
          } as IPaymentSalesTax);
        }
      }

      let paymentJurisdictions = [];
      salesTaxVersionRates.forEach((rate: SalesTaxVersionRate) => {
        if (rate.HasJurisdictions && rate.SalesTaxJurisdictions) {
          paymentJurisdictions = paymentJurisdictions.concat(rate.SalesTaxJurisdictions);
        }
        if (!paymentInfo.JurisdictionId) {
          paymentSalesTaxes.push({
            Id: rate.Id,
            SalesTaxId: rate.SalesTaxId,
            DisplayName: rate.DisplayName,
            IsApplied: rate.HasNumberAssigned,
            ratePercentage: rate.RatePercentage,
            hasNumber: rate.HasNumberAssigned
          } as IPaymentSalesTax);
        }
      });
      this.paymentSalesTaxJurisdictions[index] = paymentJurisdictions;
    }
    return paymentSalesTaxes;
  }
  
  async getBillingSalesTaxes(billingInfo: IBillingInfo, organizationIdInternal: number): Promise<Array<IBillingSalesTax>> {
    const promises = [];
    // Get:
    // - Billing Sales Taxes for Internal Organization
    // - Client organization charges sales tax  
    if (billingInfo.SubdivisionIdSalesTax && billingInfo.OrganizationIdClient && organizationIdInternal) {
      promises.push(this.getSalesTaxVersionRatesBySubdivisionAndOrganization(billingInfo.SubdivisionIdSalesTax, organizationIdInternal).toPromise());
      promises.push(this.clientRoleChargeSalesTax(billingInfo.OrganizationIdClient).toPromise());
    }

    const data = await Promise.all(promises);
    const responseSalesTaxVersionRates = data[0];
    const isApplied = data[1] ?? true;

    const billingSalesTaxes: Array<IBillingSalesTax> = [];
    const salesTaxVersionRates: Array<SalesTaxVersionRate> = responseSalesTaxVersionRates?.Items ?? [];

    if (billingInfo.JurisdictionId) {
      // Restrict to sales taxes in selected Jurisdiction, use Jurisdiction rate and show Jurisdiction in display name
      const jurisdiction = salesTaxVersionRates
        .filter(salesTax => salesTax.HasJurisdictions)
        .flatMap(salesTax => salesTax.SalesTaxJurisdictions)
        .find(salesTaxJurisdiction => salesTaxJurisdiction.JurisdictionId === billingInfo.JurisdictionId);

      if (jurisdiction.IsApplied) {
        const rate = salesTaxVersionRates.find(st => st.Id === jurisdiction.SalesTaxVersionRateId);
        billingSalesTaxes.push({
          Id: rate.Id,
          SalesTaxId: rate.SalesTaxId,
          DisplayName: rate.DisplayName + ' - ' + jurisdiction.DisplayName,
          IsApplied: isApplied && rate.HasNumberAssigned,
          ratePercentage: jurisdiction.RatePercentage,
          hasNumber: rate.HasNumberAssigned
        } as IBillingSalesTax);
      }
    }

    this.billingSalesTaxJurisdictions = [];
    salesTaxVersionRates.forEach((rate: SalesTaxVersionRate) => {
      if (rate.HasJurisdictions && rate.SalesTaxJurisdictions) {
        this.billingSalesTaxJurisdictions = this.billingSalesTaxJurisdictions.concat(rate.SalesTaxJurisdictions);
      }
      // else, add all sales taxes in internal organization
      if (!billingInfo.JurisdictionId) {
        billingSalesTaxes.push({
          Id: rate.Id,
          SalesTaxId: rate.SalesTaxId,
          DisplayName: rate.DisplayName,
          IsApplied: isApplied && rate.HasNumberAssigned,
          ratePercentage: rate.RatePercentage,
          hasNumber: rate.HasNumberAssigned
        } as IBillingSalesTax);
      }
    });

    return billingSalesTaxes;
  }

  getWorkOrderTerminationComment(EntityTypeId: number, EntityId: number, showLoader: boolean = true): Promise<string> {
    return this.apiService.query<string>(`assignment/getWorkOrderTerminationComment/EntityTypeId/${EntityTypeId}/EntityId/${EntityId}`, showLoader).toPromise();
  }

  getCommissionChangeImpactForWov(wovId: number): Promise<ICommissionChangeImpactSummary> {
    return this.apiService.query<ICommissionChangeImpactSummary>('commission/getCommissionChangeImpact/' + wovId).toPromise();
  }

  getApprovedCommissionChangeImpactForWov(wovId: number): Promise<ICommissionChangeImpactSummary> {
    const entityTypeId = PhxConstants.EntityType.WorkOrderVersion;
    return this.apiService.query<ICommissionChangeImpactSummary>(`commission/getApprovedCommissionChangeImpact/EntityTypeId/${entityTypeId}/EntityId/${wovId}`).toPromise();
  }

  public isWorkOrderVersionCommissionsChanged(wovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[], workOrder: IWorkOrder): boolean {
    if (workOrder?.StatusId === PhxConstants.WorkOrderStatus.Processing) {
      return false;
    }
    if (originalWovCommissions?.length === 0) {
      return false;
    }

    if (!this.areWorkOrderVersionCommissionProfilesEqual(wovCommissions, originalWovCommissions)) {
      return true;
    }
    return !this.areWorkOrderVersionCommissionRateHeadersEqual(wovCommissions, originalWovCommissions);
  }

  public getIsRecruitersAllowed(lob: PhxConstants.LineOfBusiness) {
    return lob === PhxConstants.LineOfBusiness.Regular || lob === PhxConstants.LineOfBusiness.SubVendorPlacement;
  }

  public getAtsExemptOrganizationIdClients() {
    return this.apiService.query<Array<number>>('assignment/getAtsExemptOrganizationIdClients');
  }

  private areWorkOrderVersionCommissionProfilesEqual(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    if (this.areWovCommissionsSameLength(newWovCommissions, originalWovCommissions)) {
      const result = newWovCommissions.filter(obj1 => {
        return !originalWovCommissions.some(obj2 => {
          return obj1.UserProfileIdSales === obj2.UserProfileIdSales && obj1.CommissionRoleId === obj2.CommissionRoleId;
        });
      });
      return result.length <= 0;
    }
    return false;
  }

  private areWorkOrderVersionCommissionRateHeadersEqual(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    if (this.areWovCommissionsSameLength(newWovCommissions, originalWovCommissions)) {
      const result = newWovCommissions.filter(obj1 => {
        return !originalWovCommissions.some(obj2 => {
          return obj1.CommissionRateHeaderId === obj2.CommissionRateHeaderId;
        });
      });
      return result.length <= 0;
    }
    return false;
  }

  private areWovCommissionsSameLength(newWovCommissions: IJobOwner[], originalWovCommissions: IJobOwner[]): boolean {
    return newWovCommissions && originalWovCommissions && newWovCommissions.length === originalWovCommissions.length;
  }
}
