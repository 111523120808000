<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title">{{modalTitle}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="dialogRef.close()">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="modal-body">
        <app-phx-validation-failures class="validation-warnings"
            *ngFor="let validation of softValidations"
            [validationErrorResponse]="validation">
        </app-phx-validation-failures>
        <ng-container *ngFor="let field of roeFields; let i = index">
            <div class="roe-field">
                <div class="field-label">{{field.label}}</div>
                
                <!-- Single Value Fields -->
                <div class="d-flex align-items-center" *ngIf="!field.subFields">
                    <span class="field-value">{{field.value}}</span>
                    <i class="material-icons copy-icon" 
                        [class.copied]="copiedFields[i]"
                        (click)="copyToClipboard(field.value, i, field.label)"
                        (keydown.enter)="copyToClipboard(field.value, i, field.label)"
                    >
                        content_copy
                    </i>
                </div>
                
                <!-- Sub Fields -->
                <ng-container *ngIf="field.subFields">
                    <div [class]="field.subFields.length > 6 ? 'roe-sub-fields-grid' : 'roe-sub-fields-list'">
                        <div class="roe-sub-field" *ngFor="let subField of field.subFields; let j = index">
                            <div class="sub-field-label">{{subField.label}}:</div>
                            <span class="sub-field-value">{{subField.value}}</span>
                            <i class="material-icons copy-icon" 
                                [class.copied]="copiedFields[i + '_' + j]"
                                (click)="copyToClipboard(subField.value, i + '_' + j, field.label + ' - ' + subField.label)"
                                (keydown.enter)="copyToClipboard(subField.value, i + '_' + j, field.label + ' - ' + subField.label)"
                            >
                                content_copy
                            </i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div class="roe-field">
            <div class="field-label">
                ROE Detailed View
            </div>
        </div>
        <div class="table-wrapper">
            <table class="detailed-table">
                <thead>
                        <th>Work Order #</th>
                        <th>Transaction #</th>
                        <th>Type</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Days</th>
                        <th>Period</th>
                        <th>Hours</th>
                        <th>Amount</th>
                </thead>
                <tbody>
                    <tr *ngFor="let row of detailedRows" [ngClass]="{'missing-period-row': row.isMissingPeriod}">
                        <td>{{row.workOrderFullNumber}}</td>
                        <td>{{row.transactionNumber}}</td>
                        <td>{{row.transactionType}}</td>
                        <td>{{row.transactionFromDate}}</td>
                        <td>{{row.transactionToDate}}</td>
                        <td>{{row.dayCount}}</td>
                        <td>{{row.periodCount}}</td>
                        <td>{{row.totalHours}}</td>
                        <td class="text-right">{{row.grossAmount | currency}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <div class="feedback-container">
            <span class="feedback-text">Rate your experience</span>
            <div class="feedback-buttons">
                <button class="btn btn-link" (click)="onFeedbackClick(true)" aria-label="Thumbs up">
                    <i class="material-icons" [class.selected]="feedbackValue === true">thumb_up</i>
                </button>
                <button class="btn btn-link" (click)="onFeedbackClick(false)" aria-label="Thumbs down">
                    <i class="material-icons" [class.selected]="feedbackValue === false">thumb_down</i>
                </button>
            </div>
        </div>
        <button type="button" class="btn btn-default" (click)="dialogRef.close()">Close</button>
    </div>
</div>
  