import { PhxConstants } from '@common/model';

import { Theme } from '../models/theme.model';

export const PROCOM_THEME: Theme = {
  tenantCode: PhxConstants.Tenant.PROCOM,
  tenantLogo: '/assets/logos/logo-flex-backoffice.png',
  attributes: [
    {
      value: '#354051',
      variableName: 'side-nav-text-hover'
    },
    {
      value: '#4F5C70',
      variableName: 'logo-background'
    },
    {
      value: '#f8b91e',
      variableName: 'accent-color'
    },
    {
      value: 'url("/assets/images/todo-list-check-procom.svg")',
      variableName: 'todo-list-check'
    },
    {
      value: 'url("/assets/timeline/status_current.svg")',
      variableName: 'timeline-current-icon'
    },
    {
      value: 'url("/assets/timeline/status_done.svg")',
      variableName: 'timeline-done-icon'
    }
  ]
};
