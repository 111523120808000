import { Injectable } from '@angular/core';
import { PhxConstants, CodeValueService, CodeValueGroups } from '../../common';

@Injectable({
  providedIn: 'root'
})
export class CommissionPickerService {

  constructor(private codeValueService: CodeValueService) { }

  getCommissionRateDisplayText(commissionRateHeaderId: number, commissionRateDescription: string, commissionRateHeaderStatusId: number) {
    const showStatus = [
      PhxConstants.CommissionRateHeaderStatus.InActive,
      PhxConstants.CommissionRateHeaderStatus.Grandfathered
    ];

    let displayText = commissionRateHeaderId && `${commissionRateHeaderId} - ${commissionRateDescription}`;
    if (showStatus.includes(commissionRateHeaderStatusId)) {
      displayText += ` (${this.codeValueService.getCodeValueText(commissionRateHeaderStatusId, CodeValueGroups.CommissionRateHeaderStatus)})`;
    }

    return displayText;
  }

}
