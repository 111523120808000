import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export abstract class AzureScopes {
  static readonly UserRead = 'user.read';
  static readonly WebApi = 'api://0b6dd18e-5f4a-4c5b-81bb-6cd87de2fcf1/FBO.Auth';
}

export const MS_GRAPH_URL = 'https://graph.microsoft.com/v1.0/';
export const MS_GRAPH_USER_PROFILE_URL = MS_GRAPH_URL + 'me';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '0b6dd18e-5f4a-4c5b-81bb-6cd87de2fcf1',
      authority: 'https://login.microsoftonline.com/8d195b37-4c12-4f37-b356-c0d6a39b46a1',
      redirectUri: environment.activeDirectoryRedirectUri,
      postLogoutRedirectUri: environment.activeDirectoryPostLogoutRedirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(MS_GRAPH_USER_PROFILE_URL, [AzureScopes.UserRead]);
  protectedResourceMap.set(environment.apiUrl, [AzureScopes.WebApi]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [AzureScopes.UserRead, AzureScopes.WebApi]
    },
  };
}
