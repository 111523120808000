import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { IOtherEarnings, IPaymentOtherEarning } from '../../models/work-order-form.interface';

@Injectable()
export class OtherEarningsFormService implements IFormService {

  formGroup: FormGroup<IOtherEarnings>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder
  ) { }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<IOtherEarnings> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const paymentInfo = workorder.WorkOrderVersion.PaymentInfoes[0];

    this.formGroup = this.fb.group<IOtherEarnings>({
      PaymentOtherEarnings: this.createPaymentOtherEarningFormArray(paymentInfo.PaymentOtherEarnings)
    });

    this.updateValidatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const paymentOtherEarnings = this.formGroup.value.PaymentOtherEarnings;

    // Only applied to first payment info
    const paymentInfoDto = workOrder.WorkOrderVersion.PaymentInfoes[0];

    // TODO: do foreach to clear the other payment infos other earnings?
    workOrder.WorkOrderVersion.PaymentInfoes[0] = {
      ...paymentInfoDto,
      ...{
        PaymentOtherEarnings: paymentOtherEarnings.map((paymentOtherEarning, index) => {
          const paymentOtherEarningDto = paymentInfoDto.PaymentOtherEarnings[index]; // TODO: match by Type? (though only 1 for now)

          return {
            ...paymentOtherEarningDto,
            ...{
              PaymentOtherEarningTypeId: paymentOtherEarning.PaymentOtherEarningTypeId,
              IsApplied: paymentOtherEarning.IsApplied,
              IsAccrued: paymentOtherEarning.IsAccrued,
              RatePercentage: paymentOtherEarning.RatePercentage,
              UseWorkerProfileVacationSetup: paymentOtherEarning.UseWorkerProfileVacationSetup
            }
          };
        })
      }
    }

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {

    const paymentInfo = workorder.WorkOrderVersion.PaymentInfoes[0];
    
    this.formGroup.patchValue({
    }, { emitEvent: false });

    const paymentOtherEarningsFormArray = this.formGroup.get('PaymentOtherEarnings') as FormArray<IPaymentOtherEarning>;
    this.updatePaymentOtherEarningFormArray(paymentOtherEarningsFormArray, paymentInfo.PaymentOtherEarnings);

    this.updateValidatorsAndDisabled();
  }

  private createPaymentOtherEarningFormArray(paymentOtherEarnings: IPaymentOtherEarning[]) {
    return this.fb.array<IPaymentOtherEarning>(
      paymentOtherEarnings.map((paymentOtherEarning: IPaymentOtherEarning, i) => this.createPaymentOtherEarningFormGroup(paymentOtherEarning))
    );
  }

  private updateValidatorsAndDisabled() {
    const paymentOtherEarnings = this.formGroup.get("PaymentOtherEarnings") as FormArray<IPaymentOtherEarning>;

    paymentOtherEarnings.controls.forEach(otherEarning => {
      this.updatePaymentOtherEarningValidatorsAndDisabled(otherEarning as FormGroup<IPaymentOtherEarning>);
    });
  }

  updatePaymentOtherEarningValidatorsAndDisabled(formGroup: FormGroup<IPaymentOtherEarning>) {
    FormServiceHelper.setValidators(formGroup, this.onGetPaymentOtherEarningValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetPaymentOtherEarningDisabled(formGroup));
  }

  private onGetPaymentOtherEarningValidators(formGroup: FormGroup<IPaymentOtherEarning>): ValidatorsConfig<IPaymentOtherEarning> {
    return {
      IsApplied: [Validators.required],
      IsAccrued: [Validators.required],
      RatePercentage: [Validators.required],
    };
  }

  private onGetPaymentOtherEarningDisabled(formGroup: FormGroup<IPaymentOtherEarning>): DisabledConfig<IPaymentOtherEarning> {

    const isApplied = formGroup.get("IsApplied").value;
    const otherEarningTypeId = formGroup.get("PaymentOtherEarningTypeId").value;

    return {
      IsAccrued: !(isApplied && otherEarningTypeId === PhxConstants.PaymentOtherEarningType.VacationPay),
      RatePercentage: !isApplied
    };
  }

  private createPaymentOtherEarningFormGroup(paymentOtherEarning: IPaymentOtherEarning) {
    return this.fb.group<IPaymentOtherEarning>({
      PaymentOtherEarningTypeId: paymentOtherEarning.PaymentOtherEarningTypeId,
      UseWorkerProfileVacationSetup: paymentOtherEarning.UseWorkerProfileVacationSetup,
      IsApplied: paymentOtherEarning.IsApplied,
      IsAccrued: paymentOtherEarning.IsAccrued,
      RatePercentage: paymentOtherEarning.RatePercentage,
    });
  }

  private updatePaymentOtherEarningFormArray(
    formArray: FormArray<IPaymentOtherEarning>,
    paymentOtherEarnings: IPaymentOtherEarning[]
  ) {
    if (formArray.length && paymentOtherEarnings.length) {
      paymentOtherEarnings.forEach((item, index) => {
        const formGroup = formArray.at(index) as FormGroup<IPaymentOtherEarning>;
        if (formGroup) {
          this.updatePaymentOtherEarningFormGroup(formGroup, item);
        } else {
          formArray.push(this.createPaymentOtherEarningFormGroup(item));
        }
      });
      if (formArray.length > paymentOtherEarnings.length) {
        this.clearArray(formArray, paymentOtherEarnings.length);
      }
    } else if (paymentOtherEarnings.length) {
      const array = this.createPaymentOtherEarningFormArray(paymentOtherEarnings);
      array.controls.forEach(group => formArray.push(group));
    } else {
      this.clearArray(formArray);
    }
  }

  private updatePaymentOtherEarningFormGroup(
    formGroup: FormGroup<IPaymentOtherEarning>,
    paymentOtherEarning: IPaymentOtherEarning
  ) {
    formGroup.patchValue({
      PaymentOtherEarningTypeId: paymentOtherEarning.PaymentOtherEarningTypeId,
      UseWorkerProfileVacationSetup: paymentOtherEarning.UseWorkerProfileVacationSetup,
      IsApplied: paymentOtherEarning.IsApplied,
      IsAccrued: paymentOtherEarning.IsAccrued,
      RatePercentage: paymentOtherEarning.RatePercentage,
    }, { emitEvent: false });
  }

  private clearArray(formArray: FormArray<IOtherEarnings | IPaymentOtherEarning>, count = 0) {
    while (formArray.length !== count && count < formArray.length) {
      formArray.removeAt(count);
    }
  }

}
