import { AbstractControl, ValidatorFn, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PhxConstants } from '../model/phx-constants';
import { ValidationExtensions } from '../components/phx-form-control/validation.extensions';

export class CustomValidators {
    static required(control: AbstractControl): { [key: string]: any } {
        return control.value === undefined || control.value === null || (control.value.trim() === '') ?
            { 'required': true } :
            null;
    }

    static requiredCharLength(minLengthOfChars: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const res = control.value === undefined || control.value === null || (control.value.trim() === '');
            if (res) {
                return { 'required': true };
            } else {
                return control.value.length < minLengthOfChars ? { 'required': true } : null;
            }
        };
    }

    static requiredAtLeastOne(requiredField: string, message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            const array = control as UntypedFormArray;

            for (let index = 0; index < array.length; index++) {
                const formGroup = array.at(index) as UntypedFormGroup;
                const rawValue = formGroup.getRawValue();
                if (rawValue[requiredField] === true) {
                    return null;
                }
            }

            return {
                required: {
                    message
                }
            };
        };
    }
    /**
     * Validate an email
     */
    static email(control: AbstractControl): { [key: string]: boolean } {
        if (PhxConstants.Regex.Email.test(control.value)) {
            return null;
        } else {
            return { email: true };
        }
    }

    /**
     * Validate a postal code based on country id.
     * Supported countries include: Canada, USA, Mexico, Germany
     * @param countryId Id of country
     */
    static postalCode(countryId: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (countryId === PhxConstants.Country.CA) {
                if (!PhxConstants.Regex.CountryCanadaPostalCode.test(control.value)) {
                    return { 'required': true };
                }
            } else if (countryId === PhxConstants.Country.US) {
                if (!PhxConstants.Regex.CountryUsaPostalCode.test(control.value)) {
                    return { 'required': true };
                }
            } else if (countryId === PhxConstants.Country.MX) {
                if (!PhxConstants.Regex.CountryMexicoPostalCode.test(control.value)) {
                    return { 'required': true };
                }
            } else if (countryId === PhxConstants.Country.DE) {
                if (!PhxConstants.Regex.CountryGermanyPostalCode.test(control.value)) {
                    return { 'required': true };
                }
            } else {
                return null;
            }
        }
    }
    
    /**
     * @param phone Phone Number
     * @param phoneCode is country code of the phone number
     */
    static phone(phone: string, phoneCode: string, required: boolean = false): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const phoneNumber = (phoneCode ?? "") + (phone ?? "");

            if (!required && (!phoneCode && (!phone || phone.trim().length === 0))) {
                return null;
            }
    
            const isValid = ValidationExtensions.phoneNumberValidator(phoneNumber);
            if (!isValid) {
                return { 'required': true };
            } else {
                return null;
            }
        };
    }


    /**
     * Validate a number is greater than provided lower limit
     * @param limit Lower limit the value of the control must be greater than
     */
    static greaterThan(limit: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (parseFloat(control.value) <= limit) {
                return { greaterThan: true };
            } else {
                return null;
            }
        }
    }

    /**
     * Validate a number is less than a provided upper limit
     * @param limit Upper limit the value of the control must be less than
     */
    static lessThan(limit: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (parseFloat(control.value) >= limit) {
                return { lessThan: true };
            } else {
                return null;
            }
        }
    }


    static requiredAtLeastOneCheckbox(requiredFields: Array<string>, message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            const len = requiredFields ? requiredFields.length : 0;
            const formControl = control as UntypedFormControl;

            // control will be sibling of the required fields, so get required fields from parent
            const parentControl = formControl ? formControl.parent : null;

            if (parentControl) {
                for (let index = 0; index < len; index++) {
                    if (parentControl.get(requiredFields[index]).value === true) {
                        return null;
                    }
                }
            }

            return {
                required: {
                    message: message,
                }
            };
        };
    }

    static requiredAtLeastOneInput(requiredFields: Array<string>, message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {

            const len = requiredFields ? requiredFields.length : 0;
            const formControl = control as UntypedFormControl;

            // control will be sibling of the required fields, so get required fields from parent
            const parentControl = formControl ? formControl.parent : null;

            if (parentControl) {
                for (let index = 0; index < len; index++) {
                    if (parentControl.get(requiredFields[index]).value &&
                        parentControl.get(requiredFields[index]).value.length > 0) {
                        return null;
                    }
                }
            }

            return {
                required: {
                    message: message,
                }
            };
        };
    }

    // requiredFields is the combination of Checkbox and the Numeric Input
    // if the checkbox is checked and Input field value is null it will validate for this scenario
    static requiredCorresponsindNumericInput(requiredFields: Array<string>, message: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const formControl = control as UntypedFormControl;
            // control will be sibling of the required fields, so get required fields from parent
            const parentControl = formControl ? formControl.parent : null;

            if (parentControl) {
                if (parentControl.get(requiredFields[0]).value &&
                    parentControl.get(requiredFields[1]).value &&
                    parentControl.get(requiredFields[1]).value > 0) {
                    return null;
                } else if (!parentControl.get(requiredFields[0]).value) {
                    return null;
                }
            }

            return {
                required: {
                    message: message,
                }
            };
        };
    }
}
