import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IRebateAndVMSFee, IWorkOrder } from '../../models';
import { CustomValidators } from 'src/app/common/validators/CustomValidators';
import { PartyPaymentInfoFormService } from './party-payment-info-form.service';
import { Validators } from '@angular/forms';
import { IBillingParty, IBillingRate, IClientDiscount, IClientDiscountOptions } from '../../models/work-order-form.interface';


@Injectable()
export class PartyBillingInfoFormService implements IFormService {

  readonly flowdownFeeProfileTypes = [PhxConstants.UserProfileType.WorkerSubVendor];

  formGroup: FormArray<IBillingParty>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder,
    private partyPaymentInfoFormService: PartyPaymentInfoFormService
  ) { }

  get partiesRateDetailFormArray(): FormArray<IBillingParty> { // TODO: remove? we already have formGroup
    return this.formGroup;
  }

  get billingRateFormArray(): FormArray<IBillingRate> {
    const formGroup = this.partiesRateDetailFormArray.at(0) as FormGroup<IBillingParty>;
    return formGroup ? formGroup.get('BillingRates') as FormArray<IBillingRate> : null;
  
  }

  get clientOrganizationId(): FormControl<number> {
    return this.formGroup.at(0).get('OrganizationIdClient') as FormControl<number>;
  }

  get clientOrganizationIdChange$() {
    return this.clientOrganizationId.valueChanges;
  }

  get clientOrganizationIdValue() {
    return this.clientOrganizationId.value;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>) {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const billingParties = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.array<IBillingParty>(billingParties
      .map(billingParty => this.createBillingPartyFormGroup(billingParty))
    );

    this.formGroup.controls.forEach(billingPartyFormGroup => {
      // Updates all validators/disabled down the tree
      this.updateBillingPartyValidatorsAndDisabled(billingPartyFormGroup as FormGroup<IBillingParty>);
    });

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {

    const billingParties = this.formGroup;
    
    const firstPartiesRateDetailFormGroup = billingParties.length ? billingParties.at(0) : null;
    const rebateVmsFeeFormGroup = firstPartiesRateDetailFormGroup ? firstPartiesRateDetailFormGroup.get('RebateAndVMSFee') : null;
    const clientDiscountFormGroup = firstPartiesRateDetailFormGroup ? firstPartiesRateDetailFormGroup.get('ClientDiscount') : null;

    const workOrderVersion = workOrder.WorkOrderVersion;
    
    workOrderVersion.BillingInfoes = billingParties.value.map((billingParty, index) => {
      const billingInfoDto = workOrderVersion.BillingInfoes[index];
      
      // Patch the form changes to the BillingInfoDto
      return {
        ...billingInfoDto,
        ...{
          CurrencyId: billingParty.CurrencyId,
          UserProfileIdClient: billingParty.UserProfileIdClient,
          Hours: billingParty.Hours,
          OrganizationIdClient: billingParty.OrganizationIdClient,

          BillingRates: billingParty.BillingRates.map((billingRate) => {
            const billingRateDto = billingInfoDto.BillingRates[index]; // TODO: match by RateTypeId?

            return {
              ...billingRateDto,
              ...{
                RateTypeId: billingRate.RateTypeId,
                Rate: billingRate.Rate,
                RateUnitId: billingRate.RateUnitId,
                Description: billingRate.Description
              }
            };
          }),
        }
      };
    });

    // Just sets flat WorkOrderVersion fields
    this.rebateVmsFeeFormGroupToPartial(workOrder, rebateVmsFeeFormGroup as FormGroup<IRebateAndVMSFee>);
    this.clientDiscountFormGroupToPartial(workOrder, clientDiscountFormGroup as FormGroup<IClientDiscount>);
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const billingParties = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(billingParties, { emitEvent: false });
    
    FormServiceHelper.addRemoveFormArrayControls(this.formGroup, this.fb);

    this.formGroup.controls.forEach(control => {
      const billingPartyFormGroup = control as FormGroup<IBillingParty>;
      
      const billingRatesFormArray = billingPartyFormGroup.get('BillingRates') as FormArray<IBillingRate>;
      FormServiceHelper.addRemoveFormArrayControls(billingRatesFormArray, this.fb);

      // Updates all validators/disabled down the tree
      this.updateBillingPartyValidatorsAndDisabled(billingPartyFormGroup);
    });
  }

  addBillingPartyRate() {
    const formGroup = this.fb.group<IBillingRate>({
      Rate: null,
      RateTypeId: null,
      RateUnitId: null,
      Description: null
    });

    this.billingRateFormArray.push(formGroup);

    this.updateBillingRateValidatorsAndDisabled(formGroup);
  }

  deleteBillingPartyRate(rateIndex: number) {
    const billingRateFormArray = this.billingRateFormArray;

    if (billingRateFormArray) {
      const billingRate = billingRateFormArray.at(rateIndex);
      if (billingRate) {
        const rateTypeId = billingRate.get('RateTypeId').value;
        this.partyPaymentInfoFormService.deletePaymentPartyRate(rateTypeId);
        billingRateFormArray.removeAt(rateIndex);
      }
    }
  }

  updateCurrenyIdFormControl(index: number, value: number, emitEvent = false) {
    const formGroup = this.partiesRateDetailFormArray.at(index) as FormGroup<IBillingParty>;
    const formControl = formGroup.get('CurrencyId');
    formControl.patchValue(value, { emitEvent });
  }

  updateRateUnitIdFormControl(value: number, rateTypeId: number) {

    const formGroup = this.billingRateFormArray.controls.find(fg => {
      return fg.get('RateUnitId').value === rateTypeId;
    });

    if (formGroup) {
      formGroup.get('RateUnitId').patchValue(value, { emitEvent: false });
    }
  }

  private mapWorkOrderToFormData(workorder: IWorkOrder): Array<IBillingParty> {
    
    const rebateAndVMSFee: IRebateAndVMSFee = {
      HasRebate: workorder.WorkOrderVersion.HasRebate,
      HasVmsFee: workorder.WorkOrderVersion.HasVmsFee,
      RebateHeaderId: workorder.WorkOrderVersion.RebateHeaderId,
      RebateTypeId: workorder.WorkOrderVersion.RebateTypeId,
      RebateRate: workorder.WorkOrderVersion.RebateRate,
      VmsFeeHeaderId: workorder.WorkOrderVersion.VmsFeeHeaderId,
      VmsFeeTypeId: workorder.WorkOrderVersion.VmsFeeTypeId,
      VmsFeeRate: workorder.WorkOrderVersion.VmsFeeRate,
      IsFlowdownFee: this.isSubVendor(workorder) ? workorder.WorkOrderVersion.IsFlowdownFee : false
    };
    const clientDiscount: IClientDiscount = {
      HasClientDiscount: workorder.WorkOrderVersion.HasClientDiscount,
      DiscountOptions: {
        HasClientPercentDiscount: workorder.WorkOrderVersion.HasClientPercentDiscount,
        HasClientPerHourDiscount: workorder.WorkOrderVersion.HasClientPerHourDiscount,
        HasClientFlatDiscountAmount: workorder.WorkOrderVersion.HasClientFlatDiscountAmount,
        ClientPercentDiscount: workorder.WorkOrderVersion.ClientPercentDiscount,
        ClientFlatDiscountAmount: workorder.WorkOrderVersion.ClientFlatDiscountAmount,
        ClientPerHourDiscount: workorder.WorkOrderVersion.ClientPerHourDiscount,
        ClientDiscountDescription: workorder.WorkOrderVersion.ClientDiscountDescription
      },
    };

    const billingParties: IBillingParty[] = workorder.WorkOrderVersion.BillingInfoes
    .map(billingParty => {
      return {
        Id: billingParty.Id,
        Hours: billingParty.Hours,
        CurrencyId: billingParty.CurrencyId,
        UserProfileIdClient: billingParty.UserProfileIdClient,
        OrganizationIdClient: billingParty.OrganizationIdClient,
        OrganizationClientDisplayName: billingParty.OrganizationClientDisplayName,
        BillingRates: billingParty.BillingRates.map(rate => {
          const bilingRate: IBillingRate = {
            RateTypeId: rate.RateTypeId,
            Rate: rate.Rate,
            RateUnitId: rate.RateUnitId,
            Description: rate.Description
          };
          return bilingRate;
        }),
        RebateAndVMSFee: rebateAndVMSFee,
        ClientDiscount: clientDiscount
      };
    });
    return billingParties;
  }

  private createBillingPartyFormGroup(billingParty: IBillingParty): FormGroup<IBillingParty> {
    return this.fb.group<IBillingParty>({
      ...billingParty,
      BillingRates: this.fb.array<IBillingRate>(
        billingParty.BillingRates
          .map((billingRate: IBillingRate) => this.createBillingRateFormGroup(billingRate))
      ),
      RebateAndVMSFee: this.createRebateAndVMSFeeFormGroup(billingParty.RebateAndVMSFee),
      ClientDiscount: this.createClientDiscountFormGroup(billingParty.ClientDiscount)
    });
  }

  private createBillingRateFormGroup(billingRate: IBillingRate): FormGroup<IBillingRate> {
    return this.fb.group<IBillingRate>(billingRate);
  }

  private createRebateAndVMSFeeFormGroup(rebateAndVMSFee: IRebateAndVMSFee): FormGroup<IRebateAndVMSFee> {
    return this.fb.group<IRebateAndVMSFee>(rebateAndVMSFee);
  }

  private isSubVendor(workOrder: IWorkOrder): boolean {
    const isWorkerSubVendor = workOrder ? this.flowdownFeeProfileTypes.includes(workOrder.workerProfileTypeId) : false;
    const doesSubVendorOrganizationExist = workOrder?.WorkOrderVersion?.PaymentInfoes.some(pi => pi.IsOrganizationSupplierSubVendor);
    return isWorkerSubVendor || doesSubVendorOrganizationExist;
  }

  private rebateVmsFeeFormGroupToPartial(workOrder: IWorkOrder, rebateVmsFeeFormGroup: FormGroup<IRebateAndVMSFee>): IWorkOrder {

    const vmsFeeDetails: IRebateAndVMSFee = rebateVmsFeeFormGroup.value;
    workOrder.WorkOrderVersion.HasRebate = vmsFeeDetails.HasRebate;
    workOrder.WorkOrderVersion.HasVmsFee = vmsFeeDetails.HasVmsFee;
    workOrder.WorkOrderVersion.RebateHeaderId = vmsFeeDetails.RebateHeaderId;
    workOrder.WorkOrderVersion.RebateTypeId = vmsFeeDetails.RebateTypeId;
    workOrder.WorkOrderVersion.VmsFeeHeaderId = vmsFeeDetails.VmsFeeHeaderId;
    workOrder.WorkOrderVersion.VmsFeeRate = vmsFeeDetails.VmsFeeRate;
    workOrder.WorkOrderVersion.VmsFeeTypeId = vmsFeeDetails.VmsFeeTypeId;
    workOrder.WorkOrderVersion.RebateRate = vmsFeeDetails.RebateRate;
    workOrder.WorkOrderVersion.IsFlowdownFee = this.isSubVendor(workOrder) ? vmsFeeDetails.IsFlowdownFee : false;
    return workOrder;
  }

  private createClientDiscountFormGroup(clientDiscount: IClientDiscount): FormGroup<IClientDiscount> {
    const formGroup = this.fb.group<IClientDiscount>({
      HasClientDiscount: clientDiscount.HasClientDiscount,
      DiscountOptions: this.createClientDiscountOptionsFormGroup(clientDiscount.DiscountOptions)
    });

    return formGroup;
  }

  private createClientDiscountOptionsFormGroup(clientDiscountOptions: IClientDiscountOptions): FormGroup<IClientDiscountOptions> {
    const formGroup = this.fb.group<IClientDiscountOptions>({
      HasClientPercentDiscount: clientDiscountOptions.HasClientPercentDiscount,
      HasClientFlatDiscountAmount: clientDiscountOptions.HasClientFlatDiscountAmount,
      HasClientPerHourDiscount: clientDiscountOptions.HasClientPerHourDiscount,
      ClientPercentDiscount: clientDiscountOptions.ClientPercentDiscount,
      ClientFlatDiscountAmount: clientDiscountOptions.ClientFlatDiscountAmount,
      ClientPerHourDiscount: clientDiscountOptions.ClientPerHourDiscount,
      ClientDiscountDescription: clientDiscountOptions.ClientDiscountDescription
    });

    return formGroup;
  }

  updateClientDiscountValidatorsAndDisabled(formGroup: FormGroup<IClientDiscount>) {
    FormServiceHelper.setValidators(formGroup, this.onGetClientDiscountValidators());
    FormServiceHelper.setDisabled(formGroup, this.onGetClientDiscountDisabled(formGroup));

    this.updateClientDiscountOptionsValidatorsAndDisabled(formGroup.get('DiscountOptions') as FormGroup<IClientDiscountOptions>);
  }
  
  private onGetClientDiscountValidators(): ValidatorsConfig<IClientDiscount> {
    return {
      DiscountOptions: [
        CustomValidators.requiredAtLeastOneCheckbox([
          'DiscountOptions.HasClientPercentDiscount',
          'DiscountOptions.HasClientPerHourDiscount',
          'DiscountOptions.HasClientFlatDiscountAmount'
        ], 'At least one discount option should be checked')
      ]
    };
  }

  private onGetClientDiscountDisabled(formGroup: FormGroup<IClientDiscount>): DisabledConfig<IClientDiscount> {
    const hasClientDiscount = formGroup.get('HasClientDiscount').value;

    return {
      DiscountOptions: !hasClientDiscount
    };
  }

  private updateClientDiscountOptionsValidatorsAndDisabled(formGroup: FormGroup<IClientDiscountOptions>) {
    FormServiceHelper.setValidators(formGroup, this.onGetClientDiscountOptionsValidators());
    FormServiceHelper.setDisabled(formGroup, this.onGetClientDiscountOptionsDisabled(formGroup));
  }

  private onGetClientDiscountOptionsValidators(): ValidatorsConfig<IClientDiscountOptions> {
    return {
      ClientPercentDiscount: [Validators.required],
      ClientPerHourDiscount: [Validators.required],
      ClientFlatDiscountAmount: [Validators.required],
      ClientDiscountDescription: [Validators.required]
    };
  }

  private onGetClientDiscountOptionsDisabled(formGroup: FormGroup<IClientDiscountOptions>): DisabledConfig<IClientDiscountOptions> {
    
    return {
      ClientPercentDiscount: !formGroup.get('HasClientPercentDiscount').value,
      ClientPerHourDiscount: !formGroup.get('HasClientPerHourDiscount').value,
      ClientFlatDiscountAmount: !formGroup.get('HasClientFlatDiscountAmount').value,
    };
  }

  private clientDiscountFormGroupToPartial(workOrder: IWorkOrder, clientDiscountFormGroup: FormGroup<IClientDiscount>): IWorkOrder {
    const clientDiscountDetails: IClientDiscount = clientDiscountFormGroup.value;
    workOrder.WorkOrderVersion.HasClientDiscount = clientDiscountDetails.HasClientDiscount;
    workOrder.WorkOrderVersion.HasClientPercentDiscount = clientDiscountDetails.DiscountOptions?.HasClientPercentDiscount;
    workOrder.WorkOrderVersion.HasClientFlatDiscountAmount = clientDiscountDetails.DiscountOptions?.HasClientFlatDiscountAmount;
    workOrder.WorkOrderVersion.HasClientPerHourDiscount = clientDiscountDetails.DiscountOptions?.HasClientPerHourDiscount;
    workOrder.WorkOrderVersion.ClientPercentDiscount = clientDiscountDetails.DiscountOptions?.ClientPercentDiscount;
    workOrder.WorkOrderVersion.ClientFlatDiscountAmount = clientDiscountDetails.DiscountOptions?.ClientFlatDiscountAmount;
    workOrder.WorkOrderVersion.ClientPerHourDiscount = clientDiscountDetails.DiscountOptions?.ClientPerHourDiscount;
    workOrder.WorkOrderVersion.ClientDiscountDescription = clientDiscountDetails.DiscountOptions?.ClientDiscountDescription;
    return workOrder;
  }

  private updateBillingPartyValidatorsAndDisabled(formGroup: FormGroup<IBillingParty>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingPartyValidators());
    FormServiceHelper.setDisabled(formGroup, this.onGetBillingPartyDisabled());

    this.updateClientDiscountValidatorsAndDisabled(formGroup.get('ClientDiscount') as FormGroup<IClientDiscount>);
    this.updateRebateAndVmsFeeValidatorsAndDisabled(formGroup.get('RebateAndVMSFee') as FormGroup<IRebateAndVMSFee>);

    const billingRatesFormArray = formGroup.get('BillingRates') as FormArray<IBillingRate>;
    billingRatesFormArray.controls.forEach(billingRate => {
      this.updateBillingRateValidatorsAndDisabled(billingRate as FormGroup<IBillingRate>);
    });
  }

  private onGetBillingPartyValidators(): ValidatorsConfig<IBillingParty> {
    return {
      UserProfileIdClient: [Validators.required],
      Hours: [Validators.required],
      CurrencyId: [Validators.required]
    };
  }

  private onGetBillingPartyDisabled(): DisabledConfig<IBillingParty> {
    return {};
  }

  updateBillingRateValidatorsAndDisabled(formGroup: FormGroup<IBillingRate>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingRateValidators());
  }

  private onGetBillingRateValidators(): ValidatorsConfig<IBillingRate> {
    return {
      Rate: [Validators.required],
      RateTypeId: [Validators.required],
      RateUnitId: [Validators.required]
    };
  }

  updateRebateAndVmsFeeValidatorsAndDisabled(formGroup: FormGroup<IRebateAndVMSFee>) {
    FormServiceHelper.setValidators(formGroup, this.onGetRebateAndVMSFeeValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetRebateAndVMSFeeDisabled(formGroup));
  }

  private onGetRebateAndVMSFeeValidators(formGroup: FormGroup<IRebateAndVMSFee>): ValidatorsConfig<IRebateAndVMSFee> {
    
    const hasSelectedRebate = formGroup.get('RebateHeaderId').value != null;
    const hasSelectedVmsFee = formGroup.get('VmsFeeHeaderId').value != null;

    const config: ValidatorsConfig<IRebateAndVMSFee> = {};

    if (!hasSelectedRebate) {
      config.RebateTypeId = [Validators.required];
      config.RebateRate = [Validators.required];
    }

    if (!hasSelectedVmsFee) {
      config.VmsFeeTypeId = [Validators.required];
      config.VmsFeeRate = [Validators.required];
      config.IsFlowdownFee = [Validators.required];
    }

    return config;
  }

  private onGetRebateAndVMSFeeDisabled(formGroup: FormGroup<IRebateAndVMSFee>): DisabledConfig<IRebateAndVMSFee> {

    const hasRebate = formGroup.get('HasRebate').value;
    const hasVmsFee = formGroup.get('HasVmsFee').value;
    
    const config: DisabledConfig<IRebateAndVMSFee> = {};

    if (!hasRebate) {
      config.RebateTypeId = true;
      config.RebateRate = true;
    }

    if (!hasVmsFee) {
      const showFlowdownFee = true; // isWorkerSubVendor || doesSubVendorOrganizationExist; // TODO

      config.VmsFeeTypeId = true;
      config.VmsFeeRate = true;
      config.IsFlowdownFee = !showFlowdownFee; // TODO: Is this disabled? Or is it defaulted when not shown?
    }

    return config;
  }
}
