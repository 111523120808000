import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { DisabledConfig, FormServiceHelper, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IBillingInfo, IBillingInvoiceDto, IBillingRecipientDto, IWorkOrder } from '../../models';
import { IBillingInvoice, IBillingRecipient } from '../../models/work-order-form.interface';

@Injectable()
export class BillingInfoFormService {

  constructor(
    private fb: FormBuilder
  ) { }

  patchBillingInvoicesForInvoiceType(billingInfoDto: IBillingInfo, invoice: IBillingInvoice, invoiceType: PhxConstants.InvoiceType): IBillingInvoiceDto[] {
    const existingInvoiceDto = billingInfoDto.BillingInvoices.find(dto => dto.InvoiceTypeId == invoiceType);

    // filter + concat: Combine invoices that aren't of this type with the new value for this invoice type
    return billingInfoDto.BillingInvoices
        .filter(invoiceDto => invoiceDto != existingInvoiceDto) 
        .concat([this.formGroupToPartialBillingInvoice(invoice, existingInvoiceDto)]);
  }

  formGroupToPartialBillingInvoice(invoice: IBillingInvoice, invoiceDto: IBillingInvoiceDto): IBillingInvoiceDto
  {
    return {
      ...invoiceDto,
      InvoiceTypeId: invoice.InvoiceTypeId,
      BillingInvoicePresentationStyleId: invoice.BillingInvoicePresentationStyleId,
      BillingConsolidationTypeId: invoice.BillingConsolidationTypeId,
      BillingTransactionGenerationMethodId: invoice.BillingTransactionGenerationMethodId,
      IsUsesAlternateBilling: invoice.IsUsesAlternateBilling,
      OrganizatonClientRoleAlternateBillingId: invoice.OrganizatonClientRoleAlternateBillingId,
      BillingFrequencyId: invoice.BillingFrequencyId,
      BillingInvoiceTermsId: invoice.BillingInvoiceTermsId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      BillingInvoiceTemplateId: invoice.BillingInvoiceTemplateId,
      BillingReferenceContactProfileId: invoice.BillingReferenceContactProfileId,
      InvoiceNote1: invoice.InvoiceNote1,
      InvoiceNote2: invoice.InvoiceNote2,
      InvoiceNote3: invoice.InvoiceNote3,
      InvoiceNote4: invoice.InvoiceNote4,
      BillingRecipients: invoice.BillingRecipients.map((recipient, index) => {
        const recipientDto = invoiceDto.BillingRecipients[index];
        return this.formGroupToPartialBillingRecipient(recipient, recipientDto);
      })
    };
  }

  private formGroupToPartialBillingRecipient(recipient: IBillingRecipient, recipientDto: IBillingRecipientDto): IBillingRecipientDto
  {
    return {
      ...recipientDto,
      DeliveryMethodId: recipient.DeliveryMethodId,
      FileExportFormatId: recipient.FileExportFormatId,
      RecipientTypeId: recipient.RecipientTypeId,
      UserProfileId: recipient.UserProfileId,
      DeliverToUserProfileId: recipient.DeliverToUserProfileId
    };
  }

  matchArrays<T1, T2>(source: T1[], target: T2[], compareFn: (source: T1, target: T2) => boolean = null) {
    return source.map((sourceItem, index) => {
      const targetItem = compareFn != null 
        ? target.find(t => compareFn(sourceItem, t)) 
        : target[index];
      return {
        source: sourceItem,
        target: targetItem
      }
    });
  }

  addBillingRecipientFormGroup(formGroup: FormGroup<IBillingInvoice>) {
    // first recipient is the "to" recipient
    const formArray = formGroup.get('BillingRecipients') as FormArray<IBillingRecipient>;
    const isRecipientTypeTo = !formArray?.length;
    formArray.push(this.createBlankBillingRecipientFormGroup(isRecipientTypeTo));
  }

  removeBillingRecipientFormGroup(index: number, formGroup: FormGroup<IBillingInvoice>) {
    const formArray = formGroup.get('BillingRecipients') as FormArray<IBillingRecipient>;
    formArray.removeAt(index);
  }

  addTimesheetBillingInvoiceNote(formGroup: FormGroup<IBillingInvoice>, emitEvent = false) {
    const data = [2, 3, 4];
    for (const noteNumber of data) {
      const propertyName = 'ShowBillingInfoNote' + noteNumber;
      if (!formGroup.controls[propertyName].value) {
        formGroup.controls[propertyName].patchValue(true, { emitEvent });
        break;
      }
    }
  }

  removeTimeSheetBillingInvoiceNote(index: number, formGroup: FormGroup<IBillingInvoice>, emitEvent = false) {
    formGroup.controls['InvoiceNote' + index].patchValue(null, { emitEvent });
    const data = [2, 3, 4];
    let lastValue: string;
    let lastIndex: number;
    for (let i = 0; i < data.length; i++) {
      const propertyName = 'ShowBillingInfoNote' + data[i];
      if (formGroup.controls[propertyName].value) {
        lastValue = propertyName;
        lastIndex = i;
      }
    }
    formGroup.controls[lastValue].patchValue(false, { emitEvent });
    formGroup.controls['InvoiceNote' + data[lastIndex]].patchValue(null, { emitEvent });
  }

  getBillingInvoiceFormData(invoice: IBillingInvoiceDto) : IBillingInvoice // TODO: Create a new form model for BillingInvoice?
  {
    return {
      InvoiceTypeId: invoice.InvoiceTypeId,
      BillingInvoicePresentationStyleId: invoice.BillingInvoicePresentationStyleId,
      BillingConsolidationTypeId: invoice.BillingConsolidationTypeId,
      BillingTransactionGenerationMethodId: invoice.BillingTransactionGenerationMethodId,
      IsUsesAlternateBilling: invoice.IsUsesAlternateBilling,
      OrganizatonClientRoleAlternateBillingId: invoice.OrganizatonClientRoleAlternateBillingId,
      BillingFrequencyId: invoice.BillingFrequencyId,
      BillingInvoiceTermsId: invoice.BillingInvoiceTermsId,
      IsSalesTaxAppliedOnVmsImport: invoice.IsSalesTaxAppliedOnVmsImport,
      BillingInvoiceTemplateId: invoice.BillingInvoiceTemplateId,
      BillingReferenceContactProfileId: invoice.BillingReferenceContactProfileId,
      InvoiceNote1: invoice.InvoiceNote1,
      InvoiceNote2: invoice.InvoiceNote2,
      InvoiceNote3: invoice.InvoiceNote3,
      InvoiceNote4: invoice.InvoiceNote4,
      BillingRecipients: invoice.BillingRecipients.map(recipient => this.getBillingRecipientFormData(recipient))
    };
  }

  private getBillingRecipientFormData(recipient: IBillingRecipientDto): IBillingRecipient // TODO: Create a new form model for IBillingRecipient?
  {
    return {
      DeliveryMethodId: recipient.DeliveryMethodId,
      FileExportFormatId: recipient.FileExportFormatId,
      RecipientTypeId: recipient.RecipientTypeId,
      UserProfileId: recipient.UserProfileId,
      DeliverToUserProfileId: recipient.DeliverToUserProfileId
    };
  }

  createBillingInvoiceFormGroup(invoice: IBillingInvoice): FormGroup<IBillingInvoice> {

    const formGroup = this.fb.group<IBillingInvoice>({
      ...invoice,
      BillingRecipients: this.createBillingRecipientFormArray(invoice)
    });

    // ISSUE: Need to do this on the parent level, or need a way of knowing if the parent control is disabled
    // Only if the parent can be disabled though
    this.updateBillingInvoiceValidatorsAndDisabled(formGroup);

    return formGroup;
  }

  updateBillingInvoiceValidatorsAndDisabled(formGroup: FormGroup<IBillingInvoice>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingInvoiceValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetBillingInvoiceDisabled(formGroup));
  }

  updateBillingRecipientValidatorsAndDisabled(formGroup: FormGroup<IBillingRecipient>) {
    FormServiceHelper.setValidators(formGroup, this.onGetBillingRecipientValidators(formGroup));
    FormServiceHelper.setDisabled(formGroup, this.onGetBillingRecipientDisabled(formGroup));
  }

  private createBillingRecipientFormArray(invoice: IBillingInvoice) {
    return this.fb.array<IBillingRecipient>(
      invoice.BillingRecipients.map(recipient => this.createBillingRecipientFormGroup(recipient))
    );
  }

  private createBillingRecipientFormGroup(recipient: IBillingRecipient) {
    const formGroup = this.fb.group<IBillingRecipient>(recipient);

    this.updateBillingRecipientValidatorsAndDisabled(formGroup);

    return formGroup;
  }

  private createBlankBillingRecipientFormGroup(isRecipientTypeTo: boolean = false): FormGroup<any> {
    const formGroup = this.fb.group<IBillingRecipient>({
      UserProfileId: null,
      DeliveryMethodId: null,
      FileExportFormatId: null,
      RecipientTypeId: isRecipientTypeTo
        ? PhxConstants.RecipientType.InvoiceRecipient
        : null,
      DeliverToUserProfileId: null
    });

    this.updateBillingRecipientValidatorsAndDisabled(formGroup);

    return formGroup;
  }

  private onGetBillingInvoiceValidators(formGroup: FormGroup<IBillingInvoice>): ValidatorsConfig<IBillingInvoice> {

    return {
      BillingInvoicePresentationStyleId: [Validators.required],
      BillingConsolidationTypeId: [Validators.required],
      BillingTransactionGenerationMethodId: [Validators.required],
      IsUsesAlternateBilling: [Validators.required],
      OrganizatonClientRoleAlternateBillingId: [Validators.required],
      BillingFrequencyId: [Validators.required],
      BillingInvoiceTermsId: [Validators.required],
      IsSalesTaxAppliedOnVmsImport: [Validators.required],
      BillingInvoiceTemplateId: [Validators.required],
      BillingReferenceContactProfileId: [Validators.required]
    };
  }

  private onGetBillingInvoiceDisabled(formGroup: FormGroup<IBillingInvoice>): DisabledConfig<IBillingInvoice> {
    
    const invoiceTypeId = formGroup.get("InvoiceTypeId").value;

    const presentationStyleId = formGroup.get("BillingInvoicePresentationStyleId").value;

    const isUsesAlternateBilling = formGroup.get("IsUsesAlternateBilling");

    const timeSheetMethodologyId = invoiceTypeId == PhxConstants.InvoiceType.TimeSheet 
    ? null // TODO
    : null;

    const expenseMethodologyId = invoiceTypeId == PhxConstants.InvoiceType.Expense
    ? null // TODO
    : null;
    
    const isTimesheetUsesProjects = true; // TOOD;
    const isExpensesUsesProjects = true; // TOOD;
    let canSplitBilling = false;
    
    if (timeSheetMethodologyId) {
      canSplitBilling = (timeSheetMethodologyId == PhxConstants.TimeSheetMethodology.OnlineApproval
        || timeSheetMethodologyId == PhxConstants.TimeSheetMethodology.OfflineApproval) && isTimesheetUsesProjects;
    } else {
      canSplitBilling = (expenseMethodologyId == PhxConstants.ExpenseMethodology.OnlineApproval
        || expenseMethodologyId == PhxConstants.ExpenseMethodology.OfflineApproval) && isExpensesUsesProjects;
    } 

    /*
      TODO: Disable whole BillingInvoice when: invoiceTypeId == Expense && expenseMethodologyId == NoExpense
      Also look into if IncentiveCompensation needs to disable this
    */

    return {
      BillingConsolidationTypeId: presentationStyleId !== PhxConstants.BillingInvoicePresentationStyle.Consolidated,
      BillingTransactionGenerationMethodId: !canSplitBilling,
      OrganizatonClientRoleAlternateBillingId: !isUsesAlternateBilling,
      IsSalesTaxAppliedOnVmsImport: expenseMethodologyId !== PhxConstants.ExpenseMethodology.ThirdPartyImport
    }
  }

  private onGetBillingRecipientValidators(formGroup: FormGroup<IBillingRecipient>): ValidatorsConfig<IBillingRecipient> 
  {
    return {
      DeliveryMethodId: [Validators.required],
      FileExportFormatId: [Validators.required],
      RecipientTypeId: [Validators.required],
      UserProfileId: [Validators.required],
      DeliverToUserProfileId: [Validators.required]
    };
  }

  private onGetBillingRecipientDisabled(formGroup: FormGroup<IBillingRecipient>): DisabledConfig<IBillingRecipient> 
  {
    const deliveryMethodId = formGroup.get("DeliveryMethodId").value;

    return {
      FileExportFormatId: deliveryMethodId !== PhxConstants.DeliveryMethod.FileExportFormat,
      DeliverToUserProfileId: !(deliveryMethodId === PhxConstants.DeliveryMethod.InternalProfile || deliveryMethodId === PhxConstants.DeliveryMethod.ClientProfile)
    }
  }

  private updateBillingInvoiceFormGroup(
    formGroup: FormGroup<IBillingInvoice>,
    invoice: IBillingInvoiceDto
  ) {
    const formValue = this.getBillingInvoiceFormData(invoice);

    formGroup.patchValue(formValue, { emitEvent: false });

    const billingRecipientsFormArray = formGroup.get('BillingRecipients') as FormArray<any>;

    this.updateBillingRecipientFormArray(billingRecipientsFormArray, invoice.BillingRecipients);

  }

  private updateBillingRecipientFormArray(
    formArray: FormArray<any>,
    recipients: Array<IBillingRecipientDto>
  ) {
    FormServiceHelper.updateFormArrayControls(formArray, recipients, this.fb);
  }
}
