import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IWorkOrder } from '../../models';
import { ITabTimeMaterialInvoiceDetail, ITimeSheetApprover } from '../../models/work-order-form.interface';

@Injectable()
export class TimeMaterialInvoiceDetailFormService implements IFormService {

  formGroup: FormGroup<ITabTimeMaterialInvoiceDetail>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder
  ) { }

  get timeSheetApproverFormArray(): FormArray<ITimeSheetApprover> {
    return this.formGroup.get('TimeSheetApprovers') as FormArray<ITimeSheetApprover>;
  }

  get timeSheetMethodologyIdFormControl(): FormControl<number> {
    return this.formGroup.get('TimeSheetMethodologyId') as FormControl<number>;
  }

  get isTimeSheetUsesProjectsFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsTimeSheetUsesProjects') as FormControl<boolean>;
  }

  get timeSheetMethodologyIdValue(): number {
    return this.timeSheetMethodologyIdFormControl.value;
  }

  get timeSheetMethodologyIdChange$() {
    return this.timeSheetMethodologyIdFormControl.valueChanges;
  }

  get isTimeSheetUsesProjectsChange$() {
    return this.isTimeSheetUsesProjectsFormControl.valueChanges;
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabTimeMaterialInvoiceDetail> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const timeMaterialInvoiceDetail: ITabTimeMaterialInvoiceDetail = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabTimeMaterialInvoiceDetail>({
      ... timeMaterialInvoiceDetail,
      ...{
        TimeSheetApprovers: this.createTimeSheetApproverFormArray(timeMaterialInvoiceDetail.TimeSheetApprovers)
      }
    });

    this.updateValiatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.timeSheetMethodologyIdChange$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(
      value => {
        this.updateValiatorsAndDisabled();
      }
    );

    this.isTimeSheetUsesProjectsChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(
      value => {
        this.updateValiatorsAndDisabled();
      }
    );
  }

  private updateValiatorsAndDisabled() {
    const validatorsConfig = this.onGetValidators();
    FormServiceHelper.setValidators(this.formGroup, validatorsConfig);

    const disabledConfig = this.onGetDisabled();
    console.log('disabledConfig.TimeSheetApprovers', disabledConfig.TimeSheetApprovers);
    FormServiceHelper.setDisabled(this.formGroup, disabledConfig);

    this.timeSheetApproverFormArray.controls.forEach(formGroup => {
      this.updateTimeSheetApproverValiatorsAndDisabled(formGroup as FormGroup<ITimeSheetApprover>);
    });
  }

  private onGetValidators(): ValidatorsConfig<ITabTimeMaterialInvoiceDetail> {

    var timeSheetMethodologyId = this.formGroup.get("TimeSheetMethodologyId").value;    

    // Don't set conditional validators if it's only due to the control being disabled (defined in onGetDisabled)
    // Disabled controls dont' get validated
    // VmsWorkOrderReference is conditional here because it is never disabled
    return {
      TimeSheetMethodologyId: [Validators.required],
      TimeSheetCycleId: [Validators.required],
      TimeSheetApprovalFlowId: [Validators.required],
      IsTimeSheetUsesProjects: [Validators.required],
      IsTimesheetProjectMandatory: [Validators.required],
      VmsWorkOrderReference: timeSheetMethodologyId === PhxConstants.TimeSheetMethodology.ThirdPartyImport
        ? [Validators.required]
        : null,
      IsDisplayEstimatedInvoiceAmount: [Validators.required],
      IsDisplayEstimatedPaymentAmount: [Validators.required],
      TimeSheetDescription: [Validators.maxLength(200)]
    };
  }

  private onGetDisabled(): DisabledConfig<ITabTimeMaterialInvoiceDetail> {
    var timeSheetMethodologyId = this.formGroup.get("TimeSheetMethodologyId").value;
    var hasMultiApprovers = this.formGroup.get("TimeSheetApprovers").value.length > 1;
    var isUsesProjects = this.formGroup.get("IsTimeSheetUsesProjects").value;

    const methodologyUsesProjects = [PhxConstants.TimeSheetMethodology.OnlineApproval, PhxConstants.TimeSheetMethodology.OfflineApproval];
    const description = [PhxConstants.TimeSheetMethodology.OnlineApproval, PhxConstants.TimeSheetMethodology.OfflineApproval];

    return {
      TimeSheetCycleId: !timeSheetMethodologyId || timeSheetMethodologyId === PhxConstants.TimeSheetMethodology.NoTimesheet,
      TimeSheetApprovalFlowId: !(timeSheetMethodologyId === PhxConstants.TimeSheetMethodology.OnlineApproval && hasMultiApprovers),
      IsTimeSheetUsesProjects: !methodologyUsesProjects.includes(timeSheetMethodologyId),
      IsTimesheetProjectMandatory: !isUsesProjects,
      IsDisplayEstimatedInvoiceAmount: timeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.OnlineApproval,
      IsDisplayEstimatedPaymentAmount: !timeSheetMethodologyId || timeSheetMethodologyId === PhxConstants.TimeSheetMethodology.NoTimesheet,
      TimeSheetDescription: !description.includes(timeSheetMethodologyId),
      TimeSheetApprovers: timeSheetMethodologyId !== PhxConstants.TimeSheetMethodology.OnlineApproval
    };
  }

  private updateTimeSheetApproverValiatorsAndDisabled(formGroup: FormGroup<ITimeSheetApprover>) {
    const validatorsConfig = this.onGetTimeSheetApproverValidators(formGroup);
    FormServiceHelper.setValidators(formGroup, validatorsConfig);

    const disabledConfig = this.onGetTimeSheetApproverDisabled(formGroup);
    FormServiceHelper.setDisabled(formGroup, disabledConfig);
  }

  private onGetTimeSheetApproverValidators(formGroup: FormGroup<ITimeSheetApprover>): ValidatorsConfig<ITimeSheetApprover> {
    return {
      UserProfileId: [Validators.required]
    }
  }

  private onGetTimeSheetApproverDisabled(formGroup: FormGroup<ITimeSheetApprover>): DisabledConfig<ITimeSheetApprover> {
    return {
      // Nothing
    }
  }

  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const timeMaterialInvoiceDetails: ITabTimeMaterialInvoiceDetail = this.formGroup.value;

    // All the previous ternary statements that set to null under 'disabled' conditions should be resolved by disabling the controls when needed. 
    // Disabled controls have no value when calling parent.value
    // TODO: check if disabled control returns undefined instead of null
    workOrder.WorkOrderVersion.TimeSheetMethodologyId = timeMaterialInvoiceDetails.TimeSheetMethodologyId;
    workOrder.WorkOrderVersion.TimeSheetCycleId = timeMaterialInvoiceDetails.TimeSheetCycleId;
    workOrder.WorkOrderVersion.TimeSheetApprovalFlowId = timeMaterialInvoiceDetails.TimeSheetApprovalFlowId;
    workOrder.WorkOrderVersion.TimeSheetApprovers = timeMaterialInvoiceDetails.TimeSheetApprovers?.map((approver, index) => {
      const approverDto = workOrder.WorkOrderVersion.TimeSheetApprovers[index];
      return {
        ...approverDto,
        Sequence: approver.Sequence,
        UserProfileId: approver.UserProfileId,
        MustApprove: approver.MustApprove
      };
    }) ?? [];
    workOrder.WorkOrderVersion.IsTimeSheetUsesProjects = timeMaterialInvoiceDetails.IsTimeSheetUsesProjects;
    workOrder.WorkOrderVersion.IsTimesheetProjectMandatory = timeMaterialInvoiceDetails.IsTimesheetProjectMandatory;
    workOrder.WorkOrderVersion.VmsWorkOrderReference = timeMaterialInvoiceDetails.VmsWorkOrderReference === '' ? null : timeMaterialInvoiceDetails.VmsWorkOrderReference;
    workOrder.WorkOrderVersion.IsDisplayEstimatedInvoiceAmount = timeMaterialInvoiceDetails.IsDisplayEstimatedInvoiceAmount;
    workOrder.WorkOrderVersion.IsDisplayEstimatedPaymentAmount = timeMaterialInvoiceDetails.IsDisplayEstimatedPaymentAmount;
    workOrder.WorkOrderVersion.TimeSheetDescription = timeMaterialInvoiceDetails.TimeSheetDescription;

    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const timeMaterialInvoiceDetail: ITabTimeMaterialInvoiceDetail = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(timeMaterialInvoiceDetail, { emitEvent: false });

    FormServiceHelper.updateFormArrayControls(this.timeSheetApproverFormArray, timeMaterialInvoiceDetail.TimeSheetApprovers, this.fb);

    this.updateValiatorsAndDisabled();
  }

  addTimeSheetApproverDefinitionFormGroup() {
    const sequence = this.timeSheetApproverFormArray.length + 1;

    const formGroup = this.fb.group<ITimeSheetApprover>({
      MustApprove: true,
      Sequence: sequence,
      UserProfileId: null
    });

    this.timeSheetApproverFormArray.push(formGroup);

    this.updateValiatorsAndDisabled();
  }

  removeTimeSheetApproverDefinitionFormGroup(index: number) {
    this.timeSheetApproverFormArray.removeAt(index);
  }

  mapWorkOrderToFormData(workorder: IWorkOrder): ITabTimeMaterialInvoiceDetail {
    const formValue = {
      IsDisplayEstimatedInvoiceAmount: workorder.WorkOrderVersion.IsDisplayEstimatedInvoiceAmount,
      IsDisplayEstimatedPaymentAmount: workorder.WorkOrderVersion.IsDisplayEstimatedPaymentAmount,
      IsTimeSheetUsesProjects: workorder.WorkOrderVersion.IsTimeSheetUsesProjects,
      IsTimesheetProjectMandatory: workorder.WorkOrderVersion.IsTimesheetProjectMandatory,
      TimeSheetApprovalFlowId: workorder.WorkOrderVersion.TimeSheetApprovalFlowId,
      TimeSheetApprovers: workorder.WorkOrderVersion.TimeSheetApprovers ?? [],
      TimeSheetCycleId: workorder.WorkOrderVersion.TimeSheetCycleId,
      TimeSheetDescription: workorder.WorkOrderVersion.TimeSheetDescription,
      TimeSheetMethodologyId: workorder.WorkOrderVersion.TimeSheetMethodologyId,
      VmsWorkOrderReference: workorder.WorkOrderVersion.VmsWorkOrderReference,
      OrganizationIdClient: workorder.WorkOrderVersion.BillingInfoes[0].OrganizationIdClient
    };

    if (!formValue.TimeSheetApprovers.length) {
      formValue.TimeSheetApprovers.push({
        Id: 0,
        IsDraft: true,
        MustApprove: true,
        Sequence: 1,
        SourceId: null,
        UserProfileId: null,
        WorkOrderVersionId: 0
      });
    }

    return formValue;
  }

  private createTimeSheetApproverFormArray(timesheetApprovers: Array<ITimeSheetApprover> = []): FormArray<ITimeSheetApprover> {
    // TODO: is this needed? it's not in update?
    timesheetApprovers ??= [];

    return this.fb.array<ITimeSheetApprover>(
      timesheetApprovers
        .sort((a1: ITimeSheetApprover, a2: ITimeSheetApprover) => a1.Sequence - a2.Sequence)
        .map((approver: ITimeSheetApprover) => this.createTimeSheetApproverFormGroup(approver))
    );
  }

  private createTimeSheetApproverFormGroup(approver: ITimeSheetApprover): FormGroup<ITimeSheetApprover> {
    const formGroup = this.fb.group<ITimeSheetApprover>(approver);
    
    return formGroup;
  }

}
