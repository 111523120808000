import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Subject } from 'rxjs/internal/Subject';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { DisabledConfig, FormServiceHelper, IFormService, PhxConstants, ValidatorsConfig } from '../../../common/model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '../../../common/ngx-strongly-typed-forms/model';
import { IClientOrganization, ISupplierOrganization, IWorkOrder } from '../../models';
import { ITabExpenseInvoiceDetail, ITabExpenseInvoiceApprovers, IExpenseApprover, ITabExpensePaymentInfo } from '../../models/work-order-form.interface';

@Injectable()
export class ExpenseDetailFormService implements IFormService {

  formGroup: FormGroup<ITabExpenseInvoiceDetail>;
  private isRootComponentDestroyed$: Subject<boolean>;

  constructor(
    private fb: FormBuilder
  ) { }

  get expenseIdFormControl(): FormControl<number> {
    return this.formGroup.get('ExpenseMethodologyId') as FormControl<number>;
  }

  get isUsesProjectsFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsExpenseUsesProjects') as FormControl<boolean>;
  }

  get expenseApproverFormArray(): FormArray<IExpenseApprover> {
    const expenseApprovers = this.formGroup.get('ExpenseApprovers');
    return expenseApprovers.get('ClientApprover') as FormArray<IExpenseApprover>;
  }
  get supplierApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('SupplierApprover') as FormArray<IExpenseApprover>;
  }
  get internalApproverFormArray(): FormArray<IExpenseApprover> {
    return this.formGroup.get('ExpenseApprovers').get('InternalApprover') as FormArray<IExpenseApprover>;
  }

  get expenseMethodologyIdChange$() {
    return this.expenseIdFormControl.valueChanges;
  }

  get isUsesProjectsChange$() {
    return this.isUsesProjectsFormControl.valueChanges;
  }

  get isExpenseUsesProjectsFormControl(): FormControl<boolean> {
    return this.formGroup.get('IsExpenseUsesProjects') as FormControl<boolean>;
  }

  get isExpenseUsesProjectsChange$() {
    return this.isExpenseUsesProjectsFormControl.valueChanges;
  }

  private updateValiatorsAndDisabled() {
    FormServiceHelper.setValidators(this.formGroup, this.onGetValidators());
    FormServiceHelper.setDisabled(this.formGroup, this.onGetDisabled());

    var expenseMethododlogyId = this.formGroup.get("ExpenseMethodologyId").value;

    const expenseApproversFormGroup = this.formGroup.get('ExpenseApprovers') as FormGroup<ITabExpenseInvoiceApprovers>;
    this.updateApproversValdiatorsAndDisabled(expenseApproversFormGroup, expenseMethododlogyId);
  }

  onGetValidators(): ValidatorsConfig<ITabExpenseInvoiceDetail> {
  
    var expenseMethododlogyId = this.formGroup.get("ExpenseMethodologyId").value;

    return {
      ExpenseMethodologyId: [Validators.required],
      ExpenseApprovalFlowId: [Validators.required],
      IsExpenseUsesProjects: [Validators.required],
      ExpenseThirdPartyWorkerReference: expenseMethododlogyId === PhxConstants.ExpenseMethodology.ThirdPartyImport
        ? [Validators.required]
        : null,
      IsExpenseRequiresOriginal: [Validators.required],
    };
  }

  onGetDisabled(): DisabledConfig<ITabExpenseInvoiceDetail> {
    var expenseMethodologyId = this.formGroup.get("ExpenseMethodologyId").value;
    var hasMultiClientApprovers = this.formGroup.get("ExpenseApprovers").get('ClientApprover').value.length > 1;

    const methodologyApprovers = [PhxConstants.ExpenseMethodology.OnlineApproval, PhxConstants.ExpenseMethodology.OfflineApproval];
    const methodologyUsesProjects = [PhxConstants.ExpenseMethodology.OnlineApproval, PhxConstants.ExpenseMethodology.OfflineApproval];
    const methodologyRequiresOriginal = [PhxConstants.ExpenseMethodology.OnlineApproval, PhxConstants.ExpenseMethodology.OfflineApproval];
    const methodologyHasDescription = [PhxConstants.ExpenseMethodology.OnlineApproval, PhxConstants.ExpenseMethodology.OfflineApproval];

    const noExpense = !expenseMethodologyId || expenseMethodologyId == PhxConstants.ExpenseMethodology.NoExpense;

    return {
      ExpenseApprovers: !methodologyApprovers.includes(expenseMethodologyId),
      ExpenseApprovalFlowId: !(expenseMethodologyId == PhxConstants.ExpenseMethodology.OnlineApproval && hasMultiClientApprovers),
      IsExpenseUsesProjects: !methodologyUsesProjects.includes(expenseMethodologyId),
      IsExpenseRequiresOriginal: !methodologyRequiresOriginal.includes(expenseMethodologyId),
      ExpenseDescription: !methodologyHasDescription.includes(expenseMethodologyId),
      ExpenseThirdPartyWorkerReference: noExpense
    };
  }


  private updateApproversValdiatorsAndDisabled(formGroup: FormGroup<ITabExpenseInvoiceApprovers>, expenseMethodologyId: number) {
    FormServiceHelper.setDisabled(formGroup, this.onGetExpenseApproverGroupDisabled(formGroup, expenseMethodologyId));

    const supplierApprovers = formGroup.get('SupplierApprover') as FormArray<IExpenseApprover>;
    const internalApprovers = formGroup.get('InternalApprover') as FormArray<IExpenseApprover>;
    const clientApprovers = formGroup.get('ClientApprover') as FormArray<IExpenseApprover>;

    const allApprovers = [
      ...supplierApprovers.controls,
      ...internalApprovers.controls,
      ...clientApprovers.controls
    ]
    
    allApprovers
      .map(approverGroup => approverGroup as FormGroup<IExpenseApprover>)
      .forEach(approverGroup => this.updateExpenseApproverValiatorsAndDisabled(approverGroup, expenseMethodologyId));
  }

  private onGetExpenseApproverGroupDisabled(formGroup: FormGroup<ITabExpenseInvoiceApprovers>, expenseMethodologyId: number): DisabledConfig<ITabExpenseInvoiceApprovers> {
    return {
      SupplierApprover: expenseMethodologyId != PhxConstants.ExpenseMethodology.OnlineApproval,
      ClientApprover: expenseMethodologyId != PhxConstants.ExpenseMethodology.OnlineApproval
    }
  }


  private updateExpenseApproverValiatorsAndDisabled(formGroup: FormGroup<IExpenseApprover>, expenseMethodologyId: number) {
    const validatorsConfig = this.onGetExpenseApproverValidators(formGroup, expenseMethodologyId);
    FormServiceHelper.setValidators(formGroup, validatorsConfig);

    // No controls to disable
  }

  private onGetExpenseApproverValidators(formGroup: FormGroup<IExpenseApprover>, expenseMethodologyId: number): ValidatorsConfig<IExpenseApprover> {
    var approverType = formGroup.get("ApproverTypeId").value;

    const isRequiredClient = approverType === PhxConstants.ApproverType.ClientApprover;
    const isRequiredInternal = approverType === PhxConstants.ApproverType.InternalApprover && expenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval;

    return {
      UserProfileId: isRequiredClient || isRequiredInternal
        ? [Validators.required]
        : null
    }
  }

  createForm(workorder: IWorkOrder, isDestroyed$: Subject<boolean>): FormGroup<ITabExpenseInvoiceDetail> {
    this.isRootComponentDestroyed$ = isDestroyed$;

    const expenseDetail: ITabExpenseInvoiceDetail = this.mapWorkOrderToFormData(workorder);

    this.formGroup = this.fb.group<ITabExpenseInvoiceDetail>({
      ...expenseDetail,
      ExpenseApprovers: this.createExpenseApproverFormGroup(expenseDetail.ExpenseApprovers),
    });

    this.updateValiatorsAndDisabled();

    return this.formGroup;
  }

  destroyForm() {
    this.formGroup = null;
  }

  setupFormListeners() {
    this.isRootComponentDestroyed$.subscribe(() => {
      this.destroyForm();
    });

    this.expenseMethodologyIdChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(value => {
      this.updateValiatorsAndDisabled();
    });

    this.isUsesProjectsChange$.pipe(
      distinctUntilChanged(),
      takeUntil(this.isRootComponentDestroyed$)
    ).subscribe(
      value => {
        this.updateValiatorsAndDisabled();
      }
    );
  }
  
  formGroupToPartial(workOrder: IWorkOrder): IWorkOrder {
    const expenseInvoiceDetail: ITabExpenseInvoiceDetail = this.formGroup.value;

    workOrder.WorkOrderVersion.ExpenseMethodologyId = expenseInvoiceDetail.ExpenseMethodologyId;
    workOrder.WorkOrderVersion.ExpenseApprovalFlowId = expenseInvoiceDetail.ExpenseApprovalFlowId;
    workOrder.WorkOrderVersion.IsExpenseUsesProjects = expenseInvoiceDetail.IsExpenseUsesProjects;
    workOrder.WorkOrderVersion.ExpenseApprovers =
      [
        ...expenseInvoiceDetail.ExpenseApprovers.SupplierApprover.filter(a => a.UserProfileId),
        ...expenseInvoiceDetail.ExpenseApprovers.InternalApprover.filter(a => a.UserProfileId),
        ...expenseInvoiceDetail.ExpenseApprovers.ClientApprover,
      ]
      .map(approver => {
        const approverDto = workOrder.WorkOrderVersion.ExpenseApprovers.find(x => x.ApproverTypeId == approver.ApproverTypeId && x.Sequence == approver.Sequence); // Attempt to keep similar history
        return {
          ...approverDto,
          ApproverTypeId: approver.ApproverTypeId,
          Sequence: approver.Sequence,
          UserProfileId: approver.UserProfileId,
          MustApprove: approver.MustApprove
        };
      }),
    workOrder.WorkOrderVersion.ExpenseThirdPartyWorkerReference = expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.NoExpense ||
      expenseInvoiceDetail.ExpenseThirdPartyWorkerReference === '' ? null : expenseInvoiceDetail.ExpenseThirdPartyWorkerReference;
    workOrder.WorkOrderVersion.IsExpenseRequiresOriginal = expenseInvoiceDetail.IsExpenseRequiresOriginal;
    workOrder.WorkOrderVersion.ExpenseDescription =
      expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OnlineApproval || expenseInvoiceDetail.ExpenseMethodologyId === PhxConstants.ExpenseMethodology.OfflineApproval
        ? expenseInvoiceDetail.ExpenseDescription
        : null;
    return workOrder;
  }

  updateForm(workorder: IWorkOrder): void {
    const expenseDetail: ITabExpenseInvoiceDetail = this.mapWorkOrderToFormData(workorder);

    this.formGroup.patchValue(expenseDetail, { emitEvent: false });

    FormServiceHelper.addRemoveFormArrayControls(this.expenseApproverFormArray, this.fb);
    FormServiceHelper.addRemoveFormArrayControls(this.supplierApproverFormArray, this.fb);
    FormServiceHelper.addRemoveFormArrayControls(this.internalApproverFormArray, this.fb);

  }

  addTimeSheetApproverDefinitionFormGroup() {
    const formGroup = this.createBlankClientApproverFormGroup();
    this.expenseApproverFormArray.push(formGroup);
  }

  removeTimeSheetApproverDefinitionFormGroup(index: number) {
    this.expenseApproverFormArray.removeAt(index);
  }

  mapWorkOrderToFormData(workorder: IWorkOrder): ITabExpenseInvoiceDetail {

    const expenseApprovers: IExpenseApprover[] = workorder.WorkOrderVersion.ExpenseApprovers.map(approver => {
      return {
        ApproverTypeId: approver.ApproverTypeId,
        Sequence: approver.Sequence,
        UserProfileId: approver.UserProfileId,
        MustApprove: approver.MustApprove
      };
    }) ?? [];

    const clientApprovers = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.ClientApprover);
    const internalApprovers = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.InternalApprover);
    const supplierApprovers = expenseApprovers.filter(a => a.ApproverTypeId === PhxConstants.ApproverType.SupplierApprover);

    return {
      ExpenseMethodologyId: workorder.WorkOrderVersion.ExpenseMethodologyId,
      ExpenseApprovalFlowId: workorder.WorkOrderVersion.ExpenseApprovalFlowId,
      IsExpenseUsesProjects: workorder.WorkOrderVersion.IsExpenseUsesProjects,
      IsExpenseRequiresOriginal: workorder.WorkOrderVersion.IsExpenseRequiresOriginal,
      ExpenseThirdPartyWorkerReference: workorder.WorkOrderVersion.ExpenseThirdPartyWorkerReference,
      ExpenseDescription: workorder.WorkOrderVersion.ExpenseDescription,
      ExpenseApprovers: {
        SupplierApprover: supplierApprovers.length ? supplierApprovers : this.getExpenseApproverDefaultList(PhxConstants.ApproverType.SupplierApprover),
        InternalApprover: internalApprovers.length ? internalApprovers : this.getExpenseApproverDefaultList(PhxConstants.ApproverType.InternalApprover),
        ClientApprover: clientApprovers.length ? clientApprovers : this.getExpenseApproverDefaultList(PhxConstants.ApproverType.ClientApprover)
      }
    };
  }


  private createExpenseApproverFormGroup(expenseApprovers: ITabExpenseInvoiceApprovers) {

    return this.fb.group<ITabExpenseInvoiceApprovers>({
      ClientApprover: this.createGroupExpenseApproverFormArray(expenseApprovers.SupplierApprover),
      InternalApprover: this.createGroupExpenseApproverFormArray(expenseApprovers.InternalApprover),
      SupplierApprover: this.createGroupExpenseApproverFormArray(expenseApprovers.ClientApprover)
    });

  }

  private createGroupExpenseApproverFormArray(expenseApprovers: Array<IExpenseApprover>) {
    return this.fb.array<IExpenseApprover>(
      expenseApprovers
        .sort((a1: IExpenseApprover, a2: IExpenseApprover) => a1.Sequence - a2.Sequence)
        .map(approver => this.createGroupExpenseApproverFormGroup(approver))
    );
  }

  private createGroupExpenseApproverFormGroup(approver: IExpenseApprover): FormGroup<IExpenseApprover> {

    return this.fb.group<IExpenseApprover>(approver);
  }

  private getExpenseApproverDefaultList(ApproverType: number): Array<IExpenseApprover> {
    const expenseApprover: Array<IExpenseApprover> = [
      {
        ApproverTypeId: ApproverType,
        MustApprove: true,
        Sequence: ApproverType === PhxConstants.ApproverType.SupplierApprover ? 1 : ApproverType === PhxConstants.ApproverType.InternalApprover ? 2 : 3, // Can only have 1 Supplier or Internal profile, so dynamic counts start at 3 (client)
        UserProfileId: null
      }
    ];
    return expenseApprover;
  }

  private createBlankClientApproverFormGroup(): FormGroup<IExpenseApprover> {
    const expenseApprovers = this.formGroup.get('ExpenseApprovers');
    const clientApprovers = expenseApprovers.get('ClientApprover') as FormArray<IExpenseApprover>;        
    return this.fb.group<IExpenseApprover>({
      ApproverTypeId: PhxConstants.ApproverType.ClientApprover,
      MustApprove: true,
      Sequence: clientApprovers.length > 0 ? (clientApprovers.at(clientApprovers.length-1).get('Sequence').value + 1) : 3,
      UserProfileId: null,
    });
  }

}
