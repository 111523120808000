import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { PhxConstants } from '../..';
import { PhxDocument, PhxDataTableConfiguration, PhxDataTableColumn, CodeValueGroups, CodeValue, DialogResultType } from '../../model';
import { CommonService } from '../../services/common.service';
import { CodeValueService } from '../../services/code-value.service';
import { DialogService } from '../../services/dialog.service';
import { PhxDataTableComponent } from '../phx-data-table/phx-data-table.component';

declare const oreq: any;

@Component({
  selector: 'phx-entity-documents-list',
  templateUrl: './phx-entity-documents-list.component.html',
  styleUrls: ['./phx-entity-documents-list.component.less']
})
export class PhxEntityDocumentsListComponent implements OnInit, OnChanges {
  @Input() entityTypeId: PhxConstants.EntityType;
  @Input() entityId: number;
  @Input() canDeleteDocumentFn: (doc: PhxDocument) => boolean;
  @Input() documentTypesList: CodeValue[];
  @Output() rowClick = new EventEmitter<PhxDocument>();
  @Output() contextMenuOpenTab = new EventEmitter<PhxDocument>();
  @Output() documentDeleteFailed = new EventEmitter<any>();
  @ViewChild('grd', { static: true }) grd: PhxDataTableComponent;

  entityDocumentTypesLookup: CodeValue[];

  dataSourceUrl: string;
  columns: Array<PhxDataTableColumn> = [];
  dataGridComponentName: string = 'DocumentSearch';
  dataTableConfiguration: PhxDataTableConfiguration = new PhxDataTableConfiguration({
    showOpenInNewTab: true
  });

  oDataParams: string = oreq
    .request()
    .withSelect(['Name', 'UploadedByFullName', 'DocumentTypeId', 'UploadedDatetime', 'PublicId'])
    .url();

  constructor(private documentService: DocumentService, private codeValueService: CodeValueService, private commonService: CommonService, private dialogs: DialogService) {}

  ngOnInit() {
    this.setForEntityType();
    this.setForEntity();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityTypeId) {
      this.setForEntityType();
    }
    if (changes.entityTypeId || changes.entityId) {
      this.setForEntity();
    }
  }

  setForEntityType() {
    this.entityDocumentTypesLookup = this.codeValueService
      .getRelatedCodeValues(CodeValueGroups.DocumentType, this.entityTypeId, CodeValueGroups.EntityType);
  }

  setForEntity() {
    this.dataSourceUrl = `document/${this.entityTypeId}/${this.entityId}`;
    this.buildColumns();
  }

  buildColumns() {
    const documentTypesLookup = this.documentTypesList || this.entityDocumentTypesLookup;
    this.columns = [
      new PhxDataTableColumn({
        dataField: 'Name',
        caption: 'File Name',
        dataType: 'string',
        width: 400,
        allowFiltering: true
      }),
      new PhxDataTableColumn({
        dataField: 'UploadedByFullName',
        caption: 'Uploaded By',
        dataType: 'string'
      }),
      new PhxDataTableColumn({
        dataField: 'DocumentTypeId',
        caption: 'Document Type',
        dataType: 'string',
        lookup: {
          dataSource: documentTypesLookup,
          valueExpr: 'id',
          displayExpr: 'text'
        }
      }),
      new PhxDataTableColumn({
        dataField: 'UploadedDatetime',
        caption: 'Date',
        dataType: 'date'
      }),
      new PhxDataTableColumn({
        dataField: 'PublicId',
        caption: '',
        dataType: 'string',
        cellTemplate: 'actionCellTemplate',
        allowExporting: false,
        allowFiltering: false,
        allowSorting: false,
        allowGrouping: false,
        allowSearch: false
      })
    ];
  }

  canDeleteDocument(document: PhxDocument) {
    if (this.canDeleteDocumentFn) {
      return this.canDeleteDocumentFn(document);
    } else {
      return true;
    }
  }

  documentDelete(document: PhxDocument) {
    const dlg = this.dialogs.confirmDialog('Document Delete', 'This document will be deleted. Continue?');
    dlg.then(
      button => {
        if (button === DialogResultType.Yes){
          this.documentService.deleteDocumentByPublicId(document.PublicId).then(
            responseSucces => {
              if (responseSucces.IsValid) {
                this.loadDocumentsList();
              }
            },
            responseError => {
              this.loadDocumentsList();
              if (this.documentDeleteFailed) {
                this.documentDeleteFailed.emit();
              } else {
                // this.html.validationMessages = this.commonService.parseResponseError(responseError);
                this.commonService.parseResponseError(responseError).forEach(m => {
                  this.commonService.logError(m.Message);
                });
              }
            }
          );
        }        
      });
  }

  loadDocumentsList() {
    this.grd.refresh();
  }

  openDocument(publicId: string) {
    return this.documentService.openDocument(publicId, true);
  }

  onRowClick($event) {
    if (this.rowClick) {
      this.rowClick.emit($event);
    }
  }

  onContextMenuOpenTab($event) {
    if (this.contextMenuOpenTab) {
      this.contextMenuOpenTab.emit($event);
    }
  }
}
